import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1Appendix,
  V1TocLayout,
  V1TocChildLayout,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202006 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202006Query>(graphql`
    query Index_202006 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 600 : 830,
      paddingTop: windowWidth <= 767 ? 180 : 250,
      txtSize: windowWidth <= 767 ? "l" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "June 2020";
  const nextPreview = "◆次巻予告・「できれば快適にすごしたい、夏」〇七〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#f6f6f6",
    itemColor: "#f6f6f6",
  };
  const bgColor = "#9d9d9d";
  const tocContents = [
    {
      to: "/2020-6/items#candy-sleeve-anorak",
      num: "九",
      title: "雨を避けるか、飴を持つか",
      item: "キャンディ・アノラック",
    },
    {
      to: "/2020-6/items#umbrella-dress",
      num: "二十三",
      title: "開く方法は踊ること",
      item: "アンブレラ・ドレス",
    },
  ];
  const appendix = "作者あとがき";
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="June-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-6/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>三十</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix}</VerticalTxt>
            </V1Appendix>
          </>
          <Anchor to="/2020-7#July-2020" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202006;
