import { css } from "linaria";
import { animation, colors, fonts, mediaQuery, shadow } from "@/utils/styles";

export const list = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: ${fonts.size.xl};
  ${mediaQuery.max} {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 68px;
    left: 0;
    width: 100%;
    background-color: ${colors.bg};
    box-shadow: ${shadow.basic};
  }
`;

export const visible = css`
  display: flex;
`;

export const link = css`
  display: flex;
  align-items: center;
  height: 69px;
  padding: 0 40px;
  color: ${colors.base};
  transition: ${animation.hover.basic};
  cursor: pointer;
  &:hover,
  .current {
    background-color: ${colors.link.hover};
  }
  ${mediaQuery.max} {
    justify-content: center;
  }
`;

export const monthly = css`
  position: relative;
  &:hover > ul {
    display: flex;
  }
`;
