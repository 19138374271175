import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ImgAndColor,
  V1ItemLeftLayout,
  V1ItemRightLayout2,
  V1ItemRightLayout4,
  V1PurchaseBtn,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202002 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202002Query>(graphql`
    query Items_202002 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "organza-tuck-dress",
      title: {
        main: "organza tuck dress",
      },
      desc: `コミニョンドレスのよう
      いつまでも無邪気な子供みたいで
      時々ぐっとくるほど大人の香り
      彼女は独特な ”風” をまとってる
      
      触れることもできないし
      見ることもできない
      でも風は確かにそこにある
      
      襟元のフリルが揺れる
      後ろでリボンが泳ぐ
      たくさんのタックはまるで波のよう
      
      ひんやりと
      ほほを撫でたかと思えば
      足もとを過ぎ去って
      あたためる
      
      風に合わせて踊りたくなる
      ドレス
      `,
      color: [
        {
          en: "mint",
          ja: "ミント",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op03_nor_gr",
      ],
      story: `風のように軽く、
      風のように軽やかに揺れ、
      風のように優しくつつみこむ
      そんなロングドレス。
      
      １年中楽しめるオーガンジー素材なので
      冬はウールとニットにサンドイッチして。
      夏はコットンのキャミワンピにさっと重ねて。
      春・秋はお気に入りのロングTシャツやボトムに重ねて。
      
      madder madder の
      lamp dress や GOWN JACKET との
      レイヤードスタイリングもおすすめです。
      `,
    },
    {
      id: "organza-puffy-blouse",
      title: {
        main: "organza puffy blouse",
      },
      desc: `朝
      窓を開けると
      入り込んでくる
      時にやさしく
      時にきびしい
      
      手を伸ばして
      つかもうとすると
      すっと逃げられる
      袖に入りこみ
      裾からも潜り込んで
      ふわっと膨らんだ
      
      目には言えないけれど
      ここに入るよって
      ボタンもころころと転がす
      
      風を感じるブラウス
      `,
      color: [
        {
          en: "white",
          ja: "ホワイト",
        },
        {
          en: "mimosa",
          ja: "イエロー",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201bl01_nor_wh",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201bl01_nor_ye",
      ],
      story: `たっぷりとディティールの詰まった
      主役級のアイテムですが
      レイヤード用に作られている為
      会わせやすさ抜群です。
      
      シンプルなネックラインは
      ハイネック、襟付き、深めなど
      デザインを選びません。
      
      ボリュームたっぷりの袖の膨らみも
      肩と手首はきゅっとメリハリ
      長袖を着るときは
      ちらっと袖からのぞかせても
      もちろん1年中着ていただけます。
      
      春はボーダーのロングTシャツ
      夏はカラフルなキャミソール
      秋はチェックの丸襟ブラウスに
      冬はモノトーンのハイネックニット
      四季の風とともにお楽しみください。
      `,
    },
    {
      id: "organza-apron-skirt",
      title: {
        main: "organza apron skirt",
      },
      desc: `風をまとえたなら。
      それも、
      春の訪れを伝える
      とびきりやわらかい
      優しい風を
      
      風の妖精は
      きっと踊りが得意
      いつか会ってみたくて
      くるくると回る
      
      上手に踊れなくても
      このスカートがあれば
      風を起こすことができる
      今日は会えるだろうか
      `,
      color: [
        {
          en: "pink",
          ja: "ピンク",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201sk01_nor_pk",
      ],
      story: `美しいオーガンジーに
      しなやかな長いリボン。
      風になびかせたくなる、
      風の強い日にこそ
      着たくなってしまうような
      そんなエプロンスカートです。
      
      もちろんエプロンスカートですから
      中には何だって合わせられます。
      着るものを選びません。
      
      いつものデニムでも
      お気に入りのAラインスカートでも
      少し大人なタイトスカートでも
      無地でも柄でも構いません、
      透かせてみせましょう。
      
      madder madder の
      TULLE LONG DRESS や LONG SKIRT
      の上に重ねたり、
      JABOT BLOUSE や RIBBON BLOUSE
      のボトムにもおすすめです。
      `,
    },
  ];
  const title = {
    main: "THINK OF WIND",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="think-of-wind"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[0].link[0]} isExternal>
              <StaticImage
                src="../../assets/images/2020-2/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color[0].en}</Txt>
              <Txt size="l">（{items[0].color[0].ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1PurchaseBtn link={items[0].link[0]} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout4>
            <>
              <V1ImgAndColor>
                <Anchor to={items[1].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2020-2/items/02.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[0].en}</Txt>
                  <Txt size="l">（{items[1].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2020-2/items/03.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[1].en}</Txt>
                  <Txt size="l">（{items[1].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1PurchaseBtn link={items[1].link[0]} />
          </V1ItemRightLayout4>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[2].link[0]} isExternal>
              <StaticImage
                src="../../assets/images/2020-2/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color[0].en}</Txt>
              <Txt size="l">（{items[2].color[0].ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1PurchaseBtn link={items[2].link[0]} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202002;
