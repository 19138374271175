import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1TocLayout, V1TocChildLayout } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { css } from "linaria";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202103 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202103Query>(graphql`
    query Index_202103 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 170 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "March 2021";
  const nextPreview = "◆次巻予告・「見知らぬ、図鑑。」〇四〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#655653",
    itemColor: "#655653",
  };
  const bgColor = "#e7dadb";
  const tocContents = [
    {
      to: "/2021-3/items#fireworks-dress",
      num: "九",
      title: "せつなければ　おどりましょう",
      item: "ファイヤーワークス・ドレス",
    },
    {
      to: "/2021-3/items#",
      num: "十六",
      title: "３６５分の２の非日常な日常",
      item: "１８２・スカート",
    },
    {
      to: "/2021-3/items#souvenir-bag-blouse",
      num: "二十三",
      title: "レジ袋はいりません。お土産はポケットへ。",
      item: "スーベニアバッグ・ブラウス",
    },
    {
      to: "/2021-3/items#shirt-dress",
      num: "三十",
      title: "また明日からはじまる、いざ日常へ",
      item: "１８２・シャツドレス",
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="March-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-3/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) =>
              t.num === "十六" ? (
                <V1TocChildLayout
                  className={noHov}
                  key={t.title}
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              ) : (
                <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                  <V1TocChildLayout
                    {...tocProps}
                    paddingTop={responsive.paddingTop}
                  >
                    <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                  </V1TocChildLayout>
                </Anchor>
              )
            )}
          </>
          <Anchor to="/2021-4#April-2021" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

const noHov = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1;
  transition: none;
  &:hover {
    opacity: 1;
  }
`;

export default Index_202103;
