import { css } from "linaria";

export const root = css`
  display: flex;
  flex-direction: column;
`;

export const list = css`
  & > p {
    line-height: 2;
  }
`;
