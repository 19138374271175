import { colors } from "@/utils/styles";

export const compose =
  (...fns: any[]) =>
  (arg?: any) =>
    fns.reduce((composed, f) => f(composed), arg);
export const log = (message): void => console.log(message);
export const blackOrWhite = (hexColor: string) => {
  if (!hexColor) {
    return colors.base;
  }
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return (r * 299 + g * 587 + b * 114) / 1000 < 128 ? colors.bg : colors.base;
};
