import { css } from "linaria";
import { border, colors, fonts } from "@/utils/styles";

export const h = css`
  font-weight: ${fonts.weight.bold};
  line-height: ${fonts.lineHeight.xl};
  margin: 15px 0;
  text-align: center;
`;

export const underlined = css`
  border-bottom: ${border.basic};
  padding-bottom: 5px;
`;

export const outlined = css`
  color: ${colors.outlined};
`;

export const h1 = css`
  font-size: ${fonts.size.xxxxl};
`;

export const h2 = css`
  font-size: ${fonts.size.xxxl};
`;

export const h3 = css`
  font-size: ${fonts.size.xxl};
`;

export const h4 = css`
  font-size: ${fonts.size.xl};
`;

export const h5 = css`
  font-size: ${fonts.size.l};
`;

export const h6 = css`
  font-size: ${fonts.size.m};
`;
