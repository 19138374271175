import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1Appendix,
  V1TocLayout,
  V1TocChildLayout,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202104 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202104Query>(graphql`
    query Index_202104 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 160 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "April 2021";
  const nextPreview = "◆次巻予告・「わたしたちの、花。」〇五〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#f5e6b0",
    itemColor: "#f5e6b0",
  };
  const bgColor = "#507f50";
  const tocContents = [
    {
      to: "/2021-4/items#watage-fluffy-skirt",
      num: "六",
      title: "わたげが　そらを　ゆらゆらと",
      item: "ワタゲ・フラッフィー・スカート",
    },
    {
      to: "/2021-4/items#commons-garden-dress",
      num: "十三",
      title: "ふつうって、なんだろう？",
      item: "コモンズ・ガーデン・ドレス",
    },
    {
      to: "/2021-4/items#emily-open-collar-blouse",
      num: "二十",
      title: "エミリーは、図書館へ。",
      item: "オープンカラー・ブラウス",
    },
    {
      to: "/2021-4/items#himawari-big-sailor-collar-dress",
      num: "二十七",
      title: "ひまわりは、海へ",
      item: "ビッグ・セーラーカラー・ドレス",
    },
  ];
  const appendix = "ライラック・ジャボ";
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="April-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-4/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>特別付録</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix}</VerticalTxt>
            </V1Appendix>
          </>
          <Anchor to="/2021-5#May-2021" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202104;
