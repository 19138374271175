import React, { FC } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  V1CoverLayout,
  V1IndexLayout,
  V1MonthlyStoryLayout,
} from "@/components/atoms/v1";
import { V1StoryBtn } from "@/components/molecules/v1";
import { Heading, Txt } from "@/components/atoms";

const Index_201911: FC = () => {
  const data = useStaticQuery<GatsbyTypes.Index_201911Query>(graphql`
    query Index_201911 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const bookTitle = "November 2019";
  const story = {
    head: "11月のおはなし ”OSANPO”",
    body: `11月。
    
    まだまだ緑色の葉っぱ
    赤や黄色に色づき始めた葉っぱたち
    コロコロと落ちる木の実
    紅葉の季節がやってきました
    
    春のピンクのお花を見ながらのお散歩は
    柔らかく優しい気持ちにさせてくれる
    秋の深い黄色や赤の葉っぱたちは
    なんだか少し懐かしいような・・
    寂しいような・・
    アンニュイな気持ち
    ついつい浸って1時間経ってしまったりして
    
    紅葉を見にお散歩へ
    本当は食欲の秋にかこつけて
    食べ過ぎてしまった10月の反省会
    でもただ歩くだけじゃない
    あくまでお散歩、紅葉狩りを兼ねて
    
    11月のマダマダは
    サクサクと枯葉の絨毯を歩く時間
    目に入る美しい色合いたち
    懐かしい銀杏の香り
    五感で紅葉を感じる
    季節の移り変わりを感じる
    散歩の時間が
    もう少しだけ楽しくなるような
    スタイルを提案します
    `,
  };
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="November-2019"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2019-11/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1MonthlyStoryLayout>
          <Heading level={3}>{story.head}</Heading>
          <Txt isWrapping size="l">
            {story.body}
          </Txt>
          <V1StoryBtn link="/2019-11/items" />
        </V1MonthlyStoryLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_201911;
