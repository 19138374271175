import React from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  V1CoverLayout,
  V1IndexLayout,
  V1MonthlyStoryLayout,
} from "@/components/atoms/v1";
import { V1StoryBtn } from "@/components/molecules/v1";
import { Heading, Txt } from "@/components/atoms";

const Index_202001 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202001Query>(graphql`
    query Index_202001 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const bookTitle = "January 2020";
  const story = {
    head: "1月のおはなし ”BRAND NEW...”",
    body: `1月。
    
    新年あけましておめでとうございます。
    新しい年を迎えるにあたり
    身の回りのもの
    新調したくなる
    あるある
    新しい私、ブラン・ニュー・ミー！
    
    朝、目が覚めて
    最初に視界に入るもの
    触れるもの
    第一歩
    この時間が
    今日の私を決める
    
    今日も1日いい日になりますように
    今年も1年いい年になりますように
    
    1月のマダマダは
    何気なく、
    でも確かに
    私たちの毎日に
    寄り添っていてくれる
    身近なものをインスピレーション源に
    リラックスできたり
    ちょっとしたお呼ばれに着ていけたり
    コーディネート次第で遊べる
    スタイルを提案します。`,
  };
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="January-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-1/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1MonthlyStoryLayout>
          <Heading level={3}>{story.head}</Heading>
          <Txt isWrapping size="l">
            {story.body}
          </Txt>
          <V1StoryBtn link="/2020-1/items" />
        </V1MonthlyStoryLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202001;
