import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1TocLayout, V1TocChildLayout } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202101 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202101Query>(graphql`
    query Index_202101 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 910,
      paddingTop: windowWidth <= 767 ? 170 : 300,
      txtSize: windowWidth <= 767 ? "m" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "January 2021";
  const nextPreview = "◆次巻予告・「マダマダ・ランドで大はしゃぎ！」〇二〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#c30d23",
    itemColor: "#c30d23",
  };
  const bgColor = "#e7dadb";
  const tocContents = [
    {
      to: "/2021-1/items#dera-check-big-long-coat",
      num: "十二",
      title: "浮かれた気持ちを纏って隠して。",
      item: "チェック・ビッグ・ロング・コート",
    },
    {
      to: "/2021-1/items#ticket-pants",
      num: "十九",
      title: "腰に隠し持つ、ドキドキ",
      item: "チケット・パンツ",
    },
    {
      to: "/2021-1/items#rara-tulle-5-skirt",
      num: "",
      title: "妄想、ロマンティック、遊園地",
      item: "ライティング・ブラウス",
    },
    {
      to: "/2021-1/items#ticket-dress",
      num: "二十六",
      title: "肩に忍ばすか、首元に忍ばすか悩みはそれだけ",
      item: "チケット・ドレス",
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="January-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-1/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
          </>
          <Anchor
            to="/2021-2#February-2021"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202101;
