import React from "react";
import * as styles from "./styles";

type buttonProps = {
  className?: string;
  children: JSX.Element | string;
};

const buttonFactory = (type: string) => {
  return ({ children, className = "", ...props }: buttonProps) => (
    <button
      className={[styles.button, styles[type], className].join(" ")}
      {...props}
    >
      {children}
    </button>
  );
};

export const Button = buttonFactory("normal");
export default Button;
