import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202105 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202105Query>(graphql`
    query Items_202105 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "heroine-organdy-tiered-dress",
      title: {
        main: "“HEROINE” organdy tiered dress",
      },
      desc: `育ちきり
      
      老いはじめる前
      
      輝く花
      
      自分が一番だと
      
      信じて疑わない
      
      自信から出る美しさ
      `,
      color: {
        en: "lavender",
        ja: "ラベンダー",
      },
      recommend: ["Lace line pants", "TICKET PANTS", "LONG SKIRT"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op07_nor",
    },
    {
      id: "luna-frill-garden-dress",
      title: {
        main: "“Luna” frill garden dress",
      },
      desc: `「わかくさシスターズ」とは・・？
      
      それはこの世界と少し似ていて、
      でもだいぶ違う、世界のはなし。
      わかくさのようにのびのび、キラキラと
      「今」をせいいっぱい楽しく生きる
      仲良しな4姉妹がいました。
      
      長女・エミリー
      次女・ひまわり
      三女・ルナ
      四女・ミルキー
      
      半年に一回、ふらりと私たちの前に
      あらわれる彼女たち。
      秋ぶりにかえってきました。
      
      ある日、お父さんの書斎で
      見つけた不思議な本
      「THE FLOWERS NOONE KNOWS」
      
      ・・・
      
      その本にのっている、見たこともない、
      「誰も知らない植物」を探しに
      4人はそれぞれ、大冒険へ。
      さて、三女ルナは、どうするのでしょう・・・？
      `,
      color: {
        en: "lavender",
        ja: "ラベンダー",
      },
      recommend: ["GOWN JACKET", "gown dress", "candy sleeve anorak"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op08_nor1",
    },
    {
      id: "milky-easy-wide-pants",
      title: {
        main: "“Milky” easy wide pants",
      },
      desc: `「わかくさシスターズ」とは・・？
      
      それはこの世界と少し似ていて、
      でもだいぶ違う、世界のはなし。
      わかくさのようにのびのび、キラキラと
      「今」をせいいっぱい楽しく生きる
      仲良しな4姉妹がいました。
      
      長女・エミリー
      次女・ひまわり
      三女・ルナ
      四女・ミルキー
      
      半年に一回、ふらりと私たちの前に
      あらわれる彼女たち。
      秋ぶりにかえってきました。
      
      ある日、お父さんの書斎で
      見つけた不思議な本
      「THE FLOWERS NOONE KNOWS」
      
      ・・・
      
      その本にのっている、見たこともない、
      「誰も知らない植物」を探しに
      4人はそれぞれ、大冒険へ。
      さて、四女ミルキーは、どうする・・・？
      `,
      color: {
        en: "khaki & off ",
        ja: "カーキ & オフ",
      },
      recommend: [
        "“TANE” many many seeds blouse",
        "shelter blouse",
        "“Emily” open collar blouse",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211pt02_nor",
    },
  ];
  const story = `誰が作ったんだろう？
  不思議な植物図鑑に載っている、
  「誰も知らない花」たち。
  
  人間の一生と、
  同じようにたどる
  
  種が漂い、
  みんな普通の顔して成長する
  最盛期には輝き、
  終わりの足音が忍び寄る
  やがて花ではなく、実に、種に。
  
  今日もまたページが増える
  誰も知らない、はずの植物図鑑
  `;
  const title = {
    main: "THE FLOWERS NOONE KNOWS",
    sub: "第二部・わたしたちの、花。",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="hyakkiyakou-1"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-5/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-5/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-5/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202105;
