import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202112 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202112Query>(graphql`
    query Items_202112 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "madame-ruffles-collar-dress",
      title: {
        main: "Madame Ruffle’s collar dress",
        sub: "マダム・ルフレのラッフルつけ衿ドレス",
      },
      desc: `マダム・ルフレ:
      
      マダム・カラーの
      ルフレさんは
      
      いつも冷静
      波風立たせない
      
      波風立たせるのは
      衿元だけ
      `,
      color: {
        en: "bordeaux wine",
        ja: "ワインレッド",
      },
      recommend: [
        "“Milky” wrapping tunic blouse 21aw",
        "“Himawari” big sailor collar bolero 21aw",
        "“HUNTER” big buttons collar coat",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212op05_nor1",
    },
    {
      id: "madame-butters-collar-pullover",
      title: {
        main: "Madame Butter’s collar pullover",
        sub: "マダム・ブテルのつけ衿プルオーバー",
      },
      desc: `マダム・ブテル:
      
      マダム・カラーの
      ブテルさんは
      
      あっちへ ひらひら
      こっちへ ひらひら
      
      いくつになっても
      地に足つけない
      
      まるでバタフライのように
      
      あっちへ ひらひら
      こっちへ ひらひら
      `,
      color: {
        en: "potage khaki",
        ja: "カーキ",
      },
      recommend: [
        "TICKET PANTS",
        "“COLLECTOR” classical skirt",
        "“Luna” tulle layered dress 21aw",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212bl05_nor1",
    },
    {
      id: "madame-revers-collar-dress",
      title: {
        main: "Madame Rever's collar dress",
        sub: "マダム・ルベルのリバーシブルつけ衿ドレス",
      },
      desc: `マダム・ルベル:
      マダム・カラーの
      ルベルさんは
      
      いつだって
      逆転勝ち
      
      メダルの オモテ/ウラ
      どっちを選んでも
      
      彼女の手にかかれば
      逆転勝ち
      `,
      color: {
        en: "black coffee",
        ja: "ブラック",
      },
      recommend: [
        "“HUNTER” big buttons collar coat",
        "“Milky” wrapping tunic blouse 21aw",
        "“Luna” tulle layered dress 21aw",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm212op06_nor1",
    },
  ];
  const story = `マザー・マダマダのうた1
  Madame, collar
  カラーをつけたマダム3人
  晩餐会にやってきた
  カラーを飾られた席に通される
  カラーをつけたマダム3人
  カラカラカラ、と不思議な笑い声
  カラカラカラフルなコースをたいらげる
  最後のデザートは真っ黒なコーヒーに
  真っ黒なブッシュ・ド・ノエル
  酔っぱらったカラーをつけたマダム3人
  みんなコーヒーこぼしてカラーを汚す
  「わたしは付けっぱなし」
  「わたしはひっくり返して」
  「わたしはもう取ってしまいましょう」
  カラーをつけたマダム3人
  カラーをお土産に持ち帰っていった
  `;
  const title = {
    main: "MADAMEs PARTY",
    sub: "マダム・カラーの晩餐会！",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="madames-party"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-12/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-12/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-12/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202112;
