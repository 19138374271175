import React from "react";
import Seo from "@/components/seo";
import BookTitle from "@/components/molecules/BookTitle";
import { Profile } from "@/components/organisms";
import Layout from "@/components/layout";

const IndexPageLayout = ({ siteMetadata, bookTitle, desc, id, children }) => {
  return (
    <Layout navs={siteMetadata.navs}>
      <Seo title={bookTitle} description={desc} />
      <main role="main">
        <BookTitle bookTitle={bookTitle} id={id} />
        {children}
      </main>
      <Profile
        designerName={siteMetadata.designer}
        desc={siteMetadata.desc}
        links={siteMetadata.links}
      />
    </Layout>
  );
};

export default IndexPageLayout;
