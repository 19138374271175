import React, { useMemo, VFC } from "react";
import * as styles from "./styles";
import { VerticalTxt } from "@/components/atoms/Txt";
import { useWindowSize } from "@/hooks/useWindowSize";
import { Anchor } from "@/components/atoms";

type TocChildLayoutProps = {
  children: JSX.Element[];
  className?: string;
  txtColor: string;
  itemColor: string;
  paddingTop: number;
  style?: { flex?: number };
  to?: string;
};

const TocChildLayout: VFC<TocChildLayoutProps> = ({
  children,
  txtColor,
  itemColor,
  paddingTop,
  to,
  ...props
}) => {
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      dotsSize: windowWidth <= 767 ? "s" : "xxl",
    };
  }, [windowWidth]);
  return (
    <Anchor to={to} className={styles.root} {...props}>
      <div className={styles.right}>
        <div className={styles.num} style={{ color: txtColor }}>
          {children[0]}
        </div>
        <div className={styles.dots} style={{ color: txtColor }}>
          <VerticalTxt size={responsive.dotsSize} isBold>
            ・・・
          </VerticalTxt>
        </div>
        <div className={styles.title} style={{ color: txtColor }}>
          {children[1]}
        </div>
      </div>
      <div style={{ paddingTop: `${paddingTop.toString()}px` }}>
        <div style={{ color: itemColor, display: "grid" }}>{children[2]}</div>
      </div>
    </Anchor>
  );
};

export default TocChildLayout;
