import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1Appendix,
  V1TocLayout,
  V1TocChildLayout,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202010 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202010Query>(graphql`
    query Index_202010 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 170 : 250,
      txtSize: windowWidth <= 767 ? "s" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "October 2020";
  const nextPreview = "◆次巻予告・「魔女のお祭り」十一〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#f6f6f6",
    itemColor: "#f6f6f6",
  };
  const bgColor = "#891416";
  const tocContents = [
    {
      to: "/2020-10/items#dera-check-big-long-coat",
      num: "六",
      title: "ふしぎな　バランス",
      item: "チェック・ビッグ・ロング・コート",
    },
    {
      to: "/2020-10/items#maam-check-layered-skirt",
      num: "二十",
      title: "ふしぎな　かさねぎ",
      item: "チェック・レイヤード・スカート",
    },
    {
      to: "/2020-10/items#mee-check-buttons-jacket",
      num: "二十七",
      title: "ふしぎな　ボタン",
      item: "チェック・ボタン・ジャケット",
    },
  ];
  const appendix = "ふしぎな　作者あとがき";
  const appendix2 = "ジャボ・ブラウス";
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="October-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-10/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>三十一</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix}</VerticalTxt>
            </V1Appendix>
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>特別付録</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix2}</VerticalTxt>
            </V1Appendix>
          </>
          <Anchor
            to="/2020-11#November-2020"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202010;
