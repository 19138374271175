import React from "react";
import * as styles from "./styles";
import { Anchor } from "@/components/atoms";
import { MonthlyStories } from "@/components/molecules";

const Navigation = ({ links, isMenuVisible, ...props }) => {
  return (
    <nav {...props}>
      <ul
        className={
          isMenuVisible ? [styles.list, styles.visible].join(" ") : styles.list
        }
      >
        {links.map((link) => (
          <li key={link.label}>
            {link.label !== "毎月のおはなし" ? (
              !link.current ? (
                <Anchor
                  to={link.to}
                  isExternal={link.isExternal}
                  className={styles.link}
                >
                  {link.label}
                </Anchor>
              ) : (
                <span className={[styles.link, "current"].join(" ")}>
                  {link.label}
                </span>
              )
            ) : (
              <span
                id="monthlyStories"
                className={[styles.link, styles.monthly].join(" ")}
              >
                {link.label}
                <MonthlyStories />
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default Navigation;
