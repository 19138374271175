import { css } from "linaria";
import { border } from "@/utils/styles";

export const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const header = css`
  width: 100%;
  padding: 18px 10px;
  margin-bottom: 40px;
  border-top: ${border.width} dashed ${border.itemColor};
  border-bottom: ${border.width} dashed ${border.itemColor};
  & > h3,
  h4 {
    margin: 0;
  }
`;

export const desc = css`
  & > p {
    line-height: 3;
  }
`;
