import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ImgAndColor,
  V1ItemLeftLayout,
  V1ItemRightLayout3,
  V1PurchaseBtn,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_201909 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_201909Query>(graphql`
    query Items_201909 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "two-way-dress",
      title: {
        main: "2WAY DRESS",
        sub: "準備を始める",
      },
      desc: `お茶の時間をはじめましょう
      お茶をブレンドしたり
      お湯を沸かしたり
      ちょっとつまむためのお菓子を焼いたりなんかも
      そんな大切な準備の時間
      まとうのはエプロンでもいいかもしれませんが
      準備の時間用のワンピースなんてあったら
      とっても素敵。
      
      Vintage のメディカルコートのように
      前後ろどちらも主役になれる 2way 仕様なので
      もしも準備中に来客があっても大丈夫。
      パティシエさんのようなボタンがついた
      胸元が印象的な「作業着風」から
      小さなボタンのたくさんついた
      「お出迎えできる」ロングドレスに変身！
      なんてスマートなのでしょう。
      
      作業の邪魔にならないようにお袖は少し短め、
      ポワンとしたボリュームもとっても「ちょうどいい」。
      コットンなので生クリームやチョコがついても大丈夫！
      お家で洗ってくださいね。
      `,
      color: [
        {
          en: "whipped cream",
          ja: "ホワイト",
        },
        {
          en: "nutty brownie",
          ja: "ブラウン",
        },
        {
          en: "dark cherry",
          ja: "ボルドー",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_wh",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_br",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_bo",
      ],
    },
    {
      id: "tunic-dress",
      title: {
        main: "TUNIC DRESS",
        sub: "さっとはおってお菓子を買いに",
      },
      desc: `お茶を飲む時間、何時だろう？
      もしかしたら朝起きてすぐ、仕事の前かもしれないし
      お昼と夜ご飯の間のブレイクタイムかもしれないし、
      夜のワークアウトが終わった後のご褒美タイムかもしれない。
      
      そんな時ちょうどいいお菓子がなかったら？
      買いに行かなきゃ。
      もしかしたら肌寒いかもしれない。
      今のかっこうで外に出るのはちょっとな、
      かといって着替えるのも勿体無い。
      せっかくお菓子を買いに行くのだからちょっと可愛い服が着たい。
      
      1日を通して、四季を通して、どんな時間でも
      さっと手を取りたくなるような
      簡単で、楽ちんで、可愛いチュニック丈のスモッグ。
      早朝のコンビニでも
      昼過ぎの街のケーキ屋さんでも
      夜の駅前の洒落たカフェバーでも
      ちょっと出かける、お菓子を買いに。
      `,
      color: [
        {
          en: "magic hour’s herbs tea",
          ja: "ピンク",
        },
        {
          en: "after lunch oolong tea",
          ja: "ブラウン",
        },
        {
          en: "good morning, black coffee",
          ja: "ブラック",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op02_nor_pk",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op02_nor_br",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op02_nor_bk",
      ],
    },
    {
      id: "long-skirt",
      title: {
        main: "LONG SKIRT",
        sub: "視界に入るのはお茶と・・",
      },
      desc: `お茶を飲む。
      お菓子をいただく。
      手元を見るたびに 視界にうつる
      今日、私の身につけているもの。
      
      デニムでもいいけれど
      いつものワンピースでもいいけれど
      なんだか気持ちの上がる、
      お茶とお菓子の仲間に入れるような・・
      そんなロマンティックな服が着たい。
      まるで特別の日のためのデコレーションケーキのような、
      だんだんウェーブのクリームのような
      スカート。
      
      立ち姿も歩く姿も空気をはらんで素敵だけれど
      お茶をして座っている間は私にしか見えない、
      なんて贅沢な時間なんだろう。
      なんて贅沢な服なんだろう。
      `,
      color: [
        {
          en: "peach’s foot prints",
          ja: "ピンク",
        },
        {
          en: "my way brown",
          ja: "ブラウン",
        },
        {
          en: "after party",
          ja: "ブラック",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk05_nor_pk",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk05_nor_br",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk05_nor_bk",
      ],
    },
  ];
  const title = {
    main: "TEA TIME",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].desc}
      id="art"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[0].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/01.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[0].en}</Txt>
                  <Txt size="l">（{items[0].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/02.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[1].en}</Txt>
                  <Txt size="l">（{items[0].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/03.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[2].en}</Txt>
                  <Txt size="l">（{items[0].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[0].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[1].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/04.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[0].en}</Txt>
                  <Txt size="l">（{items[1].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/05.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[1].en}</Txt>
                  <Txt size="l">（{items[1].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/06.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[2].en}</Txt>
                  <Txt size="l">（{items[1].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[1].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[2].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/07.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[0].en}</Txt>
                  <Txt size="l">（{items[2].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/08.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[1].en}</Txt>
                  <Txt size="l">（{items[2].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-9/items/09.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[2].en}</Txt>
                  <Txt size="l">（{items[2].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[2].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_201909;
