import React from "react";

export const containPresenter = (
  Container,
  Presenter
): ((props) => JSX.Element) => {
  return (props) => (
    <Container
      presenter={(presenterProps) => <Presenter {...presenterProps} />}
      {...props}
    />
  );
};
