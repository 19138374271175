import { css } from "linaria";
import { mediaQuery, shadow } from "@/utils/styles";

export const root = css`
  width: 70%;
  margin-bottom: 40px;
  box-shadow: ${shadow.basic};
  ${mediaQuery.max} {
    width: 100%;
  }
`;
