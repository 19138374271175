import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1TocLayout,
  V1TocChildLayout,
  V1TocChildLayout2,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { css } from "linaria";
import { animation } from "@/utils/styles";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202012 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202012Query>(graphql`
    query Index_202012 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 600 : 870,
      paddingTop: windowWidth <= 767 ? 160 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "December 2020";
  const nextPreview = "◆次巻予告・「マダマダ・ランドに遊びに行こう」〇一〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#f6f6f6",
    itemColor: "#f6f6f6",
  };
  const bgColor = "#15422b";
  const tocContents = [
    {
      to: ["/2020-12/items#collar-blouse"],
      num: "八",
      title: "襟をつけたり、はずしたり",
      item: ["カラー・ブラウス"],
    },
    {
      to: ["/2020-12/items#reversible-skirt"],
      num: "十五",
      title: "ひっくり返すと、水玉が。",
      item: ["リバーシブル・スカート"],
    },
    {
      to: [
        "/2020-12/items#organza-tuck-mini-dress",
        "/2020-12/items#organza-tuck-dress",
      ],
      num: "二十二",
      title: "お人形になる？ならない？",
      item: [
        "ならない ▼ オーガンザ・ミニ・ドレス",
        "なる　▼ オーガンザ・ドレス",
      ],
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="December-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-12/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) =>
              t.num === "二十二" ? (
                <V1TocChildLayout2
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                  style={{ flex: 1 }}
                  className={noHov}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  {t.item.map((i, idx) => (
                    <Anchor
                      key={i}
                      to={t.to[idx]}
                      style={{ color: tocProps.txtColor, display: "grid" }}
                    >
                      <VerticalTxt key={i} className={hov} {...txtProps}>
                        {i}
                      </VerticalTxt>
                    </Anchor>
                  ))}
                </V1TocChildLayout2>
              ) : (
                <Anchor key={t.title} to={t.to[0]} style={{ flex: 1 }}>
                  <V1TocChildLayout
                    {...tocProps}
                    paddingTop={responsive.paddingTop}
                  >
                    <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.item[0]}</VerticalTxt>
                  </V1TocChildLayout>
                </Anchor>
              )
            )}
          </>
          <Anchor
            to="/2021-1#January-2021"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

const hov = css`
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

const noHov = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1;
  transition: none;
  &:hover {
    opacity: 1;
  }
`;

export default Index_202012;
