import React, { VFC } from "react";
import * as styles from "./styles";
import Copyright from "@/components/molecules/CopyRight";
import { Anchor } from "@/components/atoms";

type FooterProps = {
  className?: string;
};

const Footer: VFC<FooterProps> = ({ className, ...props }) => (
  <footer className={[styles.root, className].join(" ")} {...props}>
    <Anchor to="/privacy-policy">プライバシーポリシー</Anchor>｜
    <Copyright>madder madder</Copyright>
  </footer>
);
export default Footer;
