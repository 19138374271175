import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1TocLayout, V1TocChildLayout2 } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202111 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202111Query>(graphql`
    query Index_202111 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 180 : 250,
      txtSize: windowWidth <= 767 ? "l" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "November 2021";
  const nextPreview = "◆次巻予告・「マダム・カラーの晩餐会」一二〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#fce5d5",
    itemColor: "#b4860a",
  };
  const bgColor = "#5b3219";
  const tocContents = [
    {
      to: "/2021-11/items#hunter-big-buttons-collar-coat",
      num: "九",
      title: "ハンテルさん",
      item: ["ビッグ・ボタン・カラー・コート"],
    },
    {
      to: "/2021-11/items#collector-classical-bolero",
      num: "十六",
      title: "コレクトルさん",
      item: ["クラシカル・ボレロ", "クラシカル・スカート"],
    },
    {
      to: "/2021-11/items#baker-classical-dress",
      num: "三十",
      title: "バケルさん",
      item: ["クラシカル・ドレス"],
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="November-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-11/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout2
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  {t.item.map((i) => (
                    <VerticalTxt
                      key={i}
                      {...txtProps}
                      styles={{ display: "grid" }}
                    >
                      {i}
                    </VerticalTxt>
                  ))}
                </V1TocChildLayout2>
              </Anchor>
            ))}
          </>
          <Anchor
            to="/2021-12#December-2021"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202111;
