import React, {
  ForwardRefExoticComponent,
  forwardRef,
  RefAttributes,
  PropsWithoutRef,
  FC,
  useMemo,
} from "react";
import HTMLFlipBook from "react-pageflip";
import * as styles from "./styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useWindowSize } from "@/hooks/useWindowSize";

type BookProps = {
  images: GatsbyTypes.ImgEdge[];
  className?: string;
};

type BookPageProps = {
  children: JSX.Element;
  className?: string;
};

const BookPage: ForwardRefExoticComponent<
  PropsWithoutRef<BookPageProps> & RefAttributes<HTMLDivElement>
> = forwardRef(({ children }: BookPageProps, ref) => {
  return (
    <div className={styles.page} ref={ref}>
      <div className="content">{children}</div>
    </div>
  );
});

const Book: FC<BookProps> = ({ images, ...props }) => {
  const { windowWidth } = useWindowSize();
  const isMobile = useMemo(() => {
    return windowWidth <= 767;
  }, [windowWidth]);
  return (
    <div className={styles.root} {...props}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <HTMLFlipBook
        width={1131}
        height={1600}
        minWidth={315}
        maxWidth={900}
        minHeight={420}
        maxHeight={1350}
        size="stretch"
        flippingTime={1000}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={true}
        usePortrait={isMobile}
      >
        {images.map((_, idx) => (
          <BookPage key={idx}>
            <GatsbyImage
              key={idx}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              image={getImage(images[idx].node.image)}
              alt="test"
            />
          </BookPage>
        ))}
      </HTMLFlipBook>
    </div>
  );
};

export default Book;
