import React, { VFC } from "react";
import * as styles from "./styles";

type ImgAndColorProps = {
  children: JSX.Element[];
  className?: string;
};

const ImgAndColor: VFC<ImgAndColorProps> = ({ children, ...props }) => {
  return (
    <div {...props}>
      <div className={styles.img}>{children[0]}</div>
      <div className={styles.color}>{children[1]}</div>
    </div>
  );
};

export default ImgAndColor;
