import React, { FC } from "react";
import { Heading, Txt } from "@/components/atoms";
import * as styles from "./styles";
import * as animation from "@/utils/animation";

type BookTitleProps = {
  bookTitle: string;
  subTitle?: string;
  id: string;
};

const BookTitle: FC<BookTitleProps> = ({ bookTitle, subTitle, id, ...props }) =>
  subTitle ? (
    <div
      className={[
        styles.hasSubTitle,
        animation.fadeInFromBottom,
        animation.delay06,
      ].join(" ")}
      {...props}
    >
      <Heading className={styles.heading} id={id}>
        {bookTitle}
      </Heading>
      <Txt size="l" isBold isCenter>
        （{subTitle}）
      </Txt>
    </div>
  ) : (
    <Heading
      className={[
        styles.heading,
        animation.fadeInFromBottom,
        animation.delay06,
      ].join(" ")}
      id={id}
      {...props}
    >
      {bookTitle}
    </Heading>
  );
export default BookTitle;
