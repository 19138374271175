export const colors = {
  base: "#626262",
  primary: "#2d2d2d",
  secondary: "#e6e6e6",
  accent: "none",
  bg: "#fff",
  success: "none",
  danger: "none",
  warning: "none",
  info: "none",
  selected: "none",
  outlined: "#626262",
  itemBg: "#dbdad2",
  link: {
    normal: "#b22222",
    visited: "none",
    hover: "#f6f6f6",
    active: "none",
  },
};

export const border = {
  width: "1px",
  style: "solid",
  color: "#ebebeb",
  itemColor: "#a9a9a9",
  radius: "4px",
  basic: "1px solid #ebebeb",
};

export const shadow = {
  basic: "0 2px 5px rgb(0 0 0 / 20%)",
  book: {
    cover: {
      top: "inset 0 0 30px 0 rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4)",
      bottom:
        "inset 0 0 30px 0 rgba(36, 10, 3, 0.5), 10px 0 8px 0 rgba(0, 0, 0, 0.4)",
    },
    contents: {
      left: "inset -7px 0 30px -7px rgba(0, 0, 0, 0.6)",
      right: "inset 7px 0 30px -7px rgba(0, 0, 0, 0.6)",
    },
  },
};

export const fonts = {
  family: {
    ja: `'Noto Serif JP', 'YuGothic', 'Yu Gothic', 'Hiragino Mincho Pro', 'Hiragino Mincho ProN W3', serif, メイリオ, Meiryo, 'ＭＳ Ｐゴシック', sans-serif;`,
  },
  size: {
    xxs: "none",
    xs: "0.6rem",
    s: "0.8rem",
    m: "1rem",
    l: "1.2rem",
    xl: "1.4rem",
    xxl: "1.6rem",
    xxxl: "1.8rem",
    xxxxl: "2rem",
  },
  weight: {
    normal: "400",
    bold: "700",
  },
  lineHeight: {
    xs: 1.2,
    s: 1.4,
    m: 1.6,
    l: 1.8,
    xl: 2,
  },
};

export const width = {
  container: "1000px",
};

export const space = {
  size: {
    m: "0.5rem",
  },
};

export const animation = {
  hover: {
    duration: "0.1s",
    timing: "ease-out",
    basic: "0.1s ease-out",
  },
  fade: {
    duration: "0.2s",
    timing: "linear",
    basic: "0.2s linear",
  },
  easing: "cubic-bezier(0.46, 0.03, 0.52, 0.96)",
  opacity: "0.7",
};

export const zIndex = {
  header: 10,
};

export const breakPoints = {
  sp: 567,
  tablet: 1024, // iPad Pro
};

export const maxWidth = 950;

export const mediaQuery = {
  min: `@media screen and (min-width: ${breakPoints.sp + 1}px)`,
  middleMin: `@media screen and (min-width: ${breakPoints.tablet + 1}px)`,
  middleMax: `@media screen and (max-width: ${breakPoints.tablet}px)`,
  middle: `@media screen and (min-width: ${
    breakPoints.sp + 1
  }px) and (max-width: ${breakPoints.tablet}px)`,
  max: `@media screen and (max-width: ${breakPoints.sp}px)`,
};

export default {
  colors,
  border,
  shadow,
  fonts,
  width,
  space,
  animation,
  zIndex,
  breakPoints,
  maxWidth,
  mediaQuery,
};
