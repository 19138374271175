import React, { useMemo, VFC } from "react";
import * as styles from "./styles";
import { VerticalTxt } from "@/components/atoms/Txt";
import { useWindowSize } from "@/hooks/useWindowSize";

type TocChildLayout2Props = {
  children: [JSX.Element, JSX.Element, JSX.Element[]];
  className?: string;
  txtColor: string;
  itemColor: string;
  paddingTop: number;
  style?: { flex?: number };
};

const TocChildLayout2: VFC<TocChildLayout2Props> = ({
  children,
  txtColor,
  itemColor,
  paddingTop,
  ...props
}) => {
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      dotsSize: windowWidth <= 767 ? "s" : "xxl",
    };
  }, [windowWidth]);
  return (
    <div className={styles.root} {...props}>
      <div className={styles.right}>
        <div className={styles.num} style={{ color: txtColor }}>
          {children[0]}
        </div>
        <div className={styles.dots} style={{ color: txtColor }}>
          <VerticalTxt size={responsive.dotsSize} isBold>
            ...
          </VerticalTxt>
        </div>
        <div style={{ color: txtColor }}>{children[1]}</div>
      </div>
      <div
        className={styles.left}
        style={{ paddingTop: `${paddingTop.toString()}px` }}
      >
        {children[2].map((c, idx) => (
          <div key={idx} style={{ color: itemColor, display: "grid" }}>
            {c}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TocChildLayout2;
