import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202010 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202010Query>(graphql`
    query Items_202010 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "dera-check-big-long-coat",
      title: {
        main: "“DERA” CHECK BIG LONG COAT",
      },
      desc: `民族学者：（以下：民）こんにちは。ちょっと今お時間宜しいですか？
      
      DERA（デルア）さん：（以下：デ）あ、ちょっとなら。今仕事に向かう途中なんで。
      
      民：ありがとうございます。お名前と、お仕事は何を？
      
      デ：デルアです。お城でお姫様の専属スタイリストをしています。
      
      民：へー、かっこいい。そういえば、なんだかオシャレなコート着てらっしゃる。
      
      デ：でしょう。これはマダマダ族の伝統的な柄、「ハンサムチェック」生地をたっぷり使ってるの。
      チェックの中にボルドーの線が入っているから、裏地もボルドーにしたのよ。
      
      民：なるほど。ボルドーが派手に見えないのは表地とよく馴染んでいるからなんですね。
      
      デ：そういうわけよ。私たちマダマダ族は、とにかく柄が大好きなのだけれど、
      柄と柄を合わせても悪目立ちしないのは先祖代々伝わるマダマダ族のスタイリング指南書のおかげなのよ。
      
      民：先祖代々伝わる指南書・・そもそも、なんで柄が好きなんですか？
      
      デ：だって楽しいじゃない！柄、楽しくない！？
      
      民：た、確かに・・楽しい、ですね。
      
      デ：まあ、柄ものだけじゃなくて、カラフルなのも楽しいけれど。
      
      民：そうですね、ピンクとか、アカとか。ブルー、グリーン、イエロー。
      
      デ：そう。そして、おいしいものを食べる時間も、楽しい。
      
      民：もはやなんでも楽しいんでしょうね。いいですね、マダマダ族。
      
      デ：そうよ、楽しいの。あなたもマダマダ族に入らない？
      
      民：え、入るとかあるんですか？
      
      デ：うん、いつでもどうぞ。その代わり、毎日あなたが楽しいと思う服を着てね。
      まあ、毎日は色々と難しいかもしれないから、できれば、毎日。
      
      民：え、あー、うん、どうしようかな。とりあえず、チェックの服、探そうかな。
      
      デ：まあ素敵！今度、店に着て。では、私、仕事に行くので。ごきげんよう。
      
      民：ありがとうございました。
      あ、そこの素敵なスカートをお召しのあなた・・ちょっと今お話伺えますか・・？
      
      （つづく）
      `,
      color: {
        en: "handsome gray",
        ja: "グレー",
      },
      recommend: ["Lace line pant", "salopette", "“Lorina” dress"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202co01_nor",
      story: `「madder madder clan」＝ “マダマダ族”。
      いつかの時代、
      とある世界の、ちょっとふしぎな国。
      おいしいものと、おめかしが大好きな一族がいました。
      一族の好む服、民族衣装はなぜか柄物だらけ。
      なんでかな・・・
      さっそく “マダマダ族” にインタビュー。
      彼女たちの生活を、少し覗いてみましょう。
      今回は、城下町のファッションリーダー、
      DERA（デルア）さんに聞いてみました。
      `,
    },
    {
      id: "maam-check-layered-skirt",
      title: {
        main: "“MAAM” CHECK LAYERED SKIRT",
      },
      desc: `民族学者：(以下：民)こんにちは。怪しいものではありません、今お時間宜しいですか？
      
      MAAM（マーム）さん：（以下：マ）今妹と待ち合わせしてるんで。来るまでなら。
      
      民：ありがとうございます。お名前と、お仕事うかがってもいいですか？
      
      マ：名前はマーム。学生です。服装史について学んでいます。
      
      民：あ、学生さんなんですね。大人っぽい。
      
      マ：そうですか？今日はちょっと、シンプル&シックで、大人っぽい格好がしたくて。
      全身無地、モノトーンでまとめてたんですけど、これから妹と遊びに行くからなあ。（ゴソゴソ）
      
      民：マダマダ族の方は、柄物や色物を身につけている印象でしたが、無地モノトーンもシックでステキですよ。
      
      マ：（ゴソゴソ）あ、あった。妹って、双子なんですけど、同じ柄や色の服着たがるんですよね。
      「双子コーデしよ！」とか言って。いやいや、本当に双子なのに。これでも巻こうかな。
      
      民：なんですかそれ？エプロン？すごく可愛いチェック。
      
      マ：そう、エプロンです。重ねるだけでパーっと印象変わるでしょ。あ、リボン、前で結ぼうかな後ろで結ぼうかな・・
      
      民：へえ・・そのウェストのボタンに、ちゃんと重ねられるようになってるんですね。芸が細かい。
      
      マ：でしょ。でも、今日は横でリボン結びにしちゃおうかな。あ、やっぱり、肩掛けにしよう。
      妹、なんとなくチェック柄のトップスで来る気がする。上半身チェックでお揃いにしよう。
      民：やっぱり双子だとテレパシー？みたいなの、あるんですかね。不思議、わかるんですね。
      
      マ：なんとなくの予感だけれど。ちょっと肌寒いような気もしてたし、マント代わりよ。
      
      民：うわ、想像していたのと違った、すごくステキですねその着こなし！
      
      マ：ちょうど昨日の授業でマダマダ歴1800年頃にマントが大流行した、って習ったばかりで。
      やっぱりいいわね。ちょっとクラシカルな感じ。
      
      民：マダマダ歴・・メモメモ・・・
      
      マ：あ、向こうで手振ってるの、妹だ！やっぱり上がチェック。
      そういえば、これなんのインタビューでしたっけ？妹からも話、聞きますか？
      
      民：いいんですか！ありがとうございます！あ、妹さんもステキなジャケットを着てらっしゃる・・
      
      （つづく）
      `,
      color: {
        en: "mm check",
        ja: "マルチカラーハウンドトゥースチェック",
      },
      recommend: ["CHECK BIG LONG COAT", "JABOT BLOUSE", "mint T-blouse"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk02_nor",
      story: `「madder madder clan」＝ “マダマダ族”。
      いつかの時代、
      とある世界の、ちょっとふしぎな国。
      おいしいものと、おめかしが大好きな一族がいました。
      一族の好む服、民族衣装はなぜか柄物だらけ。
      なんでかな・・・
      さっそく“マダマダ族”にインタビュー。
      彼女たちの生活を、少し覗いてみましょう。
      今回は、街でもステキと噂の双子の姉、
      MAAM（マーム）さんに聞いてみました。
      `,
    },
    {
      id: "mee-check-buttons-jacket",
      title: {
        main: "“MEE” CHECK BUTTONS JACKET",
      },
      desc: `民族学者：（以下：民）こんにちは。マアムさんの妹さんですね、今お時間良いですか？
      
      MEE（ミー）さん：（以下：ミ）こんにちは！そうです妹です。こんにちは。街頭インタビュー的なあれですか？
      
      民：そうなんです。ちょっと調査といいますか。お名前とご職業うかがっても？
      
      ミ：名前はミー。私も学生なんだけど、マアムとは学部が違くて。民族服装デザインについて学んでいます。
      
      民：民族服装デザイン？デザイナーさんの卵、ということですか？
      
      ミ：うーん、まあ、そうですね。服装全般というより、マダマダ族の民族服に特化してますけど。
      
      あ、今日私が着ているジャケットと、マアムの着ているスカートも、私デザインです。
      
      民：え！？これ、ミーさんがデザインしたんですか！？確かに、マダマダ族のチェックだ！
      
      ミ：そう。デザインというか、まあ、リメイクなんですけど。曽祖母の着ていたコートを解体して、スカートとジャケットを作ったの。
      
      民：へえー・・曽祖母さんの・・またおしゃれなチェックで・・
      
      ミ：このチェックの柄を私たちの国では「ハウンドトゥース」と読んでいるんだけれど、遠い東の国では「CHIDORI」って呼ぶんだって。なんだか語感が可愛くて、「CHIDORI」について調べたのよ。そしたらね・・
      
      民：あ、千鳥格子柄。ご存知なんですね。確かに、千鳥の響き、可愛いかもしれません。
      
      ミ：そう。可愛いわよね。で、「CHIDORI-ASHI」っていう、可愛いステップがあるみたいなの。
      足を左右に踏み違えて歩くのよ。ダンスみたいで、可愛くって。
      
      民：ち、千鳥足・・？酔っ払ってふらふら歩くこと・・であってます・・？
      
      ミ：あら、酔っ払いなの？で、そのステップがとにかく好きで。だからボタンで表してみたのよ。
      左右でずらして塗ったわ。雪山の足跡みたいだけど、これは「CHIDORI ASHI」なの。
      
      民：えーーー。おもしろーーー。
      
      マ：学校の先生に、自分の好きなものを探し続けなさい、好きなものの数に、限りはないんだからって毎日のように教えられているの。だからわたし、自分が好きだと思ったものを、デザインに落とし込んでいるのよ。
      
      民：素敵な先生ですね、これぞマダマダ族って感じ。
      
      マ：ありがとう。あなたが何を調査しているのかいまいちわからないんだけど、でも、喜んでくれるなら嬉しいわ。
      
      民：ぐさっ。これからも私は調査を続けますよ。あ、すみません、そこのあなたー！
      
      （おしまい、もしくはすこしさきにつづく）
      `,
      color: {
        en: "mm check",
        ja: "マルチカラーハウンドトゥースチェック",
      },
      recommend: ["CHECK BIG LONG COAT", "JABOT BLOUSE", "CHECK LAYERED SKIRT"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202jk01_nor",
      story: `「madder madder clan」＝ “マダマダ族”。
      いつかの時代、
      とある世界の、ちょっとふしぎな国。
      おいしいものと、おめかしが大好きな一族がいました。
      一族の好む服、民族衣装はなぜか柄物だらけ。
      なんでかな・・・
      さっそく “マダマダ族” にインタビュー。
      彼女たちの生活を、少し覗いてみましょう。
      今回は、すこしふしぎな子、と噂の双子の妹、
      MEE（ミー）さんに聞いてみました。
      `,
    },
  ];
  const title = {
    main: "madder madder clan",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="madder-madder-clan"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-10/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-10/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-10/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202010;
