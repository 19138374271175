import { css } from "linaria";
import { mediaQuery } from "@/utils/styles";

export const hasSubTitle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  & > h2 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const heading = css`
  padding: 30px 25px 15px;

  ${mediaQuery.max} {
    padding-bottom: 0;
  }
`;
