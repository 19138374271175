import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1ItemRightLayout2,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202012 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202012Query>(graphql`
    query Items_202012 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "collar-blouse",
      title: {
        main: "collar blouse",
      },
      desc: `上の張り紙を見て、今日は面接に。
      採用されてすぐに、配属を決めるための適性試験がはじまった。
      1着のブラウスを渡されて
      「この子は本屋さんが良さそう」
      「うーん、襟を外してみたら？」
      「あっ、ケーキ屋さんでもありじゃない？」
      「ちょっと待った、リボン巻いたら・・？」
      「これはおもちゃ屋さんの可能性も・・」
      
      適性試験ってなんだろう、と思っていたら
      どうやら制服の着こなしだけみたい。
      素敵なデパートって、
      ちょっと変わってるのね。
      ところで、私の配属はどこになるんだろう？
      `,
      color: {
        en: "white",
        ja: "ホワイト",
      },
      recommend: [
        "“MAAM” CHECK LAYERED SKIRT",
        "“Luna” suspender skirt",
        "SALOPETTE PANTS",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202bl03_nor",
    },
    {
      id: "reversible-skirt",
      title: {
        main: "reversible skirt",
      },
      desc: `上の張り紙を見て、無事面接を突破し、
      適性試験なるものを受けさせられた。
      働きはじめて1週間がたつ。
      不安だった配属先は、まだ決まっていないらしい。
      これはどうやら珍しいケースのよう。
      
      毎日毎日、
      「あなたはドットが似合うから、おもちゃ売り場よ！」
      「あら、昨日のドットより、ブラックでシックな方がいいじゃない。葉巻売り場へどうぞ。」
      「みんなわかっていないわね、どちらも似合うのよ。ただ、リボンベルトはしない方がいいわ。」
      「リボンベルトをしていないの、昨日見たけど、正気！？今日はちゃんとつけてね。」
      なんて売り場ごとのお局様たちが私を取り合いしている。
      
      このスカートは気に入っているから、
      どう着たっていいんだけれど。
      早く配属先、決まらないかしら・・・
      `,
      color: {
        en: "black - navy × white dot",
        ja: "ブラック - ネイビー × ホワイトドット",
      },
      recommend: ["collar blouse", "JABOT BLOUSE", "writing blouse"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk04_nor",
    },
    {
      id: "organza-tuck-dress",
      title: {
        main: "organza tuck dress",
        sub: "for ladies",
      },
      desc: `クリスマスまであと4日。
      このデパートメントストアで働くようになってはじめての、事件に遭遇してしまったかもしれない。
      
      例の自分へのクリスマスプレゼント（つまるところ、お給料日）に買おうと思っていた「お人形」と「お人形用のラベンダー色のドレス」が、揃っていなくなってしまった。
      
      昨夜締め作業をした時は、確かにそこの棚にいた。
      最後にフロアを離れたのは私。そして、今朝最初に出勤したのも私。
      鏡拭きや床掃除、両替の確認などそっちのけで探すこと20分、全く見つかる気配が無い。
      
      こんなこと誰かに言ったらきっと笑われてしまうけれど（笑われる、だけで済むならいい方だ、きっと）
      きっとあのお人形は、あのラベンダードレスを着て旅に出てしまったんだと思う。
      あの小ささだと、歩くのにも時間がかかりそうだから、もしかすると、東の森あたりの魔女に魔法にでもかけてもらって、私達くらいのサイズまで大きく・・そう、人間にしてもらったのかもしれない。
      
      そして彼女は、あのラベンダー色のドレスを着て
      はじめて食べるチョコレート・ケーキに感動したり、
      降水確率0％でも突然降ってくる雨にびっくりしたり、
      酔っ払って歌い踊る楽しさなんかを覚えたりして、
      このデパートのことなんてすっかり忘れてしまうんだ。
      
      盗難 / 紛失届にお人形1点、着せ替え服1点、と記入しフロアマネージャーの出勤を待つ。
      怒られるのは嫌だなあ、と思いつつ、あのお人形が楽しそうに外を歩いているのを想像するだけで口角が少しあがってしまう。
      `,
      color: {
        en: "lavender",
        ja: "ラベンダー",
      },
      recommend: ["Lace line pants", "fold up dress", "182 skirt"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op03_nor",
    },
    {
      id: "organza-tuck-mini-dress",
      title: {
        main: "organza tuck mini dress",
        sub: "for dolls",
      },
      desc: `クリスマスまで1週間。 おもちゃ売り場がとにかく大忙しのようで、まだまだ本配属先は決まらないけれど・・とりあえずクリスマスまでは毎日ドールコーナーに立つことになった。
      
      少し不思議な話なのだけれど、妙に惹かれるお人形がいる。 そのお人形、よくよく見ると、勝手に動いている気がするのだ。 前を向いているはずなのに、気づいたら右隣の着せ替え服の棚を見ていたり。 何回か、その着せ替え服の中でも1番可愛いなと思っていたラベンダー色のドレス隣にいたこともある。
      
      きっとあのラベンダー色のドレスが着たいんだ。 お人形にも、ラベンダー色のドレスのタグにも、「エルダーズ・アトリエ」と刻印されている。 もしかしたら、同じ工場で集荷される前からずっとずっと、このドレスに恋い焦がれていたのかもしれない。
      
      次のお給料日は25日、本当は自分へのクリスマスプレゼントで、ふわふわのラベンダー色のマフラーを買おうと思っていたのだけれど・・ 予定を変更してあのお人形とラベンダーのドレスを買おう。 どうかあと1週間売れないで、と販売員らしからぬ気持ちををそっと、そっと胸に秘める。
      `,
      color: {
        en: "lavender",
        ja: "ラベンダー",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_minimm201op03_nor",
    },
  ];
  const story = `【MM DEPARTMENT STORE】とは？
    マダマダの世界で一番、素敵なデパート。
    クリスマスに向けて、少し先の新年に向けて12月は大忙し！
    「この世で一番素敵ななにか」を探しに、
    たくさんの目を輝かせたお客様がやって来ます。
    
    大繁忙期の中、「この世で一番素敵な販売員」
    のSALES LADYたちは世界一素敵な笑顔と接客で
    毎日せっせと幸せな時間をプレゼントしていきます
    
    そんな素敵なSALES LADYたちは、
    一体どんな制服を着ているのでしょう？
    少しだけ、のぞいてみましょう。
    `;
  const title = {
    main: "MM DEPARTMENT STORE",
    sub: "何かいいもの、探しに行かなきゃ。",
  };
  const info = `勤務地: MM DEPARTMENT STORE
  (floor B1 to 13)
  求める人材:「素敵な人」
  未経験歓迎。素敵な制服、素敵なお昼ご飯支給。
  ★素敵なあなたのご応募お待ちしています。
  ※採用フロアは適性試験により決定します。
  `;
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="mm-department-store"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <>
              <Txt isBold isCenter size="l">
                MM DEPARTMENT STORE
                <br />
                従業員募集のお知らせ
              </Txt>
              <Txt
                isWrapping
                style={{
                  marginBottom: "10px",
                  paddingBottom: "10px",
                  borderBottom: "1px dashed #a9a9a9",
                }}
              >
                {info}
              </Txt>
              <Txt size="l" isWrapping>
                {items[0].desc}
              </Txt>
            </>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-12/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <>
              <Txt isBold isCenter size="l">
                MM DEPARTMENT STORE
                <br />
                従業員募集のお知らせ
              </Txt>
              <Txt
                isWrapping
                style={{
                  marginBottom: "10px",
                  paddingBottom: "10px",
                  borderBottom: "1px dashed #a9a9a9",
                }}
              >
                {info}
              </Txt>
              <Txt size="l" isWrapping>
                {items[1].desc}
              </Txt>
            </>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-12/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <>
              <Txt isBold isCenter size="l">
                MM DEPARTMENT STORE
                <br />
                従業員募集のお知らせ
              </Txt>
              <Txt
                isWrapping
                style={{
                  marginBottom: "10px",
                  paddingBottom: "10px",
                  borderBottom: "1px dashed #a9a9a9",
                }}
              >
                {info}
              </Txt>
              <Txt size="l" isWrapping>
                {items[2].desc}
              </Txt>
            </>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-12/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[3].title.sub}）
              </Heading>
            </>
            <>
              <Txt isBold isCenter size="l">
                MM DEPARTMENT STORE
                <br />
                従業員募集のお知らせ
              </Txt>
              <Txt
                isWrapping
                style={{
                  marginBottom: "10px",
                  paddingBottom: "10px",
                  borderBottom: "1px dashed #a9a9a9",
                }}
              >
                {info}
              </Txt>
              <Txt size="l" isWrapping>
                {items[3].desc}
              </Txt>
            </>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-12/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202012;
