import { css } from "linaria";
import { colors, zIndex } from "@/utils/styles";

export const sticky = css`
  background-color: ${colors.bg};
  position: sticky;
  top: -1px;
  z-index: ${zIndex.header};
`;

export const body = css`
  min-height: 100vh;
`;
