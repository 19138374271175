import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202103 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202103Query>(graphql`
    query Items_202103 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "fireworks-dress",
      title: {
        main: "FIREWORKS DRESS",
      },
      desc: `- ご来園いただきありがとうございました。
      お別れの花火タイムです -
      
      ここは夢のような場所
      MM WONDERLAND
      
      醒めない夢はありません
      早く起きて3・2・1
      花火が6回あがったなら
      夢が終わる3・2・1
      
      ここは夢のような場所
      MM WONDERLAND
      
      （MM WONDERLAND オリジナルソング
      「MM DREAMER」より一部抜粋）
      `,
      color: {
        en: "evanescent pink",
        ja: "はかないピンク",
      },
      recommend: ["candy sleeve anorak", "gown dress", "sailor collar bolero"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op03_nor",
    },
    {
      id: "shirt-dress",
      title: {
        main: "182 shirt dress",
      },
      desc: `- 182シリーズ -
      
      ＝1年（365日）のうち、半分（182日）くらい
      着たいような・・着心地も使い勝手もいい、
      がテーマのシリーズ。
      
      ワンピース、タックスカートに続く第3弾は、
      はおりにもなるギャザーたっぷりの
      シャツワンピース。
      
      気温調節の難しい、朝から晩まで目一杯
      遊びたいMM WONDERLANDでも
      きっと大活躍！
      `,
      color: {
        en: "my pink",
        ja: "くすみピンク",
      },
      recommend: [
        "182 dress",
        "182 skirt",
        "candy sleeve anorak",
        "FIREWORKS DRESS",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op04_nor1",
    },
    {
      id: "souvenir-bag-blouse",
      title: {
        main: "souvenir bag blouse",
      },
      desc: `- 【スーベニア・ショップ】でお土産を -
      
      お土産をポケットへ
      ドキドキとワクワクと一緒に、
      産地直送でお届け
      選ぶ時間も楽しい
      渡すまでの時間も楽しい
      笑顔になってくれるのも楽しい
      `,
      color: {
        en: "grayish pink",
        ja: "グレイッシュピンク",
      },
      recommend: ["Lace line pants", "TICKET PANTS", "FIREWORKS DRESS"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211jk01_nor",
    },
  ];
  const story = `【MM WONDERLAND】へ遊びに行こう！
  そこは少しふしぎなワンダーランド。
  かわいいものとかっこいいもの、
  そしておいしいものの
  たくさん詰まった桃源郷のような施設です。
  
  はじまりがあれば、
  必ずやってくる、終わりの時間。
  今日の思い出を胸に
  さあ、明日からの現実世界も生き抜いて。
  `;
  const title = {
    main: "MM WONDERLAND",
    sub: "第3部・さようなら MM ワンダーランド。また来る、その日まで。",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="mm-wonderland-3"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-3/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-3/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-3/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202103;
