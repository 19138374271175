import { css } from "linaria";
import { colors, mediaQuery, shadow, width } from "@/utils/styles";

export const root = css`
  width: 100%;
  max-width: ${width.container};
  margin: 15px auto 70px;
  padding: 15px 25px;
  ${mediaQuery.max} {
    margin-bottom: 15px;
  }
`;

const num = {
  position: "absolute",
  bottom: "10px",
};

export const page = css`
  background-color: ${colors.secondary};
  box-shadow: ${shadow.basic};
  .content {
    position: relative;
    height: 100%;
  }
  &.--left {
    box-shadow: ${shadow.basic};
    border-right: 0;
    .num {
      ${num};
      left: 15px;
    }
  }
  &.--right {
    box-shadow: ${shadow.basic};
    border-left: 0;
    .num {
      ${num};
      right: 15px;
    }
  }
`;
