import React, { VFC } from "react";
import * as styles from "./styles";
import { Anchor } from "@/components/atoms";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "@/assets/icons/siteLogo.svg";

type SiteSymbolProps = {
  className?: string;
};

const SiteSymbol: VFC<SiteSymbolProps> = ({ ...props }) => {
  return (
    <div className={styles.root} {...props}>
      <Anchor to="/">
        <img src={logo} alt="Site logo" className={styles.img} />
      </Anchor>
    </div>
  );
};

export default SiteSymbol;
