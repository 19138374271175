import React, { VFC } from "react";
import * as styles from "./styles";
import { Heading } from "@/components/atoms";

type TocLayoutProps = {
  children?: JSX.Element[];
  className?: string;
  txtColor: string;
  height: number;
  bgColor: string;
};

const TocLayout: VFC<TocLayoutProps> = ({
  children,
  txtColor,
  height,
  bgColor,
  ...props
}) => {
  return (
    <section
      className={styles.root}
      style={{ backgroundColor: bgColor }}
      {...props}
    >
      <div className={styles.title}>
        <Heading
          visualLevel={1}
          className={styles.heading}
          style={{ color: txtColor }}
        >
          ◆目次
        </Heading>
      </div>
      <div className={styles.content}>{children[0]}</div>
      <div
        className={styles.next}
        style={{ color: txtColor, height: `${height.toString()}px` }}
      >
        {children[1]}
      </div>
    </section>
  );
};

export default TocLayout;
