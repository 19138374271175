import React from "react";
import * as styles from "./styles";
import { Heading, Txt } from "@/components/atoms";

const AboutBrand = ({ brandDesc, ...props }) => {
  return (
    <section className={styles.container} {...props}>
      <div className={styles.header}>
        <Txt isCenter>About</Txt>
        <Heading level={2}>What's madder madder?</Heading>
        <Heading level={3} visualLevel={4}>
          ~ madder madder とは ~
        </Heading>
      </div>
      <Txt isCenter isWrapping size="l" className={styles.desc}>
        {brandDesc}
      </Txt>
    </section>
  );
};

export default AboutBrand;
