import { css } from "linaria";
import { width } from "@/utils/styles";

export const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${width.container};
  padding: 0 25px;
  margin: 0 auto;
`;
