import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1Appendix,
  V1TocLayout,
  V1TocChildLayout,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { css } from "linaria";
import { animation } from "@/utils/styles";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202007 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202007Query>(graphql`
    query Index_202007 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 170 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "July 2020";
  const nextPreview = "◆次巻予告・「拝啓、お元気ですか」〇八〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#2d2d2d",
    itemColor: "#2d2d2d",
  };
  const bgColor = "#9ce4f7";
  const tocContents = [
    {
      to: "/2020-7/items#citrus-tunic",
      num: "十三",
      title: "あまずっぱい　ひと夏の思い出",
      item: "シトラス・チュニック",
    },
    {
      to: "/2020-7/items#mint-t-blouse",
      num: "二十一",
      title: "爽快指数１００パーセント",
      item: "ミント・ティーブラウス",
    },
    {
      to: "/2020-7/items#lavender-dress",
      num: "二十八",
      title: "エリマキ女王のお墨付き。",
      item: "ラベンダー・ドレス",
    },
  ];
  const appendix = "作者あとがき";
  const appendix2 = "リネンカーテンドレス";
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="July-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-7/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>三十</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix}</VerticalTxt>
            </V1Appendix>
            <Anchor to="/2020-7/items#curtain-dress-summer" style={{ flex: 1 }}>
              <V1Appendix className={hov} txtColor={tocProps.txtColor}>
                <VerticalTxt {...txtProps}>★夏の特別付録</VerticalTxt>
                <VerticalTxt {...txtProps}>{appendix2}</VerticalTxt>
              </V1Appendix>
            </Anchor>
          </>
          <Anchor to="/2020-8#August-2020" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

const hov = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export default Index_202007;
