import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ImgAndColor,
  V1ItemLeftLayout,
  V1ItemRightLayout3,
  V1PurchaseBtn,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_201910 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_201910Query>(graphql`
    query Items_201910 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "gown-jacket",
      title: {
        main: "GOWN JACKET",
        sub: "アトリエの主の",
      },
      desc: `真珠の耳飾りの少女も
      青衣の女も
      気づいたら纏っている
      椅子にかかっているのも
      自画像でだって
      きっとみんな
      このガウン
      
      お気に入りだったであろう
      このガウンは
      なんだか着物のようで
      少し懐かしい気持ちになる
      
      美しい装飾の施されたドレスにも
      お部屋着にも
      もしかすると作業中にだって
      
      女性も男性も関係なく
      さっと羽織れる
      １年中そばに置いておきたくなる
      私のアトリエの主
      ガウンのジャケット
      `,
      color: [
        {
          en: "you",
          ja: "ビンク",
        },
        {
          en: "pearl",
          ja: "ブラウン",
        },
        {
          en: "astronomer",
          ja: "チャコール",
        },
        {
          en: "atelier",
          ja: "デニム",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92jk01_nor_pk",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92jk01_nor_br",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92jk01_nor_ch",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92jk01_nor_de",
      ],
    },
    {
      id: "smock-dress",
      title: {
        main: "SMOCK DRESS",
        sub: "生み出すための",
      },
      desc: `それは絵画かもしれないし
      小さな小さな彫刻かもしれない
      あるいは
      今晩のメインディッシュの盛り付けかもしれないし
      もしかすると
      いつもとちょっと違ったメイクを纏う自分かもしれない
      
      いつでも何かを生み出せるように
      スモックを用意しておいて
      いつもの服の上にかぶる
      汚れたらじゃぶじゃぶと洗えばいい
      
      タッセルだって
      つけてもつけなくても
      ぎゅっと結んでも　結ばなくても
      今日の気分でお好きなように
      
      大きければ大きいほど
      からだの自由がきく
      自由ということは
      発想しやすいということ
      
      誰にも縛られずに
      今日も
      私は何かを生み出す
      あなたも何かを生み出す
      `,
      color: [
        {
          en: "Gustav",
          ja: "ブルー",
        },
        {
          en: "Claude",
          ja: "グレー",
        },
        {
          en: "Maurizio",
          ja: "デニム",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92op01_nor_bl",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92op01_nor_kh",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92op01_nor_de",
      ],
    },
    {
      id: "jabot-blouse",
      title: {
        main: "JABOT BLOUSE",
        sub: "絵画の中の",
      },
      desc: `襟元が印象的であればあるほど
      絵画の登場人物の気分
      
      オーギュストの描く人々に
      なぜあんなにも目を奪われるのだろう
      
      いつも同じファッションでいるのは
      描き手にとって
      つまらないかもしれないから
      襟をつけたり、外したり・・
      
      1枚でも着たい、重ね着だって楽しみたい
      春と秋の贅沢な悩み
      それを叶える　素敵なブラウス
      `,
      color: [
        {
          en: "suzanne",
          ja: "ホワイト",
        },
        {
          en: "legrand",
          ja: "ブラック",
        },
        {
          en: "moulin",
          ja: "ライラック",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92bl01_nor1_wh",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92bl01_nor1_bk",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92bl01_nor1_li",
      ],
    },
  ];
  const title = {
    main: "ART",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].desc}
      id="art"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[0].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/01.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[0].en}</Txt>
                  <Txt size="l">（{items[0].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/02.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[1].en}</Txt>
                  <Txt size="l">（{items[0].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/03.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[2].en}</Txt>
                  <Txt size="l">（{items[0].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[3]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/04.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[3].en}</Txt>
                  <Txt size="l">（{items[0].color[3].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[0].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[1].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/05.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[0].en}</Txt>
                  <Txt size="l">（{items[1].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/06.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[1].en}</Txt>
                  <Txt size="l">（{items[1].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/07.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[2].en}</Txt>
                  <Txt size="l">（{items[1].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[1].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[2].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/08.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[0].en}</Txt>
                  <Txt size="l">（{items[2].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/09.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[1].en}</Txt>
                  <Txt size="l">（{items[2].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-10/items/10.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[2].en}</Txt>
                  <Txt size="l">（{items[2].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[2].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_201910;
