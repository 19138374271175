import { css } from "linaria";
import { animation, mediaQuery, shadow } from "@/utils/styles";

export const root = css`
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  width: 70%;
  margin-bottom: 60px;
  box-shadow: ${shadow.basic};
  ${mediaQuery.max} {
    width: 100%;
  }
`;

export const title = css`
  display: flex;
  justify-content: center;
  width: 15%;
  padding-top: 40px;
`;

export const content = css`
  display: flex;
  flex-direction: row-reverse;
  width: 70%;
  padding: 110px 0;
  ${mediaQuery.max} {
    padding: 50px 0;
  }
`;

export const next = css`
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: center;
  width: 15%;
  padding-bottom: 40px;
  & > a {
    transition: ${animation.hover.basic};
    &:hover {
      opacity: ${animation.opacity};
    }
  }
`;

export const heading = css`
  writing-mode: vertical-rl;
  letter-spacing: 0.8rem;
`;
