import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202011 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202011Query>(graphql`
    query Items_202011 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "emily-reversible-coat-20aw",
      title: {
        main: "“Emily” reversible coat 20aw",
      },
      desc: `大好きなエルダーおばあさまから
      新年のお祭りの招待状が届いた
      
      ”11月吉日”
      ”東の森にて”
      “ドレスコードは黒・白・赤”
      
      東の森までの道のりは
      ほんの少し、遠い
      
      赤ずきんちゃんは
      森で迷ってしまったけれど
      
      妹たち3人を
      安全に連れて行かなくちゃ
      私は長女だから
      
      深い森の闇に飲み込まれてしまわないように
      ほんの少しだけ、光を
      `,
      color: {
        en: "black & white",
        ja: "黒 / 白リバーシブル",
      },
      recommend: ["tutor dress", "“Lorina” dress", "182 dress"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202co02_nor",
    },
    {
      id: "himawari-sailor-collar-blouse-20aw",
      title: {
        main: "“himawari” sailor collar blouse 20aw",
      },
      desc: `姉と、妹たちと一緒に
      大好きなエルダーおばあさまに会いに
      
      招待状にはご丁寧に
      ドレスコードが書いてあって、
      要するにパーティーだから、正装しろって。
      色は黒、（か白か赤ならギリセーフ）と
      限定されて。
      
      何かに縛られるのが
      大嫌いな私だけれど
      「正装」って言葉は
      なんだか背筋が伸びる気がする
      少しくすぐったいような
      
      少しくらいルールがある中で
      めいいっぱい自分らしさを表現するのが
      もしかすると一番楽しいのかもしれない
      
      私の正装、セーラー襟
      何を合わせようか
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      recommend: [
        "Lace line pants",
        "”MAAM” CHECK LAYERED SKIRT",
        "SALOPETTE PANTS",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202bl02_nor",
    },
    {
      id: "luna-suspender-skirt-20aw",
      title: {
        main: "“Luna” suspender skirt 20aw",
      },
      desc: `とうとう東の森に着いてしまった
      エルダーおばあさまには会いたいけれど
      パーティーなんてまっぴらなのよ
      知らない人がたくさん
      ああ早く終わらないかしら
      
      魔法の靴を履いて
      ドロシーのように
      カツ、カツ、カツ
      かかとを3回鳴らして
      「やっぱりおうちが一番」
      
      今ならできる気がする
      大好きなおうちに、
      早く帰りたいのよ
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      recommend: [
        "“himawari” sailor collar blouse",
        "mint T-blouse",
        "JABOT BLOUSE",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk03_nor",
    },
    {
      id: "milky-apron-dress-20aw",
      title: {
        main: "“milky” apron dress 20aw",
      },
      desc: `ついにパーティーのはじまり、はじまり！
      
      この半年間、ワークスカートを着て
      たくさん、家事を勉強したんだから
      おばあちゃまの手伝いをするの
      そうまるで、完璧なメイドさんの気分
      
      お皿を誰よりも早く洗い
      チリを見つければさっとホウキで一振り
      お湯は常に沸かして使い放題よ
      
      飽きたらエプロンは丸めてポイっと
      お靴も履き替えて
      着飾ってパーティーの主役へ
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      recommend: [
        "“Emily” reversible coat",
        "“MAAM” CHECK LAYERED SKIRT",
        "organza apron skirt",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202op06_nor",
    },
  ];
  const story = `「わかくさシスターズ」って・・・誰かしら？
  
  それはこの世界と少し似ていて、
  でもだいぶ違う、世界のはなし。
  わかくさのようにのびのび、キラキラと
  「今」をせいいっぱい楽しく生きる
  仲良しな4姉妹がいました。
  
  長女・エミリー
  次女・ひまわり
  三女・ルナ
  四女・ミルキー
  
  夏ぶりに彼女たちが かえってきます。
  
  4姉妹の祖母「エルダーおばあさま」は、実はえらーい魔法使い。
  11月は魔女界で「新年を祝うお祭り」があるので
  大好きなおばあちゃんのお家のある「東の森」の先へ向かいます。
  お祭りのドレスコードは黒と、少しの白と、ほんの少しの赤。
  彼女たちの冬を、少しのぞき見してみましょう。
  今回は末っ子の四女・ミルキーの番です。
  `;
  const title = {
    main: "Samhain Festival",
    sub: "わかくさたちのふゆじたく",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="the-best-pumpkin-pie-project"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-11/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-11/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-11/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-11/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202011;
