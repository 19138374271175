import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202008 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202008Query>(graphql`
    query Items_202008 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "writing-blouse",
      title: {
        main: "writing blouse",
        sub: "書き直しすぎた便箋",
      },
      desc: `何回も恥ずかしくなって
      消しゴムで消しては
      書き直し
      また消して
      繰り返す
      
      便箋もボロボロになってしまった
      机の上も消しカスだらけ
      
      クシャクシャと丸めて
      ゴミ箱に投げることだってできるけど
      
      悩みに悩んで書いたこの形跡を
      知ってもらいたいような
      感じ取ってもらいたいような気がして
      このまま書き続けてしまう
      少しずるい わたし
      `,
      color: {
        en: "romantic sentence",
        ja: "グレイッシュピンク",
      },
      recommend: ["lace line pants", "curtain dress", "LONG SKIRT"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202bl01_nor",
      story: `便箋をイメージした
      少し透け感のある綿ローン生地の
      ブラウスができました。
      
      Vintage ブラウスのような
      首から肩にかけて行儀よく並んだボタンや
      過剰なほどのパフィー袖ですが
      薄い生地感や落とした肩の位置、
      ゴムでの絞りなど
      重厚感ですぎずラフに着ていただけます。
      
      定番になりつつある
      「切りっぱなし」の裾や袖先、
      アンニュイなグレイッシュピンクカラーなど
      マダマダらしい要素もたっぷりです。
      
      透け感がありますので、
      中にインナー着用が必須ですが
      わざとボーダーや小花柄など透けさせて
      レイヤードスタイルも楽しめそう。
      暑い時期は袖を肘までたくし上げて5分袖に、
      寒い時期はジャケットやニットの中にインして
      毎シーズン、できれば毎日でも着たいですね。
      `,
    },
    {
      id: "envelope-dress",
      title: {
        main: "envelope dress",
        sub: "出せなかった封筒",
      },
      desc: `手紙を出しそびれてしまった
      
      理由は色々ある
      少し酔った状態で書いたから
      なんとなく内容に不安が残る
      
      お気に入りの便箋に合う
      封筒が見つからなかった
      
      ポストが通り道に全くないので
      わざわざ少し遠くまで行かなくてはいけない
      
      しばらくカバンに入れっぱなしにしていたら
      変なシワがついてしまった
      
      そもそも住所はあっているんだろうか？
      もう何年も前に聞いたものだ
      
      出せなかった手紙
      握りしめて
      会える日を待つ方が
      早そうだ
      `,
      color: {
        en: "tea brown",
        ja: "ティー・ブラウン",
      },
      recommend: ["big collar blouse", "mint T-blouse", "lamp dress"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202op01_nor",
      story: `「本当に手紙を入れることのできる」封筒のついた
      アソビゴコロたっぷりのワンピースができました。
      
      ヴィンテージのアーミッシュエプロンドレス
      をイメージしたワンピースですが、
      本来のアーミッシュらしい
      “無駄なものを取り除いた質素”なものではなく、
      マダマダらしい装飾たっぷり。
      背中にいる封筒は言わずもがな、
      左右の肩にはもちろん、
      裾にもたっぷりくるみボタンをつけました。
      開けてインナーを見せるとまた雰囲気が変わります。
      
      ウエストは楽ちんなゴム仕様ですが、
      リボンが左右についているので
      結び方でまた色々と楽しめそうです。
      
      素材はやや厚めで透け感はありません。
      ノースリーブですので
      フレンチスリーブからロングスリーブまで、
      素材も薄手のものからニットまで。
      暑がりさんでなければ、
      オールシーズン着れそうです。
      
      本来のアーミッシュにならって
      シンプルな無地のシャツを合わせてもいいですし、
      フリッフリの柄物ブラウスで
      ちょっと派手に遊んじゃうのも素敵ですよ。
      
      封筒にはできれば手紙を入れて欲しいのですが、
      小さなお菓子を入れることも可能です。
      `,
    },
    {
      id: "fold-up-dress",
      title: {
        main: "fold up dress",
        sub: "折る",
      },
      desc: `気持ちを込めて
      手紙を折る
      最後の仕上げ、のようなもの
      
      丁寧に時間をかけて折れば
      綺麗にピシッと折れる
      気持ちがいい
      
      ラフな気分でささっと折ると
      ちょっと歪んだり合わなかったり
      でもそれが味だったりして
      
      今日の手紙は
      緊張して震えながら折った
      時間をかけて
      想いが伝わりますようにと
      心の奥底で呪文を唱えながら
      折る
      
      折りすぎて小さくなってしまった
      封筒のサイズに合うかな・・・
      `,
      color: {
        en: "monochrome sepia",
        ja: "モノクロ・セピア",
      },
      recommend: ["big collar blouse", "mint T-blouse", "lamp dress"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202op02_nor",
      story: `セピア色のたっぷりタックが美しい
      ロングのドレスができました。
      （祝・マダマダはじめての2サイズ展開です！）
      
      胸元にも、
      袖にも、
      裾にも。
      折って、折って、折りまくる
      タックがたっぷりのドレス。
      春先に販売した organza tuck dress のように、
      アンティークのコミニョンドレスをイメージしています。
      
      コミニョンドレスは通常、ホワイトですが、
      こちらはブラウンとも、チャコールとも言い切れない
      マダマダらしいアンニュイカラーの “セピア” 色。
      
      ほんの少しだけ光沢があるので、見る角度によって
      違う印象になるかもしれません。
      「何色なんだろう？」というカラーって、
      新しい発見！な感じがとてもワクワクするのですが、
      こんな商品説明をするのってダメでしょうか。
      「グレーがかった、ブラウン」が一番伝わるかと思います。
      
      ウエストゴムで楽ちんで、
      生地もタックがたくさんある分、
      使用量は少なくはありませんが、軽やかです。
      透け感は色がついている分、さほど気になりません。
      定番になりつつある過剰なパフィー袖も柔らかく
      上にカーディガンやジャケットを重ねられます。
      
      胸元の3つのボタンは
      「・・・」を表しています。
      文章の中にある
      「・・・」ってなんだか、
      いつまでも続くような、
      余韻を楽しめる余裕があるというか、
      「まだまだ・・・」な感じがして、
      とても好きです・・・
      `,
    },
  ];
  const title = {
    main: "LETTER.",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="madder-madder-clan"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-8/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-8/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-8/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202008;
