import React from "react";
import * as styles from "./styles";
import { Anchor, Txt } from "@/components/atoms";
import { format } from "date-fns";
import { compose } from "@/utils/util";

const MonthlyStories = ({ ...props }) => {
  const months = (startMonth: number, endMonth: number) => {
    const length = endMonth - startMonth + 1;
    return [...Array(length)].map((_, i) => i + startMonth).reverse();
  };
  const dates = [
    {
      year: "2022",
      month: [1, 2, 3, 4, 5, 6, 7, 8, 10],
    },
    {
      year: "2021",
      month: months(1, 12),
    },
    {
      year: "2020",
      month: months(1, 12),
    },
    {
      year: "2019",
      month: months(9, 12),
    },
  ];
  const timeTemplate = "yyyy-MM";
  const getCurrentTime = () => new Date();
  const serializeTime = (date: Date) => ({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });
  const formatTime =
    (format: string) => (time: { year: number; month: number }) =>
      format
        .replace("yyyy", String(time.year))
        .replace("MM", String(time.month));
  const rootPass = (): string =>
    compose(getCurrentTime, serializeTime, formatTime(timeTemplate))();
  const addMonth = (month, list) => [{ year: "2022", month }, ...list];
  // FIXME: 一時的な対応。
  const newDates = rootPass() === "2022-10" ? addMonth([1], dates) : dates;
  const monthEn = (year: number, month: number) => {
    return format(new Date(year, month - 1, 1), "MMMM");
  };
  return (
    <ul className={styles.years} {...props}>
      {newDates.map((d, idx) => (
        <li key={idx} className={styles.yearsList}>
          <Txt size="l" className={styles.date}>
            {d.year}
          </Txt>
          <ul className={styles.months}>
            <li className={styles.monthsList}>
              {d.month.map((m, idx) => (
                <Anchor
                  key={idx}
                  to={`/${d.year}-${m}`}
                  className={styles.link}
                >
                  <Txt size="l" className={styles.date}>
                    {monthEn(Number(d.year), m)}
                  </Txt>
                </Anchor>
              ))}
            </li>
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default MonthlyStories;
