import React from "react";
import * as styles from "./styles";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const AboutDesigner = ({ designerName, designerDesc, links, ...props }) => {
  return (
    <section className={styles.container} {...props}>
      <div className={styles.header}>
        <Txt isCenter>Profile</Txt>
        <Heading>{designerName}</Heading>
      </div>
      <StaticImage
        src="../../../assets/images/profile.png"
        alt="Profile image"
      />
      <Txt isWrapping size="l" className={styles.desc}>
        {designerDesc}
      </Txt>
      <ul className={styles.list}>
        {links?.map((link) => (
          <li key={link.label} className={styles.item}>
            <Anchor
              to={link.to}
              isExternal={link.isExternal}
              className={styles.link}
            >
              {link.label}
            </Anchor>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default AboutDesigner;
