import { css } from "linaria";
import { animation, border, fonts } from "@/utils/styles";

export const button = css`
  border-radius: ${border.radius};
  border-width: 0;
  display: inline-block;
  font-weight: ${fonts.weight.bold};
  font-size: ${fonts.size.m};
  line-height: ${fonts.lineHeight.m};
  padding: 0.9rem;
  transition: opacity ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export const normal = css`
  background-color: inherit;
`;
