import React, { FC } from "react";
import Seo from "@/components/seo";
import BookTitle from "@/components/molecules/BookTitle";
import { Profile } from "@/components/organisms";
import Layout from "@/components/layout";
import { V2Book } from "@/components/molecules/v2";

type IndexPageLayoutProps = {
  siteMetadata: GatsbyTypes.SiteSiteMetadata;
  bookTitle: string;
  desc: string;
  id: string;
  images: GatsbyTypes.ImgEdge[];
};

const IndexPageLayout: FC<IndexPageLayoutProps> = ({
  siteMetadata,
  bookTitle,
  desc,
  id,
  images,
}) => {
  return (
    <Layout navs={siteMetadata.navs}>
      <Seo title={bookTitle} description={desc} />
      <main role="main">
        <BookTitle bookTitle={bookTitle} id={id} />
        <V2Book images={images} />
      </main>
      <Profile
        designerName={siteMetadata.designer}
        desc={siteMetadata.desc}
        links={siteMetadata.links}
      />
    </Layout>
  );
};

export default IndexPageLayout;
