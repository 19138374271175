import { css } from "linaria";
import { animation, mediaQuery } from "@/utils/styles";

export const root = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1;
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export const right = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const num = css`
  display: flex;
  justify-content: center;
  min-height: 70px;
  ${mediaQuery.max} {
    min-height: 50px;
  }
`;

export const dots = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  text-orientation: upright;
  ${mediaQuery.max} {
    min-height: 70px;
  }
`;

export const titles = css`
  display: flex;
  justify-content: space-evenly;
`;
