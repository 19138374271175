import { css } from "linaria";
import { mediaQuery, width } from "@/utils/styles";

export const wrapper = css`
  display: flex;
  padding: 0 25px;
  flex-direction: row;
  width: 100%;
  max-width: ${width.container};
  margin: 0 auto;
  ${mediaQuery.max} {
    flex-direction: column;
  }
`;
