import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1Appendix, V1TocLayout } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { css } from "linaria";
import { animation } from "@/utils/styles";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202003 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202003Query>(graphql`
    query Index_202003 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 200 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "March 2020";
  const nextPreview = "◆次巻予告・「WAKAKUSA SISTERS」〇四〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#f6f6f6",
    itemColor: "#f6f6f6",
  };
  const bgColor = "#e5b5d1";
  const tocContents = [
    {
      to: "/2020-3/items#tulle-long-dress",
      num: "二",
      title: "水たまりを飛び越える・チュールのドレス",
    },
    {
      to: "",
      num: "七",
      title: "壁と女と酒と頬",
    },
    {
      to: "/2020-3/items#big-collar-blouse",
      num: "十二",
      title: "いつものネイルの帰り道・ガーゼのブラウス",
    },
    {
      to: "",
      num: "十五",
      title: "かばんの中を染めあげる",
    },
    {
      to: "/2020-3/items#dress",
      num: "十九",
      title: "芬蘭架空散歩日記・ガーゼのワンピース",
    },
    {
      to: "",
      num: "二十二",
      title: "ピンクで辛い",
    },
    {
      to: "/2020-3/items#long-skirt",
      num: "二十六",
      title: "おひなさまの呪い・ティアードのドレス",
    },
    {
      to: "",
      num: "二十九",
      title: "いちごとミルクの忖度",
    },
    {
      to: "",
      num: "三十一",
      title: "作者あとがき",
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="March-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-3/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) =>
              t.to === "" ? (
                <V1Appendix
                  key={t.title}
                  txtColor={tocProps.txtColor}
                  style={{ flex: 1 }}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                </V1Appendix>
              ) : (
                <Anchor
                  to={t.to}
                  key={t.title}
                  style={{ flex: 1 }}
                  className={hover}
                >
                  <V1Appendix txtColor={tocProps.txtColor}>
                    <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  </V1Appendix>
                </Anchor>
              )
            )}
          </>
          <Anchor to="/2020-4#April-2020" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

const hover = css`
  transition: opacity ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export default Index_202003;
