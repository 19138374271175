import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout2,
  V1PurchaseBtn,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_201912 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_201912Query>(graphql`
    query Items_201912 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "tulle-long-dress",
      title: {
        main: "TULLE LONG DRESS",
        sub: "かざる",
      },
      desc: `はじめて憧れのシューズを買った時
      箱を開けたらチュールが出てきて
      息が止まるかと思った
      
      チュールにやさしく包まれたシューズは
      それはそれは神々しくて
      宝物を見つけた時の勇者って
      こんな気持ちだったのかしら？
      
      私たちの夢が詰まっている
      繊細で、でも実は強い
      チュールに包まれたなら
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92op02_nor",
      story: `袖を通すと
      優しさに包まれたなら
      って思わず口から出る
      そんなチュールのドレス。
      “特別な日” を思い切り楽しむためにも
      ”なんでもない日” を特別な気持ちで過ごすためにも
      なんてぴったりなのでしょう。
      
      少し硬めのチュールをたっぷり使い
      （本当に、たっぷりと！）
      なかなか無い贅沢なシルエット。
      
      スタイルアップしやすい
      少し上気味のウエスト切り替えに
      ノースリーブですので
      そのまま1枚でも、
      上に1枚重ねても、
      中に1枚きてみても。
      お好きなように
      コーディネートの幅は無限大です。
      パーティーにも、普段使いにも
      合わせやすいブラックでの提案です。
      `,
    },
    {
      id: "wrap-dress",
      title: {
        main: "wrap dress",
        sub: "ギャザーを寄せて",
      },
      desc: `包装紙って偉大だ
      一瞬にしてそれが
      “プレゼント” だってわかるけれど
      中に何が入っているかはわからない。
      
      はやる気持ちを抑えて
      まるで子供の頃のように
      本当はビリビリと破ってしまいたい
      ところだけれど
      私はもう大人だから
      余裕を見せて
      丁寧にあける。
      
      あけるまでの時間だって愛おしいのよ
      中に何が入っているかはわからないけれど
      それは紛れもなく私へのプレゼントだから。
      `,
      color: {
        en: "charcoal gray",
        ja: "チャコールグレー",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92op03_nor",
      story: `包装紙のように ”ラッピング” できる
      ラップドレス。
      スーツのような素材で
      コクーンのようなシルエット
      少し ”シック” な装いにピッタリです。
      
      とはいえ包装紙をイメージしたドレスですから
      中に合わせるものを選ぶ、なんてことありません。
      
      愛くるしい柄のドレスでも
      繊細なアンティークのスカートでも
      カジュアルなボーダーのトップスでも
      好きなものを中に忍ばせてください。
      
      柄物の包装紙も好きですが
      リボンや飾りでいかようにもなる
      シックな1色の包装紙にも憧れます。
      プラスするアイテムでガラッと印象が変えられる
      万能カラーチャコールグレーでの提案です。
      `,
    },
    {
      id: "ribbon-blouse",
      title: {
        main: "ribbon blouse",
        sub: "むすぶ",
      },
      desc: `例えばコンビニのチョコに
      リボンを結ぶだけでも
      それは簡単にプレゼントになる
      憧れのジュエリーブランドの
      紙バッグには
      常にリボンが結ばれている
      
      リボンを自分に結んで見る
      ちょっとだけ ”特別な” 気分になる
      
      ドレスに合わせたって
      デニムに合わせたって
      今日の私は ”特別”
      `,
      color: {
        en: "white",
        ja: "ホワイト",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92bl02_nor",
      story: `1年中着れるように
      厚すぎず、薄すぎず。
      ちょうどいい透け感のコットンの生地を使用しています。
      リボンの長さはなかなか他では見られない。
      一重でも、二重でも、三重でも。
      前・横・後ろ どこで結んでも素敵です。
      
      春は同系色をインナーに
      ワンピースやセッタアップに合わせればセレモニーにもぴったりです。
      夏は中にわざと色柄ものを入れて透けさせても。
      デニムや朝のスカートでラフに、軽やかに。
      秋はプリーツスカートやタックパンツで、ちょっとだけ、クラシカルなのはいかが？
      冬はニットを重ね、さらにその上からノーカラーコートを。
      リボンだけ出すのも素敵です。
      
      1年中、365日、いつだって ”特別” になれるコーディネート。
      毎日着たくなるホワイトでの提案です。
      `,
    },
  ];
  const title = {
    main: "DRESS ME UP",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="dress-me-up"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2019-12/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2019-12/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2019-12/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_201912;
