import React, { VFC } from "react";
import * as styles from "./styles";

type IndexLayoutProps = {
  children: JSX.Element[];
  className?: string;
};

const IndexLayout: VFC<IndexLayoutProps> = ({ children, ...props }) => {
  return (
    <section className={styles.root} {...props}>
      {children[0]}
      {children[1]}
    </section>
  );
};

export default IndexLayout;
