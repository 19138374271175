import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202108 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202108Query>(graphql`
    query Items_202108 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "skirt-the-dead-of-night",
      title: {
        main: "182 skirt - the dead of night -",
        sub: "丑満時の182スカート",
      },
      desc: `うしみつ時。
      
      やつらは
      みんな
      楽しそうに、
      散歩をしている。
      
      そう、
      こんな漆黒の、
      闇夜にまぎれる
      服を着て。
      
      後ろの正面、
      だあれ、だ？
      `,
      color: {
        en: "night black",
        ja: "丑満時の黒",
      },
      recommend: [
        "savon, the fairy",
        "tableware, the fairy",
        "cushion, the fairy",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk01_r_nor1",
    },
    {
      id: "cushion-the-fairy-gillet",
      title: {
        main: "Cushion, the fairy - gillet",
        sub: "クッションカバーのようせい",
      },
      desc: `尻に敷かれっぱなし
      背もたれに使われたことも
      ああ、頭に敷かれたこともあったわね、
      たまによだれがついたの、
      嫌だったわあ。
      私の性格（中身）が変わっても
      ずっと一緒にいてくれたから
      外見にしか取り柄がないのねって
      思った時もあったけど
      ねえ、もう飽きちゃったの？
      （ひゅー、どろどろ）
      `,
      color: {
        en: "white",
        ja: "ホワイト",
      },
      recommend: [
        "savon, the fairy",
        "tableware, the fairy",
        "cushion, the fairy",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm212gl01_nor1",
    },
    {
      id: "dress-the-dead-of-night",
      title: {
        main: "182 dress - the dead of night -",
        sub: "丑満時の182ドレス",
      },
      desc: `うしみつ時。
      やつらは
      みんな
      楽しそうに、
      散歩をしている。
      そう、
      こんな漆黒の、
      闇夜にまぎれる
      服を着て。
      後ろの正面、
      だあれ、だ？
      `,
      color: {
        en: "night black",
        ja: "丑満時の黒",
      },
      recommend: [
        "cushion, the fairy",
        "savon, the fairy",
        "wrapping paper, the fairy",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op04_r_nor1",
    },
    {
      id: "umbrella-the-fairy-skirt",
      title: {
        main: "umbrella, the fairy - skirt",
        sub: "かさのようせい",
      },
      desc: `あなたと出会った日のこと、覚えてる
      天気予報が大はずれ、な日だったね
      君がいなきゃダメなんだって
      すごくすごく求めてくれた
      なのに今日
      私は知らない駅で
      知らない人に連れて行かれて
      これからどうなるんだろう
      せめてお別れが言いたかった
      
      思いはここに呪縛する
      （ひゅー、どろどろ）
      `,
      color: {
        en: "daddy check",
        ja: "紺 × 緑チェック",
      },
      recommend: [
        "cushion, the fairy",
        "tableware, the fairy",
        "savon, the fairy",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm212sk01_nor1",
    },
  ];
  const story = `ある人はそれを、
  お化けと呼び、妖怪と呼び、
  鬼と呼び、妖精と呼ぶ。
  神と呼ぶ人も、いるかもしれない。
  そもそもそれは、いるのかもしれないし、
  もしかしたらいないのかもしれない。
  私にもわからない。
  
  でもたしかに、
  自分の所有しているモノ（コト）に対して、
  誰しも愛情を持ったことはあると思う。
  そして私たちは、
  それを捨てなくてはいけない時に、
  「チクリ」とココロを刺される感覚を
  持ったこともあると思う。
  あるいは、後ろ髪をひかれる感覚とか。
  
  7月から9月までのテーマは
  「マダマダ百着夜行」。
  百鬼夜行をもじりました。
  夏至（6末）がすぎ、秋分（9末）を迎えるまで、
  太陽のちからが強くなる。
  そのぶんぎゅぎゅっと凝縮された夜に、
  あやしげに、そして楽しそうに
  お散歩している“やつら”（妖精、あるいは妖怪。
  おばけ。鬼。）はいるはずだ、と確信したので
  服にしました。
  
  ひゅー、どろどろ。
  `;
  const title = {
    main: "HYAKKIYAKOU",
    sub: "第二部・ワクワク★丑満時のお散歩タイム",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="hyakkiyakou-2"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-8/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-8/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-8/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[3].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-8/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202108;
