import React from "react";
import ReactMarkdown from "react-markdown";

import Layout from "@/components/layout";
import Seo from "@/components/seo";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "linaria";
import { fonts, mediaQuery } from "@/utils/styles";

const body = `
# プライバシーポリシー
株式会社ステイト・オブ・マインド（以下「当社」という。）は、お客様の個人情報保護の重要性を強く認識し、またお客様との信頼関係を今後も恒久的に築いて行くため、以下の通りプライバシーポリシーを定め、全社を挙げてお客様の個人情報の適切な保護に努めます。

## 1. 法令等の遵守
当社は、個人情報の取扱いについて、『個人情報の保護に関する法律』、『電気通信事業法』、その他個人情報保護関連法令及びガイドライン、またこのプライバシーポリシーを遵守します。

## 2. 個人情報の定義
個人情報とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、その他の記述等により特定の個人を識別することが出来るものを言います。これには他の情報と照合することが出来、それにより特定の個人を識別出来るものを含みます。

## 3. 個人情報の利用目的について
当社では、個人情報の取得に際しては、本プライバシーポリシー内または各サービスのウェブサイト内おいて、あらかじめ利用目的をできる限り特定した上で公表します。また、公表した利用目的にしたがって個人情報を取り扱います。当社で取得する個人情報の利用目的は以下の通りです。

### ① 当社サービスをご利用いただいた方又は当社サービスにご関心をお持ちの方（以下「お客様」といいます。）の個人情報の利用目的
1. 利用規約、利用料金表に基づいた各サービスの利用料金又は当社が販売した商品代金のご請求のため
2. 当社サービスに関する契約のお申し込み、退会、ご登録情報変更、ご契約更新・継続のご案内及び確認手続きのため
3. 当社又は当社が提供する各サービスに関していただいたお問い合わせに関する内容確認、調査、又はご返信のため
4. 当社が提供する各サービスの障害情報、メンテナンス情報等技術的なサポートに関する情報のご提供のため
5. 新サービス、新商品、機能改善等お客様に有用と思われる情報の告知、広告、宣伝、ダイレクトメールの送付、お電話によるご紹介等、サービスに関する各種ご提案のため
6. 商品発送、当社が実施するアンケートへのご協力のお願いのため、ご登録いただいた個人情報を元に、個人として特定できない範囲において統計情報として集計し、お客様に有用なサービス開発の参考資料とするため
7. 当社が提供する各サービス及びそれに関連するサービスのご提供及び当社サービス利用者の管理のため
8. 各サービスの提供に当たりお客様本人からあらかじめ同意を得ている場合に、個人情報を第三者に提供するため
9. その他、各サービスの提供に当たり利用目的を公表の上、同意をいただいた利用目的のため

※ なお、各サービスの利用に際しては、お客様の意思によって利用される個人情報と連動した機能の取り扱いには格別のご注意をいただきますようお願いいたします。

### ② 当社への採用応募者の個人情報の利用目的
1. 応募歴の確認、履歴書・職務経歴書等個人情報記載事項をもとにした選考のため
2. 面接日時、採用不採用等合否のご連絡のため
3. 採用後の賃金決定、雇用管理のため
4. 前 3 号に掲げる場合のほか、ご本人から同意を得た利用目的のため

### ③ 当社と新規でお取引いただく場合に、個人のお取引先様よりご提供いただいた個人情報の利用目的
1. お取引先様としての登録のため
2. 法令に基づくコンプライアンス遵守のため

### ④ 当社の株主様（以下「株主様」といいます。）の個人情報の利用目的
1. 株主様の法律に基づく権利の行使への対応や、当社の義務の履行のため
2. 当社が定める株主優待制度等、株主様としての地位に対し、当社から便宜を供与するため
3. 法令に基づく所定の基準による株主様のデータ（株主名簿等）を作成する等、株主様の管理のため

## 4. 利用目的の範囲内での利用
当社は、あらかじめ特定し公表した利用目的の達成に必要な範囲内でのみお客様の個人情報を取り扱います。但し、『電気通信事業における個人情報保護に関するガイドライン』第 5 条第 3 項にある以下の各号に該当する場合は、予め特定し公表した利用目的の達成に必要な範囲を超えてお客様の個人情報を取り扱うことがあります。

### ① 法令に基づく場合
### ② 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
### ③ 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
### ④ 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき

## 5. 保存期間
当社は、利用目的に必要な範囲内でお客様の個人情報の保存期間を定め、保存期間経過後又は利用目的達成後はお客様の個人情報を遅滞なく消去いたします。但し、『電気通信事業における個人情報保護に関するガイドライン』第 10 条第 1 項にある以下の各号に該当する場合はこの限りではありません。

### ① 法令の規定に基づき、保存しなければならないとき
### ② 本人の同意があるとき
### ③ 電気通信事業者が自己の業務の遂行に必要な限度で個人情報を保存する場合であって、当該個人情報を消去しないことについて相当な理由があるとき
### ④ 前 3 号に掲げる場合のほか、当該個人情報を消去しないことについて特別の理由があるとき

## 6. 個人情報の安全管理措置について
お客様よりお預かりした個人情報は、組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の紛失、破壊、改ざん、及び漏えい等を防止いたします。

## 7. 従業者の監督について
個人情報の取り扱いに関する内部規程類を明確化し、適切に個人情報を取り扱うよう従業者を監督いたします。

## 8. 個人情報の第三者提供について
お客様よりお預かりした個人情報は、お客様本人の同意を得ずに第三者に提供することは、原則いたしません。但し以下の場合は、関係法令に反しない範囲で、お客様本人の同意なくお客様の個人情報を提供することがあります。

### ① 法令に基づく場合
### ② 人の生命、身体又は財産の保護のために必要がある場合であって、お客様本人の同意を得ることが困難であるとき
### ③ 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様本人の同意を得ることが困難であるとき
### ④ 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
### ⑤ お客様本人が希望されるサービスを行うために業務を委託している業者に対して開示・提供する場合
### ⑥ 各サービスの提供に当たり、お客様本人からあらかじめ同意を得た上で、個人情報を下記の第三者に提供させていただく場合があります。

## 9. Cookie およびユーザー行動情報の取得について
当社は、当社サービスの利便性向上、統計データ分析及び広告の効果測定の目的で Cookie（クッキー）を取得しています。

Cookie（クッキー）とは、お客様がWebサイトを快適にご利用できるように、Webサーバがお客様のブラウザに送信する情報です。 なお、お客様は、Cookie（クッキー）の受け取りを拒否するように設定することもできますが、 その場合、当社が提供するサービスの全部又は一部をご利用できなくなることがありますので予めご了承ください。 Cookie はお客様のブラウザを識別することはできますが、お客様個人を特定することはできません。

## 10. 継続的改善
当社は、個人情報の取り扱いを適切なものとするよう、継続的な改善を実施します。

## 11. 個人情報の開示、訂正、削除について

### ① 各サービスごとの個人情報開示・訂正方法
当社が提供する各サービスでは、当社がお預かりした個人情報の全て、または一部が合理的、技術的に適切な方法で保護された環境下で閲覧、訂正することが可能な場合があります。 各サービスサイトよりユーザー専用ページにログインしてください。ユーザー専用ページから、それぞれのサービスにてお預かりした個人情報の閲覧、訂正が可能です。

### ② 当社保有の個人情報の開示方法
前項の方法で閲覧、訂正等を行うことができないお客様の個人情報について、お客様本人が個人情報の利用目的の通知、個人情報の開示、訂正、追加、削除、利用の停止又は第三者への提供の停止を希望される場合、ご請求いただければ、合理的な範囲内で速やかに対応いたします。但し、『電気通信事業における個人情報保護に関するガイドライン』第 20 条第 2 項各号にある以下の各号に該当する場合は、お客様のご請求に対応できない場合があります。

1. 本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
2. 当該電気通信事業者の業務の適正な実施に著しい支障を及ぼすおそれがある場合
3. 他の法令に違反することとなる場合

## 12. お問い合わせ窓口
当社における個人データの取扱いに関するご質問やご苦情に関しては下記の窓口にご連絡ください。

### ① 住所
〒153-0044 東京都目黒区大橋1丁目6-13 STUMPS IKEJIRI OHASHI 4階

株式会社ステイト・オブ・マインド　管理部　お問合せ窓口

### ② 電話番号
03-6712-7883

### ③ 受付時間
月曜～金曜（祝日、年末年始は除く）
10時～12時、13時～17時

株式会社ステイト・オブ・マインド

〒153-0044 東京都目黒区大橋1丁目6-13 STUMPS IKEJIRI OHASHI 4階

代表取締役　伊藤　悠平

Email: info@state-of-mind.co.jp
`;

const PrivacyPolicyPage = () => {
  const data = useStaticQuery<GatsbyTypes.PrivacyPolicyPageQuery>(graphql`
    query PrivacyPolicyPage {
      site {
        siteMetadata {
          navs {
            label
            to
            isExternal
          }
        }
      }
    }
  `);
  return (
    <Layout navs={data.site.siteMetadata.navs}>
      <Seo title="プライバシーポリシー" />
      <main role="main" className={container}>
        <ReactMarkdown>{body}</ReactMarkdown>
      </main>
    </Layout>
  );
};

const container = css`
  width: 1080px;
  margin: 20px auto 20px;
  padding: 0 15px;

  h1 {
    font-size: ${fonts.size.xxxxl};
    font-weight: ${fonts.weight.bold};
    line-height: ${fonts.lineHeight.xl};
    margin: 35px 0;
    text-align: center;
  }

  h2 {
    font-size: ${fonts.size.xxxl};
    font-weight: ${fonts.weight.bold};
    line-height: ${fonts.lineHeight.xl};
    margin: 35px 0 15px;
  }

  h3 {
    font-size: ${fonts.size.xxl};
    font-weight: ${fonts.weight.bold};
    line-height: ${fonts.lineHeight.xl};
    margin: 25px 0 15px;
  }

  ol {
    padding: 10px;
  }

  ul {
    padding: 10px;
  }

  ol li {
    font-size: ${fonts.size.l};
    list-style: lower-latin;
    margin: 0 0 10px 20px;
  }

  p {
    font-size: ${fonts.size.l};
  }

  ${mediaQuery.middleMax} {
    width: auto;
  }
`;

export default PrivacyPolicyPage;
