import { useState, useEffect } from "react";

export const useWindowSize: () => {
  windowHeight: number;
  updateWindowSize: () => void;
  windowWidth: number;
} = () => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: 0,
    windowHeight: 0,
  });
  let cullTime = null;
  const updateWindowSize = () => {
    setWindowSize({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };
  const handleResize = () => {
    clearTimeout(cullTime);
    cullTime = setTimeout(() => {
      updateWindowSize();
    }, 80);
  };

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", handleResize);

    return () => {
      if (typeof window === "undefined") return;
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { ...windowSize, updateWindowSize };
};
