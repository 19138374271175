import React from "react";
import * as styles from "./styles";

const txtFactory =
  (role?: string) =>
  ({
    tag: Tag = "p",
    size = "m",
    className = "",
    isCenter = false,
    isWrapping = false,
    isBold = false,
    ...props
  }) => {
    isCenter && (className += ` ${styles.center}`);
    isWrapping && (className += ` ${styles.wrapping}`);
    isBold && (className += ` ${styles.bold}`);
    return (
      <Tag
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={[styles[role], styles[size], className].join(" ")}
        {...props}
      />
    );
  };

const Txt = txtFactory("normal");
export const VerticalTxt = txtFactory("vertical");
export default Txt;
