import { css } from "linaria";
import { colors, shadow } from "@/utils/styles";

export const root = css`
  width: 100%;
  margin-bottom: 5px;
`;

export const btn = css`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colors.base};
  cursor: pointer;
  box-shadow: ${shadow.basic};
`;

export const txt = css`
  color: ${colors.bg};
  & > svg {
    fill: ${colors.bg};
    margin-left: 5px;
  }
`;
