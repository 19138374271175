import { css } from "linaria";
import { border, mediaQuery } from "@/utils/styles";

export const container = css`
  border: ${border.basic};
  padding: 30px 40px;

  ${mediaQuery.min} {
    width: calc(50% - 5px);
    margin-right: 5px;
  }

  ${mediaQuery.max} {
    width: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const header = css`
  margin-bottom: 40px;
`;

export const desc = css`
  line-height: 2;
`;
