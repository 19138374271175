import { css } from "linaria";
import { mediaQuery } from "@/utils/styles";

export const root = css`
  display: flex;
  justify-content: center;
  padding: 15px 25px;
`;

export const img = css`
  width: 170px;
  ${mediaQuery.max} {
    width: 90px;
  }
`;
