import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202106 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202106Query>(graphql`
    query Items_202106 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "tane-many-many-seeds-blouse",
      title: {
        main: "“TANE” many many seeds blouse",
      },
      desc: `“STORY”
      
      かつて 大輪を咲かせた花も
      いつか老い、
      実になり、
      種になっていく。
      変わっていくこと、
      自分を受け入れていくこと、
      花の時は気付かれなかったのに
      実になってから気付かれること。
      `,
      color: {
        en: "off yellow",
        ja: "オフイエロー",
      },
      recommend: ["“WATAGE” fluffy skirt", "LONG SKIRT", "Lace line pants"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211bl03_nor1",
    },
    {
      id: "twins-layered-bicolor-dress",
      title: {
        main: "“TWINS” layered bicolor dress",
      },
      desc: `“STORY”
      
      森の奥深く。
      ふたりだけ、ひっそりといる、
      絶滅危惧種の花。
      誰にも見つからず、
      ふたりはずっと一緒にいる
      こんな深い森の中
      種をとどけてくれるものもなく
      このままずっと
      ふたりで、ひとり
      静かに
      一生を全うする
      `,
      color: {
        en: "indigo × white",
        ja: "インディゴ × ホワイト",
      },
      recommend: [
        "“MEE” CHECK BUTTONS JACKET",
        "gown dress",
        "Lace line jacket coat",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op09_nor1",
    },
    {
      id: "candy-sleeve-anorak",
      title: {
        main: "candy sleeve anorak",
        sub: "雨？飴？アノラック - 植物エディション -",
      },
      desc: `あるときは
      お花屋さんの制服に
      あるときは
      お庭いじりに
      あるときは
      裏山へ探検に
      いつも
      フォレスト・カーキに身を包んで
      `,
      color: {
        en: "forest khaki",
        ja: "フォレストカーキ",
      },
      recommend: [
        "LONG SKIRT",
        "”RARA” tulle 5 skirt",
        "”WATAGE” fluffy skirt",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201ar01_nor1_kh",
    },
    {
      id: "rose-check-big-pocket-blouse",
      title: {
        main: "“ROSE” check big pocket blouse",
      },
      desc: `“STORY”
      
      - mm clan（マダマダ族） -
      いつかの時代、とある世界の、
      ちょっとふしぎな国。
      おいしいものと、
      おめかしが大好きな一族がいました。
      彼女たちの生活を、少し覗いてみましょう。
      今回は、街で一番季節の移り変わりに
      敏感！なROSE（ローゼ）さん。
      表の顔は花のように可憐で
      ちょっとドジなお花屋さんですが、
      実はハイパーな植物学者としての
      裏の顔を持ち、
      日々研究も欠かしません。
      さて、彼女の着ているお洋服は・・？
      `,
      color: {
        en: "check",
        ja: "グリーンベースチェック",
      },
      recommend: [
        "“ROSE” funny pocket check skirt",
        "Lace line pants",
        "TICKET PANTS",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211bl04_nor1",
    },
    {
      id: "rose-funny-pocket-check-skirt",
      title: {
        main: "“ROSE” funny pocket check skirt",
      },
      desc: `“STORY”
      
      - mm clan（マダマダ族） -
      いつかの時代、とある世界の、
      ちょっとふしぎな国。
      おいしいものと、
      おめかしが大好きな一族がいました。
      彼女たちの生活を、少し覗いてみましょう。
      今回は、街で一番季節の移り変わりに
      敏感！なROSE（ローゼ）さん。
      表の顔は花のように可憐で
      ちょっとドジなお花屋さんですが、
      実はハイパーな植物学者としての
      裏の顔を持ち、
      日々研究も欠かしません。
      さて、彼女の着ているお洋服は・・？
      `,
      color: {
        en: "check",
        ja: "グリーンベースチェック",
      },
      recommend: [
        "“ROSE” check big pocket blouse",
        "shelter blouse",
        "mint T-blouse",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211sk03_nor1",
    },
  ];
  const story = `誰が作ったんだろう?
  不思議な植物図鑑に載っている、
  「誰も知らない花」たち。
  
  人間の一生と、
  同じようにたどる
  
  種が漂い、
  みんな普通の顔して成長する
  最盛期には輝き、
  終わりの足音が忍び寄る
  やがて花ではなく、実に、種に。
  
  今日もまたページが増える
  誰も知らない、はずの植物図鑑
  `;
  const title = {
    main: "THE FLOWERS NOONE KNOWS",
    sub: "第三部・そしてまた、咲く。",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="the-flowers-noone-knows-3"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-6/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-6/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-6/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-6/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[4].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[4].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[4].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[4].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-6/items/05.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[4].color.en}</Txt>
              <Txt size="l">（{items[4].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[4].recommend} />
            <V1PurchaseBtn link={items[4].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202106;
