import React from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  V1CoverLayout,
  V1IndexLayout,
  V1MonthlyStoryLayout,
} from "@/components/atoms/v1";
import { V1StoryBtn } from "@/components/molecules/v1";
import { Heading, Txt } from "@/components/atoms";

const Index_201912 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_201912Query>(graphql`
    query Index_201912 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const bookTitle = "December 2019";
  const story = {
    head: "12月のおはなし “DRESS ME UP”",
    body: `12月。
    
    1年の終わりが来る、
    そしてまた新しい1年がやって来る。
    
    貰い受けるほどに
    届ける思いも
    素直に解けるこのリボン
    
    特に意味ないものでもいいから
    ただ、見えないものを形にしたい
    何度もなんて恥ずかしいから
    1年にたった1度だけでもいいかなって
    
    今日までをありがとうと
    これからも今と何も変わらず
    よろしくお願いねって笑顔と一緒に
    
    12月のマダマダは、
    
    自分を着飾る、プレゼントのように。
    1年の終わり、と1年の始まり。
    今年も1年ありがとうございました、と
    自分にも、周りにも改めて感謝したい
    思いを込めてプレゼント。
    
    装うということは
    包み込むということ
    あたたかい気持ちになれる
    そんなスタイルを提案します`,
  };
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="December-2019"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2019-12/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1MonthlyStoryLayout>
          <Heading level={3}>{story.head}</Heading>
          <Txt isWrapping size="l">
            {story.body}
          </Txt>
          <V1StoryBtn link="/2019-12/items" />
        </V1MonthlyStoryLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_201912;
