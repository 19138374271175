import { css } from "linaria";
import { animation } from "@/utils/styles";

export const img = css`
  margin-bottom: 10px;
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export const color = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;
