import React from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  V1CoverLayout,
  V1IndexLayout,
  V1MonthlyStoryLayout,
} from "@/components/atoms/v1";
import { V1StoryBtn } from "@/components/molecules/v1";
import { Heading, Txt } from "@/components/atoms";

const Index_201909 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_201909Query>(graphql`
    query Index_201909 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const bookTitle = "September 2019";
  const story = {
    head: "9月のおはなし “TEA TIME”",
    body: `9月。
    
    まだまだ暑い日が続いていますが
    朝晩はだんだんと涼しくなってきました
    
    その日の気分で選んでいれる
    あつーいお茶
    1日の始まりに、
    ちょっと疲れちゃったな、休憩に
    1日のしめに、
    ゆっくりゆっくりいただきます
    
    そんな時に一緒にいただく
    美味しい　美味しい
    お菓子があったらきっと素敵・・
    
    9月のマダマダは
    お茶の時間のための
    準備の時間
    
    お菓子とお茶を
    作る、
    買う、
    いただく。
    
    それぞれのシーンを思い浮かべた
    スタイルを提案します`,
  };
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="September-2019"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2019-9/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1MonthlyStoryLayout>
          <Heading level={3}>{story.head}</Heading>
          <Txt isWrapping size="l">
            {story.body}
          </Txt>
          <V1StoryBtn link="/2019-9/items" />
        </V1MonthlyStoryLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_201909;
