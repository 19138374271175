import React, { VFC } from "react";
import * as styles from "./styles";
import { Txt } from "@/components/atoms";

type ItemRightLayout2Props = {
  children: JSX.Element[];
  className?: string;
};

const ItemRightLayout2: VFC<ItemRightLayout2Props> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.img}>{children[0]}</div>
      <div className={styles.color}>{children[1]}</div>
      <div className={styles.storyWrapper}>
        <div className={styles.story}>{children[2]}</div>
      </div>
      <div className={styles.btnWrapper}>
        {children[3]}
        <Txt size="s" isCenter>
          または写真をタップしてください
        </Txt>
      </div>
    </div>
  );
};

export default ItemRightLayout2;
