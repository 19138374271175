import { css } from "linaria";
import { mediaQuery } from "@/utils/styles";

export const root = css`
  display: flex;
  justify-content: center;
  padding: 45px 25px;
  text-align: center;
  ${mediaQuery.max} {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  > a {
    color: inherit;
    margin: 0 10px;
    transition: 0.3s;

    &: hover {
      opacity: 0.8;
    }
  }

  > p {
    margin: 0 10px;
  }
`;
