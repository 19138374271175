import React, { VFC } from "react";
import * as styles from "./styles";

type MonthlyStoryLayoutProps = {
  children: JSX.Element[];
  className?: string;
};

const MonthlyStoryLayout: VFC<MonthlyStoryLayoutProps> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.head}>{children[0]}</div>
      <div className={styles.body}>{children[1]}</div>
      <div className={styles.btn}>{children[2]}</div>
    </div>
  );
};

export default MonthlyStoryLayout;
