import React from "react";
import Layout from "@/components/layout";
import Seo from "@/components/seo";
import BookTitle from "@/components/molecules/BookTitle";
import { Profile } from "@/components/organisms";

const ItemsPageLayout = ({
  siteMetadata,
  bookTitle,
  subTitle,
  desc,
  id,
  children,
}) => {
  return (
    <Layout navs={siteMetadata.navs}>
      <Seo title={bookTitle} description={desc} />
      <main>
        <BookTitle bookTitle={bookTitle} subTitle={subTitle} id={id} />
        {children}
      </main>
      <Profile
        designerName={siteMetadata.designer}
        desc={siteMetadata.desc}
        links={siteMetadata.links}
      />
    </Layout>
  );
};

export default ItemsPageLayout;
