import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1TocLayout,
  V1TocChildLayout,
  V1Appendix2,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202109 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202109Query>(graphql`
    query Index_202109 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 160 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "September 2021";
  const nextPreview = "◆次巻予告・「パンプキンパイ大作戦！」一〇〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#fff",
    itemColor: "#c20e22",
  };
  const bgColor = "#232323";
  const tocContents = [
    {
      to: "/2021-9/items#tableware-the-fairy-blouse",
      num: "十四",
      title: "しょっき　の　ようせい",
      item: "テーブルウェア・ブラウス",
    },
    {
      to: "/2021-9/items#wrapping-paper-the-fairy-gown-dress",
      num: "二十一",
      title: "ほうそうし　の　ようせい",
      item: "ラッピングペーパー・ガウンドレス",
    },
    {
      to: "/2021-9/items#tea-bag-the-fairy-dress",
      num: "二十八",
      title: "ティーバッグ　の　ようせい",
      item: "ティーバッグ・ドレス",
    },
  ];
  const appendix = [
    "リデル・ドレス（〇九〇七）",
    "チケット・パンツ（予約・〇九一四）",
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="September-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-9/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <V1TocChildLayout
                {...tocProps}
                paddingTop={responsive.paddingTop}
                key={t.title}
                to={t.to}
                style={{ flex: 1 }}
              >
                <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
              </V1TocChildLayout>
            ))}
            <V1Appendix2 txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>特別付録</VerticalTxt>
              {appendix.map((a) => (
                <VerticalTxt key={a} {...txtProps}>
                  {a}
                </VerticalTxt>
              ))}
            </V1Appendix2>
          </>
          <Anchor
            to="/2021-10#October-2021"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202109;
