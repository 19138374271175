import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1ItemRightLayout2,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202007 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202007Query>(graphql`
    query Items_202007 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "citrus-tunic",
      title: {
        main: "citrus tunic",
      },
      desc: `夏を振り返ると、いつだっていた。
      
      部活の後にどこからか漂ってくる
      制汗スプレーのグレープフルーツの匂い
      
      おばあちゃんが出してくれた
      レモンの輪っかが浮いたアイスティー
      
      親戚から届いた
      箱いっぱいのオレンジ
      
      旅行先で試食して買った
      日向夏のあまーいジャム
      
      夏が少しだけ苦手だった私を、
      夏が少しだけ好きにしてくれた
      シトラスたちとの思い出
      `,
      color: {
        en: "citrus white",
        ja: "シトラスホワイト",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201po01_nor",
      story: `誰がなんと言おうと、これはシトラスです。
      柑橘を、半分に切って、
      そのまた半分に切って。
      半月型の、美味しい、シトラス。
      その半月型をそのまま表現したら、
      少しユニークなチュニックが出来上がりました。
      
      ミニのワンピースっぽくも、
      少し長めのトップスっぽくも。
      チュニックって様々な表情を見せてくれるので
      特に重ね着をあまりしない夏の時期に
      なんだか重宝するアイテムです。
      
      素材は夏も安心なコットン。
      お洗濯を繰り返して、少しラフな風合いに
      なっていくのも楽しめそうです。
      かぶりタイプで袖や襟もゆとりがあるので、
      秋冬は中に薄手のニット（タートルネック）や
      ブラウスを合わせても素敵です。
      柑橘は夏だけでなく、冬も美味しいですものね。
      
      とても小さなポイントですが、
      袖と襟もとの糸ボタンは
      ひっそりと柑橘をイメージしています。
      胸ポケットには、グレープフルーツは入らないかもしれませんが
      レモンならギリギリ入ります。
      `,
    },
    {
      id: "mint-t-blouse",
      title: {
        main: "mint T-blouse",
      },
      desc: `子供の頃ちょっぴり苦手だった
      良い香りがするのに
      口に入れると不思議な味
      苦いような、スースーするような
      
      いつのまにか気にならなくなって
      おしゃれなことしたい気分の時は
      水の入ったカラフェに浮かばせたりして
      
      そのうちキッチンで育て始めたりして
      これだけで食べるなんてことはないけど
      あると一気に輝く
      ミント
      縁の下の力持ちっていうのかな
      こんなに可愛い色してさ
      `,
      color: {
        en: "mint water",
        ja: "ミントウォーター",
      },
      recommend: ["work skirt", "LONG SKIRT", "lace line pants"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201bl03_nor",
      story: `胸元にある小さな襟、
      そうです、これは「ミント」です。
      マダマダらしいユーモアのある
      モチーフのついたブラウスは、
      Tシャツで使われるようなカットソー素材で
      着心地抜群、優しい肌触り！
      「ブラウス」のように見えて、
      「Tシャツ」のような着心地の
      良いとこ取りなこのアイテムを、
      「T-blouse」と名付けました。
      
      ほんの少しだけ長めの丈に、
      落ちたパフスリーブが
      絶妙なシルエット。
      少しひろめに開けた後ろのボタンが
      程よい抜け感を出しています。
      
      たくさん並んだ袖のボタンは、
      「ミントウォーターに浮かぶ氷」や
      「モヒートのグラスについたソルト」をイメージして
      ひんやりしたすりガラスのようなものを選びました。
      
      季節を問わず、
      例えば冬などはウールジャケットの中に着て
      ちょこんとミントだけ見せるのも素敵。
      春秋はノースリーブのワンピースの中に
      合わせるもの素敵です。
      
      薄いミントカラーは意外と合わせる色を選びませんが、
      ぜひブラウンやブラックを合わせて
      「チョコミント」なコーディネートにも
      トライしてみていただきたいです。
      `,
    },
    {
      id: "lavender-dress",
      title: {
        main: "LAVENDER DRESS",
      },
      desc: `遠いむかし、むかしのおはなし
      
      子供の頃からラベンダーの香りに包まれて
      育ったお姫さま
      彼女の机の上には
      常にラベンダーの砂糖漬けがありました
      
      ラベンダーは強い魔除け効果があり
      降りかかる様々な悪い魔法から守られ
      無事にお姫様はすくすくと育ち
      聡明で美しい女王様になりました
      そして彼女は・・・・・
      
      そんな作り話を頭に浮かべながら
      今夜私がいただくのは
      ラベンダーのショートブレッド
      ドレスだって身にまとっている
      気分は女王様だ
      `,
      color: {
        en: "lavender tea",
        ja: "ラベンダー",
      },
      recommend: ["gown dress", "lace GOWN JACKET", "lace line jacket"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op09_nor",
      story: `ラベンダーをお茶にしたような
      とても優しい色合いの
      ラベンダーカラー。
      着やすい柔らかなコットン素材で作りました。
      
      つけ襟は取り外し可能で
      リボンは前で結んでも、後ろで結んでも。
      ウエストに巻くのも素敵です。
      
      最後に製品洗いをかけたので、
      ヴィンテージのような風合いを持ち
      特に襟のほつれが程よいラフさを出しています。
      アイロンをかけてピシッと着ても、
      洗いざらしで着ても
      シーンによって雰囲気を変えて楽しめそうです。
      
      淡い色でまとめるのももちろん素敵ですが、
      ブラックもしくはダークブラウンなど
      濃い色で引き締めるコーディネートも素敵です。
      秋冬にダークグリーンやボルドーを合わせるのも
      いいなと思っています
      （ハーブガーデンのような色合いですね）
      `,
    },
    {
      id: "curtain-dress-summer",
      title: {
        main: "curtain dress - summer -",
        sub: "ギャザーを寄せて",
      },
      desc: `今日の天気はなんだろう
      雨かな曇りかな晴れかな
      少しだけ光が漏れて見える
      ああ、きっと晴れだ
      
      カーテンをあけて
      全身で今日を感じる
      誰も知らない私も知らない
      今日が始まる
      
      新しい私
      毎日、新しい私
      `,
      color: {
        en: "sand beige",
        ja: "サンドベージュ",
      },
      recommend: ["lace line pants", "lace line jacket"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op10_nor",
      story: `2020年1月に発表した「カーテン」をイメージした
      ギャザーをお好きなだけ寄せれる
      贅沢な生地使用量のドレス。
      ボリュームや美しいシルエットが
      より際立つよう、
      ハリのある素材を使用し
      まるで本当の「カーテン」のような佇まいです。
      「カーテン」なので、暑い時期にも快適に過ごせるよう、
      「コットンリネン」で夏バージョンを作りました！
      
      陽の光が欲しくて
      たっぷりと寄せたギャザーも
      夜帰宅後に閉めて
      すこしだけ寄った
      可愛らしいギャザーも
      カーテンのように
      自由自在にどうぞ
      
      しっかりとしたコットンリネンですので
      「夏バージョン」と言いつつも、
      季節を問わずお召しいただけます。
      春夏にTシャツや薄手のローンのブラウスに合わせるのはもちろん、
      秋冬にもこもこのニットと合わせるのもとても素敵ですよ。
      `,
    },
  ];
  const title = {
    main: "LETTER.",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="madder-madder-clan"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-7/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-7/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-7/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[3].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-7/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[3].story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202007;
