import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1TocLayout, V1TocChildLayout } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202105 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202105Query>(graphql`
    query Index_202105 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 180 : 250,
      txtSize: windowWidth <= 767 ? "l" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "May 2021";
  const nextPreview = "◆次巻予告・「そしてまた、咲く」〇六〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#3c7145",
    itemColor: "#3c7145",
  };
  const bgColor = "#cfdcc8";
  const tocContents = [
    {
      to: "/2021-5/items#heroine-organdy-tiered-dress",
      num: "十一",
      title: "あなたが主役（ヒロイン）。",
      item: "オーガンジー・ティアード・ドレス",
    },
    {
      to: "/2021-5/items#luna-frill-garden-dress",
      num: "十八",
      title: "ルナはいつものように、お庭へ。",
      item: "フリル・ガーデン・ドレス",
    },
    {
      to: "/2021-5/items#milky-easy-wide-pants",
      num: "二十五",
      title: "ミルキーは、おつかいに。",
      item: "イージー・ワイド・パンツ",
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="May-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-5/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
          </>
          <Anchor to="/2021-6#June-2021" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202105;
