import React, { useState } from "react";
import { StickyHeaderLayout } from "@/components/atoms";
import { SiteSymbol } from "@/components/molecules";
import { Header, Footer } from "@/components/organisms";
import { fadeIn } from "@/utils/animation";

const Layout = ({ navs, children }) => {
  const [isVisible, updateVisible] = useState<boolean>(false);
  return (
    <>
      <SiteSymbol />
      <StickyHeaderLayout>
        <Header
          navigations={navs}
          isMenuVisible={isVisible}
          updateVisible={updateVisible}
        />
        <div className={fadeIn}>{children}</div>
      </StickyHeaderLayout>
      <Footer />
    </>
  );
};

export default Layout;
