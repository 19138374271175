import React, { VFC } from "react";
import * as styles from "./styles";

type ItemsLayoutProps = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const ItemsLayout: VFC<ItemsLayoutProps> = ({ children, ...props }) => {
  return Array.isArray(children) ? (
    <article className={styles.root} {...props}>
      {children[0]}
      {children[1]}
      {children[2]}
      {children[3]}
      {children[4]}
    </article>
  ) : (
    <div className={styles.root} {...props}>
      {children}
    </div>
  );
};

export default ItemsLayout;
