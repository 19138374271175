import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202111 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202111Query>(graphql`
    query Items_202111 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "hunter-big-buttons-collar-coat",
      title: {
        main: "“HUNTER” big buttons collar coat",
        sub: "“ハンテル” ビッグボタンコート",
      },
      desc: `“ハンテル”
      
      ハンテルさんの特殊能力は
      たくさんの落ち葉・・
      つまり、落ち葉のじゅうたんを
      いつだって、すぐに、
      見つけられること
      
      それが木枯らし吹く冬だろうが、
      蒼青としげる夏だろうが
      関係ない
      一瞬で見つける
      いとも簡単に
      `,
      color: {
        en: "fallen leaf check",
        ja: "ボルドー系チェック",
      },
      recommend: [
        "“BAKER” classical dress",
        "“COLLECTOR” classical bolero",
        "“COLLECTOR” classical skirt",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212co02_nor1",
    },
    {
      id: "collector-classical-bolero",
      title: {
        main: "“COLLECTOR” classical bolero",
        sub: "“コレクトル” クラシカルボレロ",
      },
      desc: `“コレクトル”
      
      コレクトルさんの特殊能力は
      たくさんの落ち葉たちを
      いつだって、すぐに、
      集められること
      
      それがマダマダドームくらいの
      面積だろうが
      うちの犬のおうちくらいの
      面積だろうが
      
      一瞬で集める
      いとも簡単に
      `,
      color: {
        en: "fallen leaf beige",
        ja: "グレージュ",
      },
      recommend: [
        "“COLLECTOR” classical skirt",
        "“BAKER” classical dress",
        "TICKET PANTS",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212BL04_nor1",
    },
    {
      id: "collector-classical-skirt",
      title: {
        main: "“COLLECTOR” classical skirt",
        sub: "“コレクトル” クラシカルスカート",
      },
      desc: `“コレクトル”
      
      コレクトルさんの特殊能力は
      たくさんの落ち葉たちを
      いつだって、すぐに、
      集められること
      
      それがマダマダドームくらいの
      面積だろうが
      うちの犬のおうちくらいの
      面積だろうが
      
      一瞬で集める
      いとも簡単に
      `,
      color: {
        en: "fallen leaf beige",
        ja: "グレージュ",
      },
      recommend: [
        "“HUNTER” big buttons collar coat",
        "“COLLECTOR” classical bolero",
        "Madame Butter’s collar dress",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212sk02_nor1",
    },
    {
      id: "baker-classical-dress",
      title: {
        main: "“BAKER” classical dress",
        sub: "“バケル” クラシカルドレス",
      },
      desc: `“バケル”
      
      バケルさんの特殊能力は
      たくさんの落ち葉たちを
      いつだって、すぐに、
      焼くことができること
      
      そこに焼き芋を入れようが
      あるいはマシュマロを棒に刺そうが
      
      一瞬で（美味しく）焼ける
      いとも簡単に
      `,
      color: {
        en: "black × off white",
        ja: "クロ × オフホワイト",
      },
      recommend: [
        "“HUNTER” big buttons collar coat",
        "“COLLECTOR” classical bolero",
        "“Himawari” big sailor collar bolero 21aw",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212op04_nor1",
    },
  ];
  const story = `架空の民族 “マダマダ族” の新シリーズ。
  “普段着”、“民族衣装”、“職業服” などなど、様々なものを出してきましたが今回は “落ち葉拾い” のプロ集団、3人組の “落ち葉拾い” のための服。
  マダマダ族といえば！なチェックやベッチンなどなど、仲良し3人組が着ているのは、さて・・・？
  `;
  const title = {
    main: "O.CHI.BA. HI.RO.I.",
    sub: "おちばひろい",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="ochiba-hiroi"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-11/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-11/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-11/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[3].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-11/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202111;
