import { css } from "linaria";
import { animation, border } from "@/utils/styles";

export const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const img = css`
  margin-bottom: 10px;
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export const color = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const storyWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 20px;
  margin-bottom: 20px;
  border: ${border.width} dashed ${border.itemColor};
  border-radius: ${border.radius};
`;

export const story = css`
  padding-bottom: 20px;
  border-bottom: ${border.width} dashed ${border.itemColor};
  & > p {
    line-height: 2.1;
  }
`;

export const recommend = css`
  width: 100%;
  padding-top: 20px;
  & > p {
    margin-bottom: 5px;
  }
`;

export const btnWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
