import React from "react";

import Layout from "@/components/layout";
import Seo from "@/components/seo";
import { Heading, Txt } from "@/components/atoms";
import { graphql, useStaticQuery } from "gatsby";

const NotFoundPage = () => {
  const data = useStaticQuery<GatsbyTypes.NotFoundPageQuery>(graphql`
    query NotFoundPage {
      site {
        siteMetadata {
          navs {
            label
            to
            isExternal
          }
        }
      }
    }
  `);
  return (
    <Layout navs={data.site.siteMetadata.navs}>
      <Seo title="404: Not found" />
      <Heading level={1}>404: Not Found</Heading>
      <Txt isCenter>お探しのページは見つかりませんでした。</Txt>
    </Layout>
  );
};

export default NotFoundPage;
