import { css } from "linaria";

export const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const imgWrapper = css`
  margin-bottom: 20px;
`;

export const btnWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
