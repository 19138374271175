import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout2,
  V1PurchaseBtn,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202001 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202001Query>(graphql`
    query Items_202001 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "lamp-dress",
      title: {
        main: "lamp dress",
        sub: "ON と OFF",
      },
      desc: `包み込んで
      優しい灯りを部屋中に
      寝る前にオンにして
      起きたらオフにする
      私の気持ちとは真逆ね
      おやすみなさい
      おはよう
      オンとオフ
      
      ドレスに合わせたって
      デニムに合わせたって
      今日の私は ”特別”
      `,
      color: {
        en: "beige",
        ja: "ベージュ",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op01_nor",
      story: `ランプシェードをイメージした
      大きな襟が特徴の
      ブラウス・ワンピース。
      ランプのようにストンとした
      筒型のシルエットにしつつも
      浅いスリット入りで
      動きやすさは損なわれません。
      
      少し珍しい
      袖の小さな金の四角ボタンや
      リボンの先の金具は
      スイッチのボタンやヒモを
      イメージしています。
      
      「オン」と「オフ」
      の二面性を楽しめるように
      前後どちらでも着ることのできる
      2WAY 仕様。
      コットンなので1枚でも、
      重ね着でも。
      1年中お楽しみ下さい。
      madder madder の
      WRAP DRESS や curtain dressとも
      相性抜群です。
      `,
    },
    {
      id: "certain-dress",
      title: {
        main: "curtain dress",
        sub: "ギャザーを寄せて",
      },
      desc: `今日の天気はなんだろう
      雨かな曇りかな晴れかな
      少しだけ光が漏れて見える
      ああ、きっと晴れだ
      
      カーテンをあけて
      全身で今日を感じる
      誰も知らない私も知らない
      今日が始まる
      
      新しい私
      毎日、新しい私
      `,
      color: {
        en: "beige",
        ja: "ベージュ",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op02_nor",
      story: `カーテンをイメージした
      ギャザーをお好きなだけ寄せれる
      贅沢な生地使用量のドレス。
      ボリュームや美しいシルエットが
      より際立つよう、
      ハリのある素材を使用し
      まるで本当のカーテンのような佇まい
      
      陽の光が欲しくて
      たっぷりと寄せたギャザーも
      夜帰宅後に閉めて
      すこしだけ寄った
      可愛らしいギャザーも
      カーテンのように
      自由自在にどうぞ
      
      少し厚手ですので真夏以外、
      9月～6月頃まで
      ほぼ1年中お楽しみいただけます。
      madder madder の
      lamp dress や RIBBON BLOUSE、
      JABOT BLOUSE とも相性抜群です。
      `,
    },
  ];
  const title = {
    main: "THE BRAND NEW...",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="the-brand-new"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-1/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-1/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202001;
