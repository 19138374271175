import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ImgAndColor,
  V1ItemLeftLayout,
  V1ItemRightLayout4,
  V1PurchaseBtn,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_201911 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_201911Query>(graphql`
    query Items_201911 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "skip-coat",
      title: {
        main: "SKIP COAT",
        sub: "5 to 11",
      },
      desc: `朝の5時から
      夜の11時まで
      別にそれ以外の時間でもいいけれど
      一人だと少し怖いから
      散歩の時間はこの間で
      
      ポケットに詰める葉っぱたち
      ひらひらと気づけば
      コートの襟にもついていた
      枯葉の絨毯を
      レッドカーペットに見立てて
      くるくると踊りたくなるコート
      踊るのが恥ずかしければ
      控えめなスキップでも
      
      昼は日差しが当たってあたたかい
      日が落ちて
      凍えるような寒さになってきても
      大丈夫
      首をふんわりと包んでくれる
      大きな大きな襟
      マフラーを忘れても平気
      外にずっとでていられる
      `,
      color: [
        {
          en: "WINE - 6PM -",
          ja: "ボルドー",
        },
        {
          en: "PARK - 11AM -",
          ja: "ダークブラウン",
        },
        {
          en: "DARK NIGHT - 11PM -",
          ja: "ブラック",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92co01_nor_bo",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92co01_nor_br",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92co01_nor_bk",
      ],
      story: `昨年も人気だった再生ウールを使用したコート。
      たっぷりと生地を贅沢に使って
      他にはないシルエットを
      堪能できます。
      袋状になった大きな襟は
      リボンでぎゅっと絞り
      膨らますようにかたちを整えれば
      首元の防寒にもぴったりな
      スタイリングのアクセントにも。
      軽くてあたたかい、散歩にぴったりなコートです。
      `,
    },
    {
      id: "salopette-pants",
      title: {
        main: "SALOPETTE PANTS",
        sub: "散歩に出かける",
      },
      desc: `涼しさを与えてくれた緑の葉っぱたちが
      おめかしをはじめ色付きだし
      やがて ふかふかのじゅうたんに変わります
      
      燃えるような真っ赤なもみじ
      見るだけで優しい気持ちになれる
      いちょうの柔らかいイエロー
      靴の裏でコロコロとしたどんぐり
      なんだかくすぐったいな
      
      美しい景色が
      肌をなでる少しひんやりとした空気が
      主役は私ではない、
      彼らの方なのだと
      感じさせてくれる
      
      彼らの邪魔をしないように
      でもじっくりと、見たいから
      ひっそりと控えめの色でお散歩する
      
      子供の頃
      たくさん歩く日はスカートではなく
      サロペットだった
      これさえ履けばどこまででも
      散歩できる気がした
      
      日が暮れ出して
      指先がかじかんできたら
      ポケットに手を突っ込んでしまえばいい
      今日は疲れてもタクシーには乗らない
      寒かった、と言いながらドアを開け
      ちょうど湧いたところの
      あったかいお湯に浸かる
      `,
      color: [
        {
          en: "THE BENCHES",
          ja: "ブラック",
        },
        {
          en: "THE STONES",
          ja: "グレー",
        },
        {
          en: "THE WOODS",
          ja: "ブラウン",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92pt01_nor_bk",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92pt01_nor_gr",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92pt01_nor_ca",
      ],
      story: `ブラウスでも、ニットでも、
      フーディーでも、カットソーでも。
      何でも合わせやすいのは
      ストンとシンプルなかたちながらも
      座った姿も立った姿もサマになるような、きれいなラインをつくるパターンのおかげ。
      肩紐やウエスト部分は
      ボタンが2個付いているので
      サイジングの微調整可能です。
      起毛素材で着心地が良く、
      毎日でも着たくなるサロペットは
      カジュアルに着るか
      すこしきれいめに着るか
      全てはあなたの
      その日の気分次第です。
      `,
    },
  ];
  const title = {
    main: "OSANPO",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="osanpo"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout4>
            <>
              <V1ImgAndColor>
                <Anchor to={items[0].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-11/items/01.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[0].en}</Txt>
                  <Txt size="l">（{items[0].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-11/items/02.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[1].en}</Txt>
                  <Txt size="l">（{items[0].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[0].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-11/items/03.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[0].color[2].en}</Txt>
                  <Txt size="l">（{items[0].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1PurchaseBtn link={items[0].link[0]} />
          </V1ItemRightLayout4>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout4>
            <>
              <V1ImgAndColor>
                <Anchor to={items[1].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-11/items/04.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[0].en}</Txt>
                  <Txt size="l">（{items[1].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-11/items/05.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[1].en}</Txt>
                  <Txt size="l">（{items[1].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[1].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2019-11/items/06.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[1].color[2].en}</Txt>
                  <Txt size="l">（{items[1].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1PurchaseBtn link={items[1].link[0]} />
          </V1ItemRightLayout4>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_201911;
