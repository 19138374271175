import { css } from "linaria";
import { animation, border, colors, mediaQuery, shadow } from "@/utils/styles";

export const years = css`
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 70px;
  left: 0;
  width: 200px;
  background-color: ${colors.bg};
  box-shadow: ${shadow.basic};
  padding: 20px;

  ${mediaQuery.max} {
    width: 50%;
  }
`;

export const date = css`
  padding: 10px;
  border-bottom: ${border.basic};
  transition: ${animation.hover.basic};
  cursor: pointer;
  &:hover,
  .current {
    background-color: ${colors.link.hover};
  }
  &:visited {
    border-bottom: ${border.basic};
  }
`;

export const months = css`
  position: absolute;
  top: 0;
  left: calc(50% - -70px);
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  width: 200px;
  background-color: ${colors.bg};
  box-shadow: ${shadow.basic};
  padding: 20px;
  ${mediaQuery.max} {
    width: 100%;
  }
`;

export const yearsList = css`
  &:hover > ul {
    display: flex;
  }
`;

export const monthsList = css`
  width: 100%;
`;

export const link = css`
  color: ${colors.base};
`;
