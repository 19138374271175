import { css } from "linaria";
import { colors, mediaQuery, shadow } from "@/utils/styles";

export const root = css`
  display: flex;
  flex-direction: row;
  width: 70%;
  background-color: ${colors.itemBg};
  padding: 30px 0;
  margin-bottom: 30px;
  box-shadow: ${shadow.basic};
  ${mediaQuery.max} {
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
  }
`;

export const left = css`
  width: 50%;
  ${mediaQuery.max} {
    width: 100%;
  }
`;

export const right = css`
  width: 50%;
  ${mediaQuery.max} {
    width: 100%;
  }
`;
