import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202102 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202102Query>(graphql`
    query Items_202102 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "shelter-blouse",
      title: {
        main: "shelter blouse",
      },
      desc: `- 【ハコブネ】は現在、110分待ちです。 -
      
      MM WONDERLANDでも
      大人気のアトラクション、
      【ハコブネ】。
      とっても楽しそうだけれど、
      水しぶきが盛大に
      スプラッシュしてしまうみたい。
      せっかく今日は、メイクもヘアも
      うまくいったのに。
      キュートなお顔もヘアもばっちり守って
      いざ、出陣！
      `,
      color: {
        en: "off cream",
        ja: "オフクリーム",
      },
      recommend: ["candy sleeve anorak", "Lace line pants", "TICKET PANTS"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211bl01_nor1",
    },
    {
      id: "cummerbag-dress",
      title: {
        main: "cummerbag dress",
      },
      desc: `- 【アップル・パニック】は現在、
      66分待ちです。 -
      
      MM WONDERLANDで
      大人気のアトラクション、
      【アップル・パニック】。
      なんだかワクワクするような、
      でもちょっぴり
      「パニック」という言葉が
      こわいような。
      どうやら手荷物はしっかり、
      体に密着させたほうがいいみたい。
      一体何が起こるんだ！？
      カマーバッグにワクワクを込めて、
      いざ、出陣！
      `,
      color: {
        en: "pink × cream",
        ja: "ピンク × クリーム",
      },
      recommend: ["reversible coat", "Lace line jacket coat", "GOWN JACKET"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op02_nor",
    },
    {
      id: "rara-tulle-5-skirt",
      title: {
        main: "“RARA” tulle 5 skirt",
      },
      desc: `- mm clan（マダマダ族） -
      
      いつかの時代、とある世界の、ちょっとふしぎな国。おいしいものと、おめかしが大好きな一族がいました。彼女たちの生活を、少し覗いてみましょう。今回は、楽しいことで頭がいっぱい！MM WONDERLAND の年間パスポートも持っている、おてんばな RARA（ララ）さんにインタビュー。
      
      民俗学者：（以下：民）こんにちは！とても素敵だったので、すみません、声をかけてしまいました。もしかして、マダマダ族の方ですか？
      
      RARA（ララ）さん：（以下：ラ）えっ！よくわかりましたね、そうです。私、マダマダ族。ララって言います。
      
      民：やっぱり！チェック柄を纏ってないから、もしかしたら違うかな、って思ったんですが。良かったです、間違ってなかった。
      
      ラ：ああ、チェック。あれは、まあ、民族衣装というか。各家庭の家紋みたいなものなので・・可愛いから皆よく着ますけど、常に来ているわけではないですよ。今日は完全に普段着。だってここは MM WONDERLAND ですからね。
      
      民：あ、そういうことですかー。チェックは一応、民族衣装というカテゴリーな訳ですね。普段着・・この、なんだかとにかくハッピーそうなスカート、結構スペシャルな感じに見えますけど、普段着なんですね？
      
      ラ：はい。紛れもなく普段着です。マダマダ族はね、おめかしが大好きな民族ですよ。知らないんですか?そりゃ、おめかしが大好きなんだから、普段からチュールたっぷりのスカート、着るでしょう。マダマダ族とは、そういうものよ。
      
      民：知ってました・・いやあ、こういうところには、もしかして歩きにくいんじゃないかなあ、って思ったり思わなかったりして・・
      
      ラ：歩きにくい、歩きにくくない、なんて次元でものを考えていないの。「ドコドコで着ていて素敵なのは、コノフク！」。それだけよ。このスカート、今並んでるアトラクションの【ニューワールドオーダー】にとーっても似合いそうでしょ！
      
      民：（なんだかすごく早口だ。怒っているのかもしれない。）あれ、怒ってます・・？
      
      ラ：怒ってないですよ。今の列、180分待ちだから、少しご機嫌斜めかもしれないですけど。あ、列進んでるので。ではまた！時間あったらこれ、乗ってみてくださいね！とても楽しいんで！
      
      民：はい、お忙しい中ありがとうございました！（ひるんでしまって普段着について深く質問ができなかった・・あとでまた会えればいいな・・）
      `,
      color: {
        en: "beige pink",
        ja: "ベージュピンク",
      },
      recommend: [
        "sailor collar blouse",
        "citrus tunic",
        "candy sleeve anorak",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211sk01_nor",
    },
  ];
  const story = `【MM WONDERLAND】へ遊びに行こう！
  そこは少しふしぎなワンダーランド。
  かわいいものとかっこいいもの、
  そしておいしいものの
  たくさん詰まった桃源郷のような施設です。
  
  はじまりがあれば、
  必ずやってくる、終わりの時間。
  今日の思い出を胸に
  さあ、明日からの現実世界も生き抜いて。
  `;
  const title = {
    main: "MM WONDERLAND",
    sub: "第2部・MM ワンダーランドで大はしゃぎ！編",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="mm-wonderland-2"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-2/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-2/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-2/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202102;
