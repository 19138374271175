import React, { VFC } from "react";
import * as styles from "./styles";

type ItemLayoutProps = {
  children: JSX.Element[];
  id: string;
  className?: string;
};

const ItemLayout: VFC<ItemLayoutProps> = ({ children, id, ...props }) => {
  return (
    <article id={id} className={styles.root} {...props}>
      <div className={styles.left}>{children[0]}</div>
      <div className={styles.right}>{children[1]}</div>
    </article>
  );
};

export default ItemLayout;
