import { css } from "linaria";
import { animation, fonts } from "@/utils/styles";

export const normal = css``;

export const vertical = css`
  writing-mode: vertical-rl;
`;

export const xxs = css`
  font-size: ${fonts.size.xxs};
`;

export const xs = css`
  font-size: ${fonts.size.xs};
`;

export const s = css`
  font-size: ${fonts.size.s};
`;

export const m = css`
  font-size: ${fonts.size.m};
`;

export const l = css`
  font-size: ${fonts.size.l};
`;

export const xl = css`
  font-size: ${fonts.size.xl};
`;

export const xxl = css`
  font-size: ${fonts.size.xxl};
`;

export const xxxl = css`
  font-size: ${fonts.size.xxxl};
`;

export const xxxxl = css`
  font-size: ${fonts.size.xxxxl};
`;

export const center = css`
  text-align: center;
`;

export const wrapping = css`
  white-space: pre-line;
`;

export const bold = css`
  font-weight: ${fonts.weight.bold};
`;

export const hover = css`
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;
