import { css } from "linaria";
import { border, mediaQuery } from "@/utils/styles";

export const root = css`
  width: 70%;
  padding: 20px;
  margin-bottom: 40px;
  ${mediaQuery.max} {
    width: 100%;
  }
`;

export const head = css`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  & > h3 {
    display: inline-block;
    padding: 0 15px 8px;
    border-bottom: ${border.width} dashed ${border.itemColor};
  }
`;

export const body = css`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  & > p {
    line-height: 3;
  }
`;

export const btn = css`
  max-width: 300px;
  margin: 0 auto;
`;
