import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ImgAndColor,
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1ItemRightLayout2,
  V1ItemRightLayout3,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202003 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202003Query>(graphql`
    query Items_202003 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "tulle-long-dress",
      title: {
        main: "TULLE LONG DRESS",
        sub: "かざる",
      },
      desc: `はじめて憧れのシューズを買った時
      箱を開けたらチュールが出てきて
      息が止まるかと思った
      
      チュールにやさしく包まれたシューズは
      それはそれは神々しくて
      宝物を見つけた時の勇者って
      こんな気持ちだったのかしら？
      
      私たちの夢が詰まっている
      繊細で、でも実は強い
      チュールに包まれたなら
      `,
      color: {
        en: "pink",
        ja: "ピンク",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm92op02_nor_pk",
      story: `袖を通すと
      優しさに包まれたなら
      って思わず口から出る
      そんなチュールのドレス。
      “特別な日” を思い切り楽しむためにも
      ”なんでもない日” を特別な気持ちで過ごすためにも
      なんてぴったりなのでしょう
      
      少し硬めのチュールをたっぷり使い
      （本当に、たっぷりと！）
      なかなか無い贅沢なシルエット
      
      スタイルアップしやすい
      少し上気味のウエスト切り替えに
      ノースリーブですので
      そのまま1枚でも、
      上に1枚重ねても、
      中に1枚きてみても。
      お好きなように
      コーディネートの幅は無限大です。
      `,
    },
    {
      id: "big-collar-blouse",
      title: {
        main: "big collar blouse",
      },
      desc: `バスタイム後の頬
      牛乳9：苺1のいちごミルク
      海岸で拾った桜貝の一番薄い部分
      親戚の赤ちゃんのスタイ
      あの子のすっぴんの唇の色
      
      ベビーピンクから連想する
      自然体で
      愛らしくて
      でも時々ちょっと憎らしいかも
      
      心地のいいガーゼと
      ころころ整列したボタン
      ほつれてきたら
      もっと味が出るのか、にくいね
      `,
      color: {
        en: "baby pink",
        ja: "ベビーピンク",
      },
      recommend: ["SMOCK DRESS", "salopette pants"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201bl02_nor_pk",
      story: `アンティークのカーディガンを
      マダマダらしくアップデート
      愛らしい襟とリボン、
      いつものくるみボタンに
      かっこよく
      たっぷりのギャザー、
      切りっぱなしの仕上げ
      なんだか懐かしいような、
      でも新しいような
      不思議な気持ちになるカーディガン
      
      ガーゼは一枚一枚の生地がとても薄く
      空気を纏っているような軽やかさ。
      速乾性に優れているので
      気軽にお洗濯できるのも嬉しい
      ピンク祭りでなくたって、
      毎日でも着たくなる
      `,
    },
    {
      id: "dress",
      title: {
        main: "182 dress",
      },
      desc: `お気に入りのチーク
      おばあちゃんのパジャマ
      フランス土産のパッケージ
      おしゃれなあの人の家の壁紙
      
      毎日でも着たくなる
      1年365日のうち
      お洗濯して乾かす
      お洋服の”おやすみ”の日をのぞいた
      1年の半分くらい「182日」
      着てしまいそうな着たくなりそうな
      そんな合わせやすくて
      困った時の救世主。
      `,
      color: {
        en: "cheek pink",
        ja: "チークピンク",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op04_nor_pk",
      story: `シンプルなワンピースを
      マダマダらしいガーゼ素材とピンクで。
      実は前後 2way で
      クルーネックとVネック
      お好きな気分で変えられます。
      ”1年の半分くらい着たくなる” なら、
      首元の開きは重要ポイント。
      重ね着もより一層楽しめます。
      
      ガーゼは一枚一枚の生地がとても薄く
      空気を纏っているような軽やかさ。
      袖も楽々折れるので
      季節によって長さを変えて
      ピンク祭りでなくたって、
      毎日でも着てくださいね。
      
      madder madder の
      Organza シリーズの中に着たり、
      同素材の big collar blouse と合わせたり
      オススメのスタイリングです。
      `,
    },
    {
      id: "long-skirt",
      title: {
        main: "LONG SKIRT",
        sub: "視界に入るのはお茶と・・",
      },
      desc: `お茶を飲む。
      お菓子をいただく。
      手元を見るたびに 視界にうつる
      今日、私の身につけているもの。
      
      デニムでもいいけれど
      いつものワンピースでもいいけれど
      なんだか気持ちの上がる、
      お茶とお菓子の仲間に入れるような・・
      そんなロマンティックな服が着たい。
      まるで特別の日のための
      デコレーションケーキのような、
      だんだんウェーブの
      クリームのようなスカート。
      
      立ち姿も歩く姿も
      空気をはらんで素敵だけれど
      お茶をして座っている間は
      私にしか見えない、
      なんて贅沢な時間なんだろう
      なんて贅沢な服なんだろう
      `,
      color: {
        en: "peach’s foot prints",
        ja: "ピンク",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk05_nor_pk",
    },
  ];
  const title = {
    main: "わたしたちのピンク",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="watashitachino-pink"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-3/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-3/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-3/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[3].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <V1ImgAndColor>
              <Anchor to={items[3].link} isExternal>
                <StaticImage
                  src="../../assets/images/2020-3/items/04.jpg"
                  alt="Item image"
                />
              </Anchor>
              <>
                <Txt size="l">{items[3].color.en}</Txt>
                <Txt size="l">（{items[3].color.ja}）</Txt>
              </>
            </V1ImgAndColor>
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202003;
