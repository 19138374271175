import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ImgAndColor,
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1ItemRightLayout3,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202009 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202009Query>(graphql`
    query Items_202009 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "skirt",
      title: {
        main: "182 skirt",
      },
      desc: `「私の白」は
      いつも同じ色
      だけれど
      時々違う色
      に見える
      
      どこに行っても
      誰に会っても
      私は私のままだけれど
      誰かの影もまとっている
      白だけれど
      白じゃないかもしれない
      
      少し透かして見える
      「私の白」
      
      毎日でも着たくなる
      1年365日のうち
      お洗濯して乾かす
      お洋服の”おやすみ”の日をのぞいた
      1年の半分くらい「182日」
      着てしまいそうな
      着たくなりそうな
      そんな合わせやすくて
      困った時の救世主。
      `,
      color: [
        {
          en: "my white",
          ja: "マイホワイト",
        },
      ],
      recommend: ["fold up dress", "lace line pants", "envelope dress"],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202sk01_nor",
      ],
      story: `“182シリーズ”
      ＝1年（365日）のうち、
      半分（182日）くらい着たいような
      着心地も使い勝手もいい、
      がテーマのシリーズ。
      
      ワンピースに続く第二弾は、
      コットンのガーゼでできた
      ホワイト・タックスカートです。
      
      まるで空気を纏っているような軽やかさ。
      `,
    },
    {
      id: "alice-apron-gilet",
      title: {
        main: "“ALICE” apron gilet",
      },
      desc: `「でももうお茶の時間よ、
      急がないと、遅れそう」
      
      ふしぎの国の夢ものがたりを
      機関銃のように話し続ける私に
      優しい姉がころあいを見て
      ストップをかける
      
      話しすぎて喉がカラカラになってしまった
      アイスティーを一気飲みしたい
      今日のお茶会のホストは
      そういえば私だったっけ
      
      「め茶く茶なお茶会」
      を経験した私なら
      きっととんでもなく素敵な会が開けるわ
      まずはあれと、あれと、
      あれも用意して・・・
      
      エプロンを掴み
      全速力で家に向かって走る
      姉が何か話しかけてきた気がするけれど
      風の音で何も届かない
      お茶の時間に聞けばいいや
      `,
      color: [
        {
          en: "white",
          ja: "ホワイト",
        },
      ],
      recommend: ["my black dress", "mint T-blouse", "lace line pants"],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202op05_nor",
      ],
      story: `マダマダらしい、
      「必ずしも必要ではないかもしれないけれど、
      あったら更に、とびっきりお洋服を着るのが楽しくなる」
      まるで魔法のスパイスのようなアイテムです。
      
      古い写真で見た
      修道院の人が着ているスカプラリオを
      イメージソースに
      「お茶会の準備をするためのエプロン」を考えました。
      
      帽子屋さんや双子に憧れて
      大きな襟にリボンを合わせるのもいいかもしれません。
      
      お茶会中に何か失礼なことをされたら
      小さな「シュガーポケット」から
      角砂糖を取り出して投げつけてやりましょう。
      
      自由なお茶会の準備の、
      はじまりはじまり。
      `,
    },
    {
      id: "two-way-dress",
      title: {
        main: "2 WAY DRESS",
        sub: "準備をはじめる",
      },
      desc: `お茶の時間をはじめましょう
      お茶をブレンドしたり
      お湯を沸かしたり
      ちょっとつまむための
      お菓子を焼いたりなんかも
      そんな大切な準備の時間
      まとうのはエプロンでもいいかもしれませんが
      準備の時間用のワンピースなんてあったら
      とっても素敵。
      
      Vintage のメディカルコートのように
      前後ろどちらも主役になれる 2 way 仕様なので
      もしも準備中に来客があっても大丈夫。
      パティシエさんのようなボタンがついた
      胸元が印象的な「作業着風」から
      小さなボタンのたくさんついた
      「お出迎えできる」ロングドレスに変身！
      なんてスマートなのでしょう。
      
      作業の邪魔にならないようにお袖は少し短め、
      ポワンとしたボリュームもとっても
      「ちょうどいい」。
      コットンなので生クリームや
      チョコがついても大丈夫！
      お家で洗ってくださいね。
      `,
      color: [
        {
          en: "whipped cream",
          ja: "ホワイト",
        },
        {
          en: "nutty brownie",
          ja: "ブラウン",
        },
        {
          en: "dark cherry",
          ja: "ボルドー",
        },
        {
          en: "kurogoma latte",
          ja: "チャコールブラック",
        },
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_wh",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_br",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_bo",
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm91op01_nor_ch",
      ],
    },
    {
      id: "lorina-dress",
      title: {
        main: "“Lorina” dress",
      },
      desc: `優しくて勤勉なお姉ちゃんは
      いつだって本を読んでいる
      妹の心配もしている
      毎日のお茶の準備だって
      
      なかなか甘えることができない
      お茶にだって本当はお砂糖を入れたい
      角砂糖だったら3個くらい
      コーヒーだってミルクたっぷり入れたいの
      
      でも本当に飲みたいのは
      飲みたいのは・・
      しびれるような甘さの
      ホットチョコレート
      「あまーい」って笑いながら
      全部飲み干してしまいたい
      `,
      color: [
        {
          en: "hot chocolate",
          ja: "ホットチョコレート",
        },
      ],
      recommend: [
        "“ALICE” apron gilet",
        "big collar blouse",
        "sailor collar bolero",
      ],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202op03_nor",
      ],
      story: `そんなにお天気ではなさそうなのに
      かたくなに帽子をはずさず、本を読んでいる・・
      そんなアリスのお姉さんがかっこよくて、なんだかとっても気になる。
      「アリス」の実在するモデルの姉が、「ロリーナ」という名前だったので、ドレスのお名前に拝借しました。
      `,
    },
    {
      id: "liddell-dress",
      title: {
        main: "“Liddell” dress",
      },
      desc: `かわいいから、別にいいのだけれど
      どうして水色になってしまったんだろう
      黄色も、特に赤もとっても素敵だったのに・・・
      
      きっとハートの女王さまが
      真っ赤なドレスが似合いすぎているアリスに
      嫉妬をしてしまうからかしら
      
      それならとびっきり、とびっきり
      可愛くてかっこいい真っ赤なドレスを
      あなたのために
      嫉妬なんて小さな感情吹き飛ばしてしまうような
      圧倒的に美しい真っ赤なドレスを・・・
      `,
      color: [
        {
          en: "originally red",
          ja: "オリジナリー・レッド",
        },
      ],
      recommend: ["“ALICE” apron gilet", "lace line pants", "RIBBON BLOUSE"],
      link: [
        "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202op04_r_nor1",
      ],
      story: `「不思議の国のアリス」の主人公、「アリス」の着ているドレス。
      今では水色が当たり前ですが、実は「真っ赤なドレス」を着ていた期間がほんの少しありました。
      そんな幻の赤いドレスに想いを馳せて・・
      ルイスキャロルの原作版アリスのモデルになった「アリス・リデル」をイメージしています。
      `,
    },
  ];
  const title = {
    main: "TEA PARTY",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="madder-madder-clan"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link[0]} isExternal>
              <StaticImage
                src="../../assets/images/2020-9/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color[0].en}</Txt>
              <Txt size="l">（{items[0].color[0].ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link[0]} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link[0]} isExternal>
              <StaticImage
                src="../../assets/images/2020-9/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color[0].en}</Txt>
              <Txt size="l">（{items[1].color[0].ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link[0]} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout3>
            <>
              <V1ImgAndColor>
                <Anchor to={items[2].link[0]} isExternal>
                  <StaticImage
                    src="../../assets/images/2020-9/items/03.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[0].en}</Txt>
                  <Txt size="l">（{items[2].color[0].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[1]} isExternal>
                  <StaticImage
                    src="../../assets/images/2020-9/items/04.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[1].en}</Txt>
                  <Txt size="l">（{items[2].color[1].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[2]} isExternal>
                  <StaticImage
                    src="../../assets/images/2020-9/items/05.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[2].en}</Txt>
                  <Txt size="l">（{items[2].color[2].ja}）</Txt>
                </>
              </V1ImgAndColor>
              <V1ImgAndColor>
                <Anchor to={items[2].link[3]} isExternal>
                  <StaticImage
                    src="../../assets/images/2020-9/items/06.jpg"
                    alt="Item image"
                  />
                </Anchor>
                <>
                  <Txt size="l">{items[2].color[3].en}</Txt>
                  <Txt size="l">（{items[2].color[3].ja}）</Txt>
                </>
              </V1ImgAndColor>
            </>
            <V1PurchaseBtn link={items[2].link[0]} />
          </V1ItemRightLayout3>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link[0]} isExternal>
              <StaticImage
                src="../../assets/images/2020-9/items/07.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color[0].en}</Txt>
              <Txt size="l">（{items[3].color[0].ja}）</Txt>
            </>
            <Txt isWrapping>{items[3].story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link[0]} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[4].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[4].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[4].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[4].link[0]} isExternal>
              <StaticImage
                src="../../assets/images/2020-9/items/08.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[4].color[0].en}</Txt>
              <Txt size="l">（{items[4].color[0].ja}）</Txt>
            </>
            <Txt isWrapping>{items[4].story}</Txt>
            <V1Recommend recommend={items[4].recommend} />
            <V1PurchaseBtn link={items[4].link[0]} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202009;
