import React, { VFC } from "react";
import { Txt } from "@/components/atoms";
import * as styles from "./styles";

type RecommendProps = {
  recommend: string[];
  className?: string;
};

const Recommend: VFC<RecommendProps> = ({ recommend, ...props }) => {
  return (
    <ul className={styles.root} {...props}>
      {recommend.map((r) => (
        <li className={styles.list} key={r}>
          <Txt>{r}</Txt>
        </li>
      ))}
    </ul>
  );
};

export default Recommend;
