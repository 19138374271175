import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1TocLayout,
  V1TocChildLayout,
  V1Appendix2,
  V1Appendix,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { css } from "linaria";
import { animation } from "@/utils/styles";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202106 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202106Query>(graphql`
    query Index_202106 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 550 : 830,
      paddingTop: windowWidth <= 767 ? 170 : 250,
      txtSize: windowWidth <= 767 ? "l" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "June 2021";
  const nextPreview = "◆次巻予告・「うちの　あの子が　百着夜行」〇七〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#fff8de",
    itemColor: "#fff8de",
  };
  const bgColor = "#8fa188";
  const tocContents = [
    {
      to: "/2021-6/items#tane-many-many-seeds-blouse",
      num: "一",
      item: "メニメニ・シーズ・ブラウス",
    },
    {
      to: "/2021-6/items#twins-layered-bicolor-dress",
      num: "八",
      item: "ツインズ・バイカラー・ドレス",
    },
    {
      to: "/2021-6/items#",
      num: "十五",
      item: "ロング・スカート",
    },
    {
      to: "/2021-6/items#rose-check-big-pocket-blouse",
      num: "二十二",
      item: "ビッグ・ポケット・チェック・ブラウス",
    },
    {
      to: "/2021-6/items#rose-funny-pocket-check-skirt",
      num: "二十九",
      item: "ビッグ・ポケット・チェック・スカート",
    },
  ];
  const appendix = [
    "パンプキンパイのためのアノラック",
    "フローリストのためのアノラック",
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="June-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-6/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) =>
              t.num === "十五" ? (
                <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1Appendix>
              ) : (
                <Anchor key={t.item} to={t.to} style={{ flex: 1 }}>
                  <V1Appendix {...tocProps}>
                    <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                  </V1Appendix>
                </Anchor>
              )
            )}
            <V1Appendix2 txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>特別付録</VerticalTxt>
              {appendix.map((a, idx) =>
                idx === 1 ? (
                  <VerticalTxt className={hov} {...txtProps}>
                    {a}
                  </VerticalTxt>
                ) : (
                  <VerticalTxt key={a} {...txtProps}>
                    {a}
                  </VerticalTxt>
                )
              )}
            </V1Appendix2>
          </>
          <Anchor to="/2021-7#July-2021" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

const hov = css`
  transition: ${animation.hover.basic};
  &:hover {
    opacity: ${animation.opacity};
  }
`;

export default Index_202106;
