import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1Appendix,
  V1TocLayout,
  V1TocChildLayout,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202110 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202110Query>(graphql`
    query Index_202110 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 150 : 250,
      txtSize: windowWidth <= 767 ? "l" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "October 2021";
  const nextPreview = "◆次巻予告・「おちばひろい」一一〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#ffecce",
    itemColor: "#221914",
  };
  const bgColor = "#2e6045";
  const tocContents = [
    {
      to: "/2021-10/items#emily-cocoon-long-coat-21aw",
      num: "五",
      title: "長女　エミリーさん",
      item: "コクーン・ロング・コート",
    },
    {
      to: "/2021-10/items#himawari-big-sailor-collar-bolero-21aw",
      num: "十二",
      title: "次女　ひまわり",
      item: "ビッグ・セーラーカラー・ボレロ",
    },
    {
      to: "/2021-10/items#luna-tulle-layered-dress-21aw",
      num: "十九",
      title: "三女　ルナさま",
      item: "チュール・レイヤード・ドレス",
    },
    {
      to: "/2021-10/items#milky-wrapping-tunic-blouse-21aw",
      num: "二十六",
      title: "四女　ミルキーちゃん",
      item: "チュニック・ブラウス",
    },
  ];
  const appendix = "１８２シャツドレス（一〇一二）";
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="October-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-10/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <V1TocChildLayout
                {...tocProps}
                paddingTop={responsive.paddingTop}
                style={{ flex: 1 }}
                to={t.to}
                key={t.title}
              >
                <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
              </V1TocChildLayout>
            ))}
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>特別付録</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix}</VerticalTxt>
            </V1Appendix>
          </>
          <Anchor
            to="/2021-11#November-2021"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202110;
