import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1TocLayout, V1TocChildLayout } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { css } from "linaria";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202102 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202102Query>(graphql`
    query Index_202102 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 600 : 910,
      paddingTop: windowWidth <= 767 ? 170 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "February 2021";
  const nextPreview =
    "◆次巻予告・「さようなら。また来る、その日まで。」〇三〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#655653",
    itemColor: "#655653",
  };
  const bgColor = "#e7dadb";
  const tocContents = [
    {
      to: "/2021-2/items#shelter-blouse",
      num: "二",
      title: "あなたのキュートなお顔を守ります",
      item: "シェルター・ブラウス",
    },
    {
      to: "/2021-2/items#cummerbag-dress",
      num: "九",
      title: "手ぶらではしゃぐ、私たち",
      item: "カマーバッグ・ドレス",
    },
    {
      to: "/2021-2/items#rara-tulle-5-skirt",
      num: "十六",
      title: "マダマダ族は、遊園地に何を着ていく？",
      item: "ララ・チュール・５・スカート",
    },
    {
      to: "/2021-2/items#",
      num: "",
      title: "スプラッシュしても安心よ。",
      item: "キャンディスリーブ・アノラック",
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="February-2021"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2021-2/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) =>
              t.num === "" ? (
                <V1TocChildLayout
                  key={t.title}
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                  style={{ flex: 1 }}
                  className={noHov}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              ) : (
                <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                  <V1TocChildLayout
                    {...tocProps}
                    paddingTop={responsive.paddingTop}
                  >
                    <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                    <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                  </V1TocChildLayout>
                </Anchor>
              )
            )}
          </>
          <Anchor to="/2021-3#March-2021" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

const noHov = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1;
  transition: none;
  &:hover {
    opacity: 1;
  }
`;

export default Index_202102;
