import { css } from "linaria";
import { border, mediaQuery } from "@/utils/styles";

export const root = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 70px;
  padding: 0 25px;
  border-top: ${border.basic};
  border-bottom: ${border.basic};
`;

export const iconWrapper = css`
  display: flex;
  height: 100%;
`;

export const icon = css`
  ${mediaQuery.min} {
    display: none;
  }
`;
