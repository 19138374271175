import React, { VFC } from "react";
import * as styles from "./styles";

type StickyHeaderLayoutProps = {
  children: JSX.Element[];
  className?: string;
  style?: { zIndex: number };
};

const StickyHeaderLayout: VFC<StickyHeaderLayoutProps> = ({
  children,
  ...props
}) => (
  <div {...props}>
    <div className={styles.sticky}>{children[0]}</div>
    <div className={styles.body}>{children[1]}</div>
  </div>
);

export default StickyHeaderLayout;
