import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

type SeoProps = {
  description?: string;
  lang?: string;
  meta?: Array<
    | { property: string; content: string }
    | { name: string; content: never }
    | { name: string; content: string }
  >;
  title?: string;
};

const Seo: FC<SeoProps> = ({
  // description = "",
  lang = "ja",
  meta = [],
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            designer
            siteUrl
          }
        }
      }
    `
  );

  const { pathname } = useLocation();

  const seo = {
    title: title ? `${title} | madder madder` : site.siteMetadata.title,
    // description: description || site.siteMetadata.description, // FIXME: OGP 説明文を用意できるまでは
    description: site.siteMetadata.description,
    siteName: site.siteMetadata.title,
    url: `${site.siteMetadata.siteUrl}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix: "og: https://ogp.me/ns# website: https://ogp.me/ns/website#",
      }}
      title={seo.title}
      meta={[
        {
          name: "description",
          content: seo.description,
        },
        {
          name: "theme-color",
          content: "#fff",
        },
        {
          property: "og:site_name",
          content: seo.siteName,
        },
        {
          property: "og:locale",
          content: "ja_JP",
        },
        {
          property: "og:url",
          content: seo.url,
        },
        {
          property: "og:title",
          content: seo.title,
        },
        {
          property: "og:description",
          content: seo.description,
        },
        {
          property: "og:type",
          content: pathname === "/" ? "website" : "article",
        },
        {
          property: "og:image",
          content: `https://drive.google.com/uc?export=view&id=1bvs2mpD5OvyjAXX6udVbePLgALBZvYPm`, // fixme
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default Seo;
