import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202101 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202101Query>(graphql`
    query Items_202101 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "dera-check-big-long-coat",
      title: {
        main: "“DERA” CHECK BIG LONG COAT",
      },
      desc: `3連休明け、少し憂鬱な火曜日の朝、7時30分。
      よくあるコーヒーのチェーン店の窓際の席から。
      いつものように、
      【湧湧測定機能付き拡張現実眼鏡】を使い、
      オフィス街として有名な
      この地下鉄駅から出てきた人たちを調査対象に、
      湧湧（ワクワク）を遠隔測定する。
      妙に惹かれたチェックのコートを着た女性。
      他の調査対象者と同様に、週明けの朝らしく、
      湧湧度は15％だった。
      彼女はコツコツコツコツ、
      と早足にこちらに向かって歩いてきて、
      店内に入ってきた。
      少し暑かったのだろう、
      レジに並びながらチェックの
      コートを脱いだところで、
      私の眼鏡に映る湧湧度は80％まで上昇していた。
      
      - そんなにコーヒーが楽しみなのか？
      - ポイントカードが、今日でいっぱいになる、とかかな。
      - もしかしてレジのサワヤカくんに恋をしているとか？
      - いや、でも、そんなに一気に湧湧度は上昇するものなんだろうか - - -
      
      彼女から目が離せなくなる。
      レジの順番が近くなり、THE・お仕事バッグ、
      と言えるようなレザーのA3サイズのバッグから、お財布を出した。
      プラプラとマスコットのようなものが付いて、
      泳いでいる。
      あれは確か、MM WONDERLAND の
      キャラクターだ。
      ちょっと不気味なキャラクター。よく見る。
      何が可愛いのかはわからないが、
      彼女はそれが好きなんだろうか。
      本当に、一体、何にワクワクしているんだろう。
      偶然、隣に座ってくれたりしないだろうか。
      `,
      color: {
        en: "handsome gray",
        ja: "グレー",
      },
      recommend: ["Lace line pants", "CHECK LAYERED SKIRT,", "Liddell dress"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm202co01_nor",
      story: `いつかの時代、とある世界の、
      ちょっとふしぎな国。
      おいしいものと、おめかしが
      大好きな民族“マダマダ族”。
      そんなマダマダ族の城下町の
      ファッションリーダー、
      DERA（デルア）さんのお気に入りの
      「ハンサムチェック」の
      生地をたっぷり使ったコートは
      空も山も時空も超えて、
      全く違う世界のとある女性も
      愛用しているようです。
      彼女はどんな着こなしを
      楽しんでいるのでしょうか・・？
      `,
    },
    {
      id: "ticket-pants",
      title: {
        main: "TICKET PANTS",
      },
      desc: `わたしの儚い、たからもの
      いなくなってしまうその時まで
      片時も離さない
      誰からも見つからないように、
      ひっそりと隠す
      
      ブラウスのボタンを閉め
      髪をまとめ
      靴の紐を結ぶ
      「いつもと同じ」お面をかぶる
      
      仕事をして
      コーヒーを飲んで
      また仕事をする
      誰も知らない
      わたしの腰に
      まさかチケットが隠れてるだなんて
      
      わたしの儚い、たからもの
      いなくなってしまうその時が
      近づいている
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      recommend: ["writing blouse", "big collar blouse", "mint T-blouse"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211pt01_nor1",
      story: `【MM WONDERLAND】へ遊びに行こう！
      そこは少しふしぎなワンダーランド。
      かわいいものとかっこいいもの、
      そしておいしいもののたくさん詰まった
      桃源郷のような施設です。
      
      さて、行くと決めたら
      とってもウキウキする、準備期間。
      チケットを手配して、
      着て行くお洋服を選んで、
      お土産をリサーチして・・
      ああ、すでにこんなに楽しいだなんて。
      `,
    },
    {
      id: "rara-tulle-5-skirt",
      title: {
        main: "writing blouse",
        sub: "書き直しすぎた便箋",
      },
      desc: `HOW TO MAKE HER SMILE,
      without romantic sentence
      - ロマンティックな文章なしに、
      彼女を笑顔にする方法。 -
      
      1.電車で暇な時間に、
      「MM ワンダーランド」を検索します。
      
      2.あら不思議、すでに少し口角が
      上がっていませんか？
      
      3.妄想をはじめたようです。
      もう誰にも止められません。
      
      4.車内でそんなに笑って・・
      ところで何に悩んでたっけ？
      `,
      color: {
        en: "romantic sentence",
        ja: "グレイッシュピンク",
      },
      recommend: ["TICKET PANTS", "Lace line pants", "CHECK LAYERED SKIRT"],
      link: "https://teshioni.com/products/maddermadder_mm202bl01_nor",
      story: `“書き直しすぎた便箋” writing blouse
      「書き直しすぎた便箋」という
      名前のついたブラウス、
      お色は「romantic sentence ＝ 
      ロマンティックな文章」。
      インスタライブ等では
      【消しかすブラウス】という
      ファニーな名前で親しまれていますが、
      実はマダマダのお洋服の中で一番、
      ロマンティックだったりします。
      あなたの大切な人（もしくは、あなた自身）から、
      笑顔が奪われれしまった時。
      ふと届いたロマンティックな
      メイルやお手紙よりも、
      もしかすると1枚のチケットの方が早く、
      笑顔を取り戻すことができるかもしれません。
      ロマンティックなものとは、
      人の心に、じんわりと内側からひろがっていきます。
      徐々に、徐々に。
      そしてそれはずっと消えることはありません。
      対して1枚のチケットは、
      即効性のあるお薬のように楽しい気分をもたらします。
      しかし、それは使い終えると、
      もしかするとさらなる孤独感などの副作用をもたらすかもしれません。
      というわけで、もしわたしの大切な人から笑顔が奪われてしまったら、
      1枚のチケットにロマンティックな文章を添えて、
      プレゼントしようと。そういうイメージでスタイリングしたので、
      ぜひ “writing blouse” と “TICKET PANTS”、
      一緒に着ていただけたら嬉しいです。
      `,
    },
    {
      id: "ticket-dress",
      title: {
        main: "TICKET DRESS",
      },
      desc: `今日はいよいよアレが届く日
      おまねきする準備は万端
      スニーカーで買い出しへ
      リビングにお花も飾ったし
      正装の革靴を履いたら
      いざポストへお迎えへ
      ああ、早くチケットに会いたい
      `,
      color: {
        en: "light gray",
        ja: "ライトグレー",
      },
      recommend: ["CHECK BIG LONG COAT", "collar blouse", "GOWN JACKET"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op01_nor",
      story: `【MM WONDERLAND】へ遊びに行こう!
      そこは少しふしぎなワンダーランド。
      かわいいものとかっこいいもの、
      そしておいしいもののたくさん詰まった
      桃源郷のような施設です。
      
      さて、行くと決めたらとってもウキウキする、
      準備期間。
      チケットを手配して、
      着て行くお洋服を選んで、
      お土産をリサーチして・・
      ああ、すでにこんなに楽しいだなんて。
      `,
    },
  ];
  const title = {
    main: "MM WONDERLAND",
    sub: "第1部・チケットを買って、さあ行こう！編",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={items[1].story}
      id="mm-wonderland-1"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-1/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-1/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-1/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-1/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[3].story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202101;
