import React, { VFC } from "react";
import * as styles from "./styles";

type ItemLeftLayoutProps = {
  children: JSX.Element[];
  className?: string;
};

const ItemLeftLayout: VFC<ItemLeftLayoutProps> = ({ children, ...props }) => {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.header}>{children[0]}</div>
      <div className={styles.desc}>{children[1]}</div>
    </div>
  );
};

export default ItemLeftLayout;
