import React from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  V1CoverLayout,
  V1IndexLayout,
  V1MonthlyStoryLayout,
} from "@/components/atoms/v1";
import { V1StoryBtn } from "@/components/molecules/v1";
import { Heading, Txt } from "@/components/atoms";

const Index_202002 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202002Query>(graphql`
    query Index_202002 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const bookTitle = "February 2020";
  const story = {
    head: "2月のおはなし “think of wind”",
    body: `2月。
    
    まだまだ寒い日が続きますが
    なんとなく、
    春の気分を味わいたい
    そんな時期
    
    トゲトゲしているような空気を
    冬の終わりに
    どこかへ運び去っていく
    
    ふんわり、優しい香りが
    春のはじまりに一足お先に
    運んできてくれる
    
    私の記憶の中の風と
    今吹いている風は
    なんだか少し違うみたい
    風っていったい何色なんだろう
    
    風ってどんな香りが正解なんだろう
    風について考える、感じる
    出てきた答えが
    きっと今の私の気分を映し出している
    
    2月のマダマダは
    ちょっとだけ、冬に飽きてしまって
    ほんの少し、春をまといたい・・
    軽やかな空気の質感と
    春らしい柔らかい色で
    身にまとって
    少し目をつぶって深呼吸
    風を感じたくなるような
    アイテムを提案します
    `,
  };
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="February-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-2/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1MonthlyStoryLayout>
          <Heading level={3}>{story.head}</Heading>
          <Txt isWrapping size="l">
            {story.body}
          </Txt>
          <V1StoryBtn link="/2020-2/items" />
        </V1MonthlyStoryLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202002;
