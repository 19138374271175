import React, { VFC } from "react";
import * as styles from "./styles";
import { Txt } from "@/components/atoms";

type ItemRightLayout4Props = {
  children: JSX.Element[];
  className?: string;
};

const ItemRightLayout4: VFC<ItemRightLayout4Props> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.imgWrapper}>{children[0]}</div>
      <div className={styles.storyWrapper}>
        <div className={styles.story}>{children[1]}</div>
      </div>
      <div className={styles.btnWrapper}>
        {children[2]}
        <Txt size="s" isCenter>
          または写真をタップしてください
        </Txt>
      </div>
    </div>
  );
};

export default ItemRightLayout4;
