import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1ItemRightLayout2,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202006 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202006Query>(graphql`
    query Items_202006 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "candy-sleeve-anorak",
      title: {
        main: "candy sleeve anorak",
      },
      desc: `雨は好きじゃない、
      ジメジメとして
      洗濯物も外に干せなくて
      なんだか暗くって
      そして頭が痛くなる
      
      飴はとても好き。
      色とりどりで
      いい香りがして
      小さくて可憐で
      甘くて美味しい。
      
      同じアメなのに
      どうしてこんなに違うのかな
      
      雨の日に
      飴を食べて
      自分のご機嫌取りをする
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      recommend: ["LONG SKIRT", "TULLE LONG DRESS", "lace line pants"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201ar01_nor1_bk",
      story: `こんなの、欲しかった！
      マダマダらしいアノラックが出来上がりました。
      
      “雨” の日にも着やすい素材で作った、
      “飴” のような袖の膨らみがポイントのアノラック。
      
      すぽっとかぶりで着用いただけ、
      袖、ポケット前、背中とギャザーが寄せてあるので
      前・横・後ろとどこから見ても
      ふんわりとしたシルエットを堪能できます。
      大きなフードは絞ることも。
      ギャザーが寄ってまた一味違った印象を楽しめます。
      もちろん、ポケット付き。
      
      しっかりとした生地感です。薄くはありません。
      タウンで真夏には少し暑いかもしれませんが・・
      野外フェスや、
      山や森などアウトドアでのアクティビティなど
      活躍させたい場所がたくさん思い浮かびます。
      全体的にゆったりとした作りですので、
      秋冬は中にニットなど厚手のものをインすることも可能です。
      
      雨が降っていても、降っていなくても。
      一年中、手放したくなくなるようなアイテムになれば嬉しいです。
      `,
    },
    {
      id: "umbrella-dress",
      title: {
        main: "umbrella dress",
      },
      desc: `傘について本気出して考える。
      
      なんで閉じているこの姿は
      しゅっとして
      かっこよくて
      スマートなんだろう
      脇役なのに、人気投票で3位を取ってしまう、
      みたいな。
      ちょっとニヒルでかっこいい。
      
      その反面
      開いたら、
      ぱあっとその場の空気が変わって
      華やかで
      美しい
      ああやっぱり、1位は主人公なんだよな、
      みたいな。
      「さあ、行きますか」って、声が聞こえる。
      
      ずるいなあ、と思う。
      こんな素敵な二面性のある人、いるだろうか
      現実世界でなかなかお目にかかれない気がする。
      
      というわけで傘について本気出して考えた、
      本文の結論はこちらです。
      「私は傘になりたい、だからこの服を作った」
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op08_nor",
      story: `傘と見間違えてしまうようなフリルたっぷり、生地感たっぷり！
      マダマダらしい撥水素材のティアードドレス、登場です。
      後ろのリボンは飾りではないため、
      結び方によって若干長さを調整可能です。
      
      たっぷりのティアードでボリューム感はありながら、
      キャミソール部分肩の紐は細めで程よくレディな雰囲気に。
      このバランス感にマダマダらしさが詰まっています。
      
      こちらの生地は candy sleeve anorak と同じく、
      しっかりとした生地感です。薄くはありません。
      が、しっかりとした生地だからこそ、のこのボリュームを
      堪能していただけたらな・・と思います。
      `,
    },
  ];
  const title = {
    main: "RAIN OR CANDY?",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="rain-or-candy"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-6/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout2>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-6/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout2>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202006;
