import React, { useMemo, VFC } from "react";
import * as styles from "./styles";
import { VerticalTxt } from "@/components/atoms/Txt";
import { useWindowSize } from "@/hooks/useWindowSize";

type AppendixProps = {
  txtColor: string;
  children: JSX.Element[];
  className?: string;
  style?: { flex: number };
};

const Appendix: VFC<AppendixProps> = ({ txtColor, children, ...props }) => {
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      dotsSize: windowWidth <= 767 ? "s" : "xxl",
    };
  }, [windowWidth]);
  return (
    <div className={styles.root} {...props}>
      <div className={styles.num} style={{ color: txtColor }}>
        {children[0]}
      </div>
      <div className={styles.dots} style={{ color: txtColor }}>
        <VerticalTxt size={responsive.dotsSize} isBold>
          ・・・
        </VerticalTxt>
      </div>
      <div className={styles.item} style={{ color: txtColor }}>
        {children[1]}
      </div>
    </div>
  );
};

export default Appendix;
