import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202104 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202104Query>(graphql`
    query Items_202104 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "watage-fluffy-skirt",
      title: {
        main: "“WATAGE” fluffy skirt",
      },
      desc: `“STORY”
      
      種が空を飛んでいる
      世界旅行のように ゆらゆらと
      どこへ行くのだろう
      雲のように漂う
      知らない花は
      枯れ朽ちても
      また生まれてくる
      また
      「知らない花」として
      あるいは・・・
      `,
      color: {
        en: "dark navy",
        ja: "ダークネイビー",
      },
      recommend: [
        "“TANE” many many seeds blouse",
        "mint T-blouse",
        "citrus tunic",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211sk02_nor",
    },
    {
      id: "commons-garden-dress",
      title: {
        main: "“COMMONS” garden dress",
      },
      desc: `どこにでもある、
      普通の家。
      突然変異、とはよくあること。
      普通の家の、普通のお庭で
      誰も知らない花が
      人知れず育ち、
      今まさに摘まれようとしている。
      普通の家に育った、普通の花。
      ふつうって、なんだろう？
      `,
      color: {
        en: "garden green",
        ja: "ガーデングリーン",
      },
      recommend: [
        "“ROSE” big pocket check blouse",
        "“Emily” open collar blouse",
        "“Luna” gown dress",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op05_nor",
    },
    {
      id: "emily-open-collar-blouse",
      title: {
        main: "“Emily” open collar blouse",
      },
      desc: `「わかくさシスターズ」とは・・？
      
      それはこの世界と少し似ていて、
      でもだいぶ違う、世界のはなし。
      わかくさのようにのびのび、キラキラと
      「今」をせいいっぱい楽しく生きる
      仲良しな4姉妹がいました。
      
      長女・エミリー
      次女・ひまわり
      三女・ルナ
      四女・ミルキー
      
      半年に一回、ふらりと私たちの前に
      あらわれる彼女たち。
      秋ぶりにかえってきました。
      
      ある日、お父さんの書斎で
      見つけた不思議な本
      「THE FLOWERS NOONE KNOWS」
      
      ・・・
      
      その本にのっている、見たこともない、
      「誰も知らない植物」を探しに
      4人はそれぞれ、大冒険へ。
      さて、長女エミリーさんは、どちらへ・・・？
      `,
      color: {
        en: "light gray",
        ja: "ライトグレー",
      },
      recommend: ["“Milky” easy wide pants", "TICKET PANTS", "LONG SKIRT"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211bl02_nor",
    },
    {
      id: "himawari-big-sailor-collar-dress",
      title: {
        main: "“Himawari” big sailor collar dress",
      },
      desc: `「わかくさシスターズ」とは・・？
      
      それはこの世界と少し似ていて、
      でもだいぶ違う、世界のはなし。
      わかくさのようにのびのび、キラキラと
      「今」をせいいっぱい楽しく生きる
      仲良しな4姉妹がいました。
      
      長女・エミリー
      次女・ひまわり
      三女・ルナ
      四女・ミルキー
      
      半年に一回、ふらりと私たちの前に
      あらわれる彼女たち。
      秋ぶりにかえってきました。
      
      ある日、お父さんの書斎で
      見つけた不思議な本
      「THE FLOWERS NOONE KNOWS」
      
      ・・・
      
      その本にのっている、見たこともない、
      「誰も知らない植物」を探しに
      4人はそれぞれ、大冒険へ。
      さて、次女ひまわりは、どこに向かう・・・？
      `,
      color: {
        en: "sand ivory",
        ja: "サンドアイボリー",
      },
      recommend: [
        "“Emily” open collar blouse",
        "Lace line pants",
        "sailor collar bolero",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op06_nor",
    },
  ];
  const story = `誰が作ったんだろう?
  不思議な植物図鑑に載っている、
  「誰も知らない花」たち。
  
  人間の一生と、
  同じようにたどる
  
  種が漂い、
  みんな普通の顔して成長する
  最盛期には輝き、
  終わりの足音が忍び寄る
  やがて花ではなく、実に、種に。
  
  今日もまたページが増える
  誰も知らない、はずの植物図鑑
  `;
  const title = {
    main: "THE FLOWERS NOONE KNOWS",
    sub: "第一部・見知らぬ、図鑑",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="the-flowers-noone-knows-1"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-4/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-4/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-4/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-4/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202104;
