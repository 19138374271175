import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import {
  V1Appendix,
  V1TocLayout,
  V1TocChildLayout,
} from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202005 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202005Query>(graphql`
    query Index_202005 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 160 : 250,
      txtSize: windowWidth <= 767 ? "m" : "xxxxl",
    };
  }, [windowWidth]);
  const bookTitle = "May 2020";
  const nextPreview = "◆次巻予告・「降るアメより食べるアメ」〇六〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#f6f6f6",
    itemColor: "#f6f6f6",
  };
  const bgColor = "#8a602e";
  const tocContents = [
    {
      to: "/2020-5/items#lace-line-pants",
      num: "二十二",
      title: "ロマンティックをラインに込めて",
      item: "レースラインパンツ",
    },
    {
      to: "/2020-5/items#lace-line-jacket-coat",
      num: "",
      title: "今日はジャケット。明日はコート。",
      item: "レースラインジャケットコート",
    },
    {
      to: "/2020-5/items#my-black-dress",
      num: "二十九",
      title: "旅先に１枚あれば良い。",
      item: "マイ・ブラックドレス",
    },
  ];
  const appendix = "作者あとがき";
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="May-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-5/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.item}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
            <V1Appendix txtColor={tocProps.txtColor} style={{ flex: 1 }}>
              <VerticalTxt {...txtProps}>三十一</VerticalTxt>
              <VerticalTxt {...txtProps}>{appendix}</VerticalTxt>
            </V1Appendix>
          </>
          <Anchor to="/2020-6#June-2020" style={{ color: tocProps.txtColor }}>
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202005;
