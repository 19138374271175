import React from "react";
import * as styles from "./styles";
import { AboutBrand, AboutDesigner } from "@/components/molecules";

const AboutContainer = ({ designerName, desc, links }) => {
  const { brand, designer } = desc;
  return (
    <section className={styles.wrapper}>
      <AboutBrand brandDesc={brand} />
      <AboutDesigner
        designerName={designerName}
        designerDesc={designer}
        links={links}
      />
    </section>
  );
};

export default AboutContainer;
