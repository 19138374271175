import React from "react";
import * as styles from "./styles";
import { Navigation } from "@/components/organisms";
import { MenuIcon } from "@/components/atoms/Icon";

const Header = ({
  className = "",
  isMenuVisible,
  updateVisible,
  navigations,
  ...props
}) => {
  const handleMenuClick = () => {
    updateVisible(!isMenuVisible);
  };
  return (
    <header className={[styles.root, className].join(" ")} {...props}>
      <Navigation links={navigations} isMenuVisible={isMenuVisible} />
      <div className={styles.iconWrapper} onClick={handleMenuClick}>
        <MenuIcon className={styles.icon} />
      </div>
    </header>
  );
};

export default Header;
