import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202004 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202004Query>(graphql`
    query Items_202004 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "tutor-dress",
      title: {
        main: "Tutor dress",
      },
      desc: `昔の映画の家庭教師たち
      みんな素敵なドレスを着ているのに
      ちょっと怒りん坊だったり
      真面目で石頭すぎたり
      笑顔を見せてくれなくって
      なんでかなあって
      
      そんなこと言いながらも
      憧れていたのか
      大人になった私は
      制服のように1枚のドレスを仕事着にする
      髪も綺麗にまとめて
      背筋をピンと伸ばせば
      どこからどう見ても
      ”映画の中の家庭教師の先生”
      になった
      
      長女だから教えるのは得意
      3人も個性の強い妹がいるんだから
      怒りん坊でもない
      真面目でもない
      笑顔たっぷりで
      今日も教えに行く
      `,
      color: {
        en: "green / green",
        ja: "グリーン・グリーン",
      },
      recommend: ["sailor collar blouse", "GOWN JACKET"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op05_nor",
      story: `昔の映画に出てくる
      家庭教師の先生が来ているドレス。
      マダマダらしくバイカラーのトロンプルイユ風で
      ”お茶目に” アップデート！
      ワッシャー加工の生地で
      “わざと” シワ感があり
      ピリッとしすぎない抜け感を。
      たっぷりのくるみボタンは飾りです。
      
      ウエストのリボンは
      立ったままの授業では後ろで結んで。
      座りの授業では前で結びましょう。
      
      フレンチスリーブはそのままでも、
      中に半袖を着ても、長袖を着ても。
      （もちろん七分袖でも。）
      ”制服代わり” なので1年中着ることができます。
      
      長女らしく 面倒見がいいのに
      自分のことに関しては抜けている
      そんな愛すべきあなたに捧げます。
      `,
    },
    {
      id: "sailor-collar-bolero",
      title: {
        main: "Sailor collar bolero",
      },
      desc: `女友達より男友達の方が多かった
      
      スカートは履かない、
      （と言いつつ実は
      　こっそり部屋で姉のを着て踊っていた）
      草原だってトンボを追って駆け抜ける
      （でもシロツメクサで王冠を作って
      　うっとりしていたのよ）
      
      そのまま大人になった
      ハンサムウーマンて笑いながら友が呼ぶ
      帰りにホワイト・リリーの花束を
      買って帰ろう
      
      次女だけど、妹は2人もいる
      たまに甘えたい
      たまにピシッと決めたい
      どんな時だってこれがあれば安心
      セーラーカラーは
      昔からずっと私の “制服”
      `,
      color: {
        en: "white lilly",
        ja: "リリー・グリーン",
      },
      recommend: ["Tutor dress", "2way dress", "salopette"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201jk01_nor",
      story: `カジュアルにもガーリーにも合わせたくなる、
      コーディネートを組むのがさらに楽しくなる
      セーラーカラーのボレロができました。
      
      大きめ襟とAラインのショート丈が
      マダマダのロング丈やボリュームのあるお洋服と
      相性抜群、自然にスタイルアップしてくれます。
      セーラーカラーのお洋服は
      ホワイトやブルーが多いですが、
      マダマダらしく淡いくすみグリーンでの提案。
      前あきでボタンがつかず、ボレロなところも
      日常に取り入れやすいポイントです。
      
      ゆとりのあるお袖でレイヤードも楽々。
      柔らかいコットンリネンでもちろんインして着るのも◎
      スウェード調の少し起毛したリボンもアクセント。
      
      次女・真ん中っ子らしく
      ”周りにいつだって気遣いができる” 素敵なあなたへ、
      ガーリーにもボーイッシュにも着れる
      このアイテムを捧げます。
      `,
    },
    {
      id: "gown-dress",
      title: {
        main: "gown dress",
      },
      desc: `私の好きなもの
      
      木漏れ日に照らされる袖先
      
      ガーデンの水やりで
      水しぶきが裾を濡らした
      
      春風に揺れるリボン
      
      そして
      お節介なくらい真面目な姉と
      
      怒り口調で優しいことを言う姉
      
      笑顔が太陽のようにまぶしい妹
      
      三女だけど、末っ子じゃない。
      甘えているけど
      本当はしっかりしなきゃってわかってる
      ロマンティックに裾を揺らすことも
      リボンでキュッと
      気を引き締めることもできるの
      `,
      color: {
        en: "rose mist",
        ja: "グレイッシュピンク",
      },
      recommend: ["SMOKE DRESS", "182 dress"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op06_nor",
      story: `はおりのガウンとしても
      前をしめればドレスとしても着れる
      カシュクールタイプの
      ガウン・ドレスの登場です。
      
      ヴィンテージの
      ベッドサイド・ガウンをイメージソースに
      様々な着方ができるガウンドレスは
      天然素材で1年中でも着たくなるアイテムです。
      
      首元から裾、裾までたっぷりと入ったヒラヒラは
      全体の生地量にも負けない存在感がありつつも
      少し短めのお袖が重さを感じさせません。
      
      1枚でももちろん着れますが、
      マダマダのドレスの中では丈感が少し短い方ですので
      レイヤードスタイルもぜひ挑戦してください。
      
      スウェット + デニムにさっと羽織ったり、
      時には花柄の思い切りロマンティックなドレスに合わせたり。
      
      三女・真ん中っ子らしく
      ”地に足つけながらも、しっかり夢見がち” の
      ロマンティック・リアリストなあなたへ、
      袖を通すだけで起きたまま夢の中に入っていけそうな
      このアイテムを捧げます。
      `,
    },
    {
      id: "work-skirt",
      title: {
        main: "work skirt",
      },
      desc: `ちょっと困った顔をするだけで
      
      すぐに誰かが飛んでくる
      
      姉たちがなんでも手伝ってくれる
      
      私の笑顔が見たいみたい
      
      私だってなんでもできるんだって
      
      みんなの驚く顔が見てみたい
      
      できないことがたくさんあるけれど
      
      これから学んでいけばいい
      
      ワークスカートを腰に巻いて
      
      今日も新しいことに挑戦する
      
      四女は末っ子。
      甘えるのが世界一うまくて
      姉たちも私のわがままを聞くのが
      大好きだって知ってる
      それでもちょっと憧れちゃう、
      「自立したかっこいい女性」
      `,
      color: {
        en: "beige / pink",
        ja: "ベージュ・ピンク",
      },
      recommend: ["sailor collar bolero", "ribbon blouse", "JABOT BLOUSE"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201sk02_nor",
      story: `1枚で2枚分？
      スカートの上にエプロンをつけているような
      レイヤードされたワークスカートの登場です。
      
      アンティークのインディゴのワークスカート
      をイメージソースに、
      無骨さやかっこよさを取り除いて
      マダマダらしく
      柔軟で可憐な印象をプラスしました。
      
      2着分の生地を使用しているにもかかわらず
      軽やかで足さばきも楽ちん。
      綺麗に見えながらも
      しっかり働くことができます。
      
      遠目では無地に見えるかもしれませんが
      実はグレイッシュ・ピンクのベースに
      白地にベージュのストライプ柄。
      色合わせへのときめきが止まりません。
      現代の服ではなかなか見られないような
      太めの幅のウエストマークも
      きゅきゅっとスタイルアップしてくれます。
      
      仕事に疲れたら、
      こっそりと休憩できるように
      腰に小さな小さな半月型のポケットがあります
      叩いてもビスケットは出てきませんが、
      ご自身でキャンディを忍ばせておいてください。
      
      四女・末っ子らしく
      ”誰からも愛されてしまう”
      生まれながらのみんなのアイドル！なあなたへ、
      このアイテムを捧げます。
      `,
    },
  ];
  const title = {
    main: "WAKAKUSA SISTERS",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="wakakusa-sisters"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-4/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-4/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-4/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-4/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[3].story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202004;
