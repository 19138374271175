import { css } from "linaria";
import { border } from "@/utils/styles";

export const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const imgWrapper = css`
  margin-bottom: 20px;
`;

export const storyWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 20px;
  margin-bottom: 20px;
  border: ${border.width} dashed ${border.itemColor};
  border-radius: ${border.radius};
`;

export const story = css`
  & > p {
    line-height: 2.1;
  }
`;

export const btnWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
