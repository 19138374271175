import React, { VFC } from "react";
import * as styles from "./styles";
import { Txt } from "@/components/atoms";

type ItemRightLayout3Props = {
  children: JSX.Element[];
  className?: string;
};

const ItemRightLayout3: VFC<ItemRightLayout3Props> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.imgWrapper}>{children[0]}</div>
      <div className={styles.btnWrapper}>
        {children[1]}
        <Txt size="s" isCenter>
          または写真をタップしてください
        </Txt>
      </div>
    </div>
  );
};

export default ItemRightLayout3;
