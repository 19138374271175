import React, { VFC } from "react";
import { Anchor, Button, Txt } from "@/components/atoms";
import { PlayIcon } from "@/components/atoms/Icon";
import * as styles from "./styles";

type PurchaseBtnProps = {
  link: string;
  className?: string;
};

const PurchaseBtn: VFC<PurchaseBtnProps> = ({ link, ...props }) => {
  return (
    <Anchor to={link} isExternal className={styles.root} {...props}>
      <Button className={styles.btn}>
        <Txt className={styles.txt} isBold>
          商品ページはこちら
          <PlayIcon />
        </Txt>
      </Button>
    </Anchor>
  );
};

export default PurchaseBtn;
