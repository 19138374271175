import React, { useMemo } from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { V1CoverLayout, V1IndexLayout } from "@/components/atoms/v1";
import { V1TocLayout, V1TocChildLayout } from "@/components/molecules/v1";
import { VerticalTxt } from "@/components/atoms/Txt";
import { Anchor } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";

const Index_202009 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_202009Query>(graphql`
    query Index_202009 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      height: windowWidth <= 767 ? 500 : 830,
      paddingTop: windowWidth <= 767 ? 200 : 250,
      txtSize: windowWidth <= 767 ? "l" : "xxxl",
    };
  }, [windowWidth]);
  const bookTitle = "September 2020";
  const nextPreview = "◆次巻予告・「madder madder clan」十〇〇一";
  const txtProps = {
    size: responsive.txtSize,
    isBold: true,
    style: { letterSpacing: "0.5rem" },
  };
  const tocProps = {
    txtColor: "#e7bccc",
    itemColor: "#e7bccc",
  };
  const bgColor = "#4c392f";
  const tocContents = [
    {
      to: "/2020-9/items#skirt",
      num: "一",
      title: "１８２スカート",
    },
    {
      to: "/2020-9/items#alice-apron-gilet",
      num: "十一",
      title: "エプロン・ジレ",
    },
    {
      to: "/2020-9/items#two-way-dress",
      num: "十五",
      title: "準備をはじめる　2WAY ドレス",
    },
    {
      to: "/2020-9/items#lorina-dress",
      num: "十八",
      title: "ロリーナ・ドレス",
    },
    {
      to: "/2020-9/items#liddell-dress",
      num: "二十九",
      title: "リデル・ドレス",
    },
  ];
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="September-2020"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2020-9/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1TocLayout
          txtColor={tocProps.txtColor}
          height={responsive.height}
          bgColor={bgColor}
        >
          <>
            {tocContents.map((t) => (
              <Anchor key={t.title} to={t.to} style={{ flex: 1 }}>
                <V1TocChildLayout
                  {...tocProps}
                  paddingTop={responsive.paddingTop}
                >
                  <VerticalTxt {...txtProps}>{t.num}</VerticalTxt>
                  <VerticalTxt {...txtProps}>{t.title}</VerticalTxt>
                </V1TocChildLayout>
              </Anchor>
            ))}
          </>
          <Anchor
            to="/2020-10#October-2020"
            style={{ color: tocProps.txtColor }}
          >
            <VerticalTxt {...txtProps}>{nextPreview}</VerticalTxt>
          </Anchor>
        </V1TocLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_202009;
