import React from "react";
import { V1IndexPageLayout } from "@/layouts";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  V1CoverLayout,
  V1IndexLayout,
  V1MonthlyStoryLayout,
} from "@/components/atoms/v1";
import { V1StoryBtn } from "@/components/molecules/v1";
import { Heading, Txt } from "@/components/atoms";

const Index_201910 = () => {
  const data = useStaticQuery<GatsbyTypes.Index_201910Query>(graphql`
    query Index_201910 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const bookTitle = "October 2019";
  const story = {
    head: "10月のおはなし ”ART”",
    body: `10月。
    
    だんだんと秋らしくなってきました
    厚手のウールのコートの楽しみは
    もう少し先みたい
    ワンピースを1枚でさらっと着たり
    ブラウスにはスカートかな、パンツかな・・
    はおりを重ねてレイヤードを楽しんだり
    身軽にいろいろなところに出かけたい
    たくさんのものを視界に入れたい
    
    芸術の秋、という言葉があるけれど
    美術館に行ったり
    家で美しい挿絵の本を見たり
    特別なことをしなくても
    例えば
    温野菜サラダの盛り付けの彩りを凝ってみたり
    メイクやヘアアレンジをすることだってそう
    いつもの日常に
    ほんの少し、ART を意識するだけで
    なんだかちょっと楽しい
    
    10月のマダマダは
    なんだかどこかで見たような、
    聞いたことあるような
    芸術家や作品から
    イメージを膨らませて、膨らませて、
    アートを感じるスタイルを提案します`,
  };
  return (
    <V1IndexPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={bookTitle}
      desc=""
      id="October-2019"
    >
      <V1IndexLayout>
        <V1CoverLayout>
          <StaticImage
            src="../../assets/images/2019-10/index.jpg"
            alt="Book cover"
          />
        </V1CoverLayout>
        <V1MonthlyStoryLayout>
          <Heading level={3}>{story.head}</Heading>
          <Txt isWrapping size="l">
            {story.body}
          </Txt>
          <V1StoryBtn link="/2019-10/items" />
        </V1MonthlyStoryLayout>
      </V1IndexLayout>
    </V1IndexPageLayout>
  );
};

export default Index_201910;
