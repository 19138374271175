import React, { VFC } from "react";
import { Txt } from "@/components/atoms";

type CopyrightProps = {
  children: string;
  className?: string;
};

const Copyright: VFC<CopyrightProps> = ({ children, ...props }) => (
  <Txt size="s" {...props}>
    © {new Date().getFullYear()} {children}
  </Txt>
);
export default Copyright;
