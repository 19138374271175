import React from "react";
import * as styles from "./styles";
import { containPresenter } from "@/utils/HoC";

export const IconSVGList = {
  Play: "play",
};

export const IconPresenter = ({
  iconName,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg height={height} width={width} {...props}>
    <use href={`/icons.svg#${iconName}`} />
  </svg>
);

export const IconContainer = ({
  presenter,
  onClick,
  className = "",
  ...props
}) => {
  if (onClick) className += ` ${styles.clickable}`;
  return presenter({ onClick, className, ...props });
};

export const iconFactory = (iconName) => (props) => {
  const Icon = containPresenter(IconContainer, IconPresenter);
  return <Icon {...{ iconName, ...props }} />;
};

// export const PlayIcon = iconFactory(IconSVGList.Play);

// fixme: useタグの使い方（パスの指定）がよく分からなかったので一旦直接svgを書いた
export const PlayIcon = ({ ...props }) => {
  return (
    <svg
      id="ico_play"
      data-name="ico_play"
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="8.66"
      viewBox="0 0 6 8.66"
      {...props}
    >
      <path d="M6,4.33,0,8.66V0Z" />
    </svg>
  );
};

export const MenuIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={styles.clickable}
      {...props}
    >
      <line x1="3" y1="12" x2="21" y2="12" />
      <line x1="3" y1="6" x2="21" y2="6" />
      <line x1="3" y1="18" x2="21" y2="18" />
    </svg>
  );
};
