import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202005 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202005Query>(graphql`
    query Items_202005 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "lace-line-pants",
      title: {
        main: "Lace line pants",
      },
      desc: `旅先にて。
      たくさん歩くかもしれないし
      楽チンな格好でふらふらしたいかも
      でもジャージっていうのも・・
      ちょっと、ねえ。
      ラインをレースで
      いつだってロマンティック。
      旅の思い出の中で
      私はいつだってロマンティックなの。
      `,
      color: {
        en: "sand beige",
        ja: "サンドベージュ",
      },
      recommend: ["sailor collar bolero", "ribbon blouse", "JABOT BLOUSE"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201pt03_nor",
      story: `スポーティーなのか？ガーリーなのか？
      ジャージをマダマダらしく、
      リネンで作ってみました。
      片方のみに入ったラインは、なんとレース。
      もはやジャージと呼んでいいのかわかりませんが、
      着心地の良さや
      足さばきのしやすさはジャージなみ◎。
      
      ポケットはファスナー付き。
      旅の移動中にちょっと席を立つにも、
      貴重品など入れられて楽チンです。
      
      同素材でお作りした
      Lace line jacket coat と
      セットアップでどうぞ。
      `,
    },
    {
      id: "lace-line-jacket-coat",
      title: {
        main: "Lace line jacket coat",
      },
      desc: `旅先にて。
      明日の天気がわからない
      寒いのかな、
      あたたかいのかな。
      さっと羽織って体温調節
      旅行に行って風邪をひくなんて
      そんな残念なことにならないわ
      毎日着たって
      印象を変えれるでしょ
      `,
      color: {
        en: "sand beige",
        ja: "サンドベージュ",
      },
      recommend: ["182 dress", "long skirt", "salopette pants"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201jk02_nor",
      story: `使い勝手の良すぎるジャケットコート。
      そのままで着ると、
      控えめなAラインが美しいコートに。
      ウエスト部分のボタンを外せば、
      ショート丈のジャケットに。
      こんな 2way、見たことありません。
      
      マダマダらしく、
      小さなくるみボタンがたくさんついています。
      もちろんレースのラインまで。
      
      甘くもない、
      カジュアルすぎない、
      シンプル・・ではないかもしれませんが、
      着回し力抜群。
      全てがいい塩梅のジャケットコート。
      
      ボタンを開けて、
      レイヤードを楽しむのも良いですが
      全てしめてドレス風に着るのも素敵です。
      
      同素材でお作りした
      Lace line pants と
      セットアップでどうぞ。
      `,
    },
    {
      id: "my-black-dress",
      title: {
        main: "my black dress",
      },
      desc: `旅先にて。
      見つけた雰囲気のいいレストラン
      ちょっと背伸びだけれど
      入ってみようかな
      大丈夫
      今私が着ているのは
      ブラック・ドレス
      おしとやかに
      しずしずと入る
      デザートは
      チョコレート・ケーキがいいな
      背伸びした私にぴったりの
      洋酒が効いているやつ
      `,
      color: {
        en: "black",
        ja: "ブラック",
      },
      recommend: ["ribbon blouse", "gown dress", "lace line jacket"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm201op07_nor",
      story: `シンプルなのに、シンプルじゃない。
      マダマダらしいブラック・ドレスの登場です。
      
      袖とウエストが結んで調整できるようになっていて
      キュッと絞ったり、
      ゆるく伸ばしたり
      ほんの少し変えるだけで、
      こんなに印象が変わるんだ！
      とファッションがさらに楽しくなるドレス。
      
      少しネップのある生地感で
      真夏以外(暑がりさんでなければもちろん真夏もどうぞ)
      オールシーズン着ていただけます。
      秋冬は中にブラウスやタートルニットなどをインしても。
      
      今回も生地感たっぷりですので
      風とともにダンスしてください
      そして旅先にぜひ連れて行って
      一緒に写真を撮ってあげてください
      `,
    },
  ];
  const title = {
    main: "TABI IN MY HEAD",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={undefined}
      desc={items[0].story}
      id="tabi-in-my-head"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-5/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[0].story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-5/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[1].story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2020-5/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{items[2].story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202005;
