import React from "react";
import { graphql } from "gatsby";
import { V2IndexPageLayout } from "@/layouts";
import { format } from "date-fns";

const V2IndexPageTemplate = ({ data }) => {
  const { siteMetadata } = data.site;
  const { microcmsMonthly } = data;
  const year = microcmsMonthly.year;
  const month = microcmsMonthly.month - 1;
  const monthEn = format(new Date(year, month, 1), "MMMM");
  const bookTitle = `${monthEn} ${year}`;
  const bookId = `${monthEn}-${year}`;
  const images = data.allImg.edges;
  return (
    <V2IndexPageLayout
      siteMetadata={siteMetadata}
      bookTitle={bookTitle}
      desc={microcmsMonthly.desc}
      id={bookId}
      images={images}
    />
  );
};

export const query = graphql`
  query V2IndexPageTemplate($monthlyId: String!) {
    site {
      siteMetadata {
        title
        description
        designer
        language
        instagram
        twitter
        note
        links {
          label
          to
          isExternal
        }
        navs {
          label
          to
          isExternal
        }
        desc {
          brand
          designer
        }
      }
    }
    microcmsMonthly(monthlyId: { eq: $monthlyId }) {
      monthlyId
      year
      month
      desc
    }
    allImg(filter: { monthlyId: { eq: $monthlyId } }) {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP, AVIF])
            }
          }
          monthlyId
        }
      }
    }
  }
`;

export default V2IndexPageTemplate;
