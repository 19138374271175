import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202110 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202110Query>(graphql`
    query Items_202110 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "emily-cocoon-long-coat-21aw",
      title: {
        main: "“Emily” cocoon long coat 21aw",
        sub: "“エミリー” コクーンロングコート",
      },
      desc: `“パンプキン・パイの
      レシピを探しに図書館へ
      春に植物図鑑を探しに行った時以来
      司書さんの中に気になる人がいる
      とっても笑顔の、素敵な人・・
      
      今までは図書館へなんて
      おめかしして行ったことなかったけど
      なんだか・・・
      わたし・・・
      
      買ったばかりの、コートを着込んで。”
      `,
      color: {
        en: "khaki × pink",
        ja: "カーキ × グレイッシュピンクリボン",
      },
      recommend: [
        "“Luna” tulle layered dress 21aw",
        "table ware the fairy blouse",
        "umbrella the fairy skirt",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212co01_nor1",
    },
    {
      id: "himawari-big-sailor-collar-bolero-21aw",
      title: {
        main: "“Himawari” big sailor collar bolero 21aw",
        sub: "“ヒマワリ” ビッグセーラーカラーボレロ",
      },
      desc: `“みんなで力を合わせて作るけど
      味のジャッジはきっと私が一番お得意
      なんせエディターですから
      おいしいもの、たくさん知ってるわ
      
      ポロポロとこぼれやすいパイは
      服を汚さないようにね
      トレードマークのセーラー衿を
      前にすればほら、
      お洋服も汚れないわ”
      `,
      color: {
        en: "cutlery gray",
        ja: "グレー",
      },
      recommend: [
        "“Luna” tulle layered dress 21aw",
        "TICKET PANTS",
        "182 dress (black)",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212bl02_nor1",
    },
    {
      id: "luna-tulle-layered-dress-21aw",
      title: {
        main: "“Luna” tulle layered dress 21aw",
        sub: "“ルナ” チュールレイヤードドレス",
      },
      desc: `“やれやれ、また何かはじまったわ。
      いつだって私も巻き込まれるの。
      
      とりあえずかぼちゃがが必要ね、
      狙ったかのように、今ちょうど・・
      採れどきのものがひとつ、お庭にあるわ。
      
      本当はかぼちゃの馬車に
      するつもりだったけれど
      それはないしょの話、やれやれ。”
      `,
      color: {
        en: "garden khaki",
        ja: "カーキ",
      },
      recommend: [
        "“Emily” cocoon long coat 21aw",
        "“Milky” wrapping tunic blouse 21aw",
        "“Himawari” big sailor collar bolero 21aw",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212op03_nor1",
    },
    {
      id: "milky-wrapping-tunic-blouse-21aw",
      title: {
        main: "“Milky” wrapping tunic blouse 21aw\n",
        sub: "“ミルキー” チュニックブラウス",
      },
      desc: `“自慢じゃないけど
      （いや、やっぱり自慢かも）
      もう家事は私が一番得意なのよ
      
      でもそんなこと言ったら、
      お姉ちゃんたちに作る係を
      押し付けられちゃうから
      ラッピング担当に立候補
      
      いつも髪に巻いてるリボン
      今日は箱に巻き巻きしちゃうわよ
      
      ・・・さて質問です、
      あめちゃんポケットはどこにある？”
      `,
      color: {
        en: "charcoal black",
        ja: "スミクロ",
      },
      recommend: [
        "“Emily” cocoon long coat 21aw",
        "“Milky” wrapping tunic blouse 21aw",
        "“Himawari” big sailor collar bolero 21aw",
      ],
      link: "https://teshioni.com/products/maddermadder_mm212bl03_nor1",
    },
  ];
  const story = `「パンプキン・パイ大作戦」
  
  パンプキン・パイがたべたいの。
  せかいで いっとう、 おいしいやつを。
  あるひ、エルダーおばあさまがいいました。
  
  エミリーは
  としょかんへ レシピさがしに
  （どうやらきになるひとが、いるみたい）
  
  ひまわりは
  あじみがかりは わたしよ、ととくいがお
  
  ルナは
  かんじんの かぼちゃを
  にわへ しゅうかくしに
  （ほんとうは かぼちゃのばしゃに
  するはずたったのにね）
  
  ミルキーは
  ラッピングは わたしに まかせて、
  とリボンと だいかくとう
  
  そしてみんなで ちからを
  あわせて つくります
  
  そのようすを すいしょうでながめる
  エルダーおばあさまは、
  
  「せかいで いっとう、
  おいしいやつにちがいない」
  と、ふかいもりのおくで
  ひとり にっこりわらうのでした。
  `;
  const title = {
    main: "THE BEST PUMPKIN π PROJECT",
    sub: "4姉妹のベスト・パンプキン・パイ大作戦",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="the-best-pumpkin-pie-project"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-10/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-10/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-10/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[3].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[3].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[3].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[3].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[3].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-10/items/04.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[3].color.en}</Txt>
              <Txt size="l">（{items[3].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[3].recommend} />
            <V1PurchaseBtn link={items[3].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202110;
