import { css } from "linaria";

export const fadeIn = css`
  opacity: 0;
  animation: fadeIn 4.2s cubic-bezier(0, 1.04, 0, 1.02) 0s 1 forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const fadeInFromRight = css`
  opacity: 0;
  transform: translateX(0px);
  animation: fadeIn_fromRight 4.2s cubic-bezier(0, 1.04, 0, 1.02) 0s 1 forwards;
  @keyframes fadeIn_fromRight {
    0% {
      transform: translateX(40px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`;

export const fadeInFromTop = css`
  opacity: 0;
  transform: translateY(0px);
  animation: fadeIn_fromTop 4.2s cubic-bezier(0, 1.04, 0, 1.02) 0s 1 forwards;
  @keyframes fadeIn_fromTop {
    0% {
      transform: translateY(-40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

export const fadeInFromBottom = css`
  opacity: 0;
  transform: translateY(0px);
  animation: fadeIn_fromBottom 4.2s cubic-bezier(0, 1.04, 0, 1.02) 0s 1 forwards;
  @keyframes fadeIn_fromBottom {
    0% {
      transform: translateY(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

export const delay01 = css`
  animation-delay: 0.1s !important;
`;

export const delay02 = css`
  animation-delay: 0.2s !important;
`;

export const delay03 = css`
  animation-delay: 0.3s !important;
`;

export const delay04 = css`
  animation-delay: 0.4s !important;
`;

export const delay05 = css`
  animation-delay: 0.5s !important;
`;

export const delay06 = css`
  animation-delay: 0.6s !important;
`;

export const delay07 = css`
  animation-delay: 0.7s !important;
`;

export const delay08 = css`
  animation-delay: 0.8s !important;
`;

export const delay09 = css`
  animation-delay: 0.9s !important;
`;

export const delay10 = css`
  animation-delay: 1s !important;
`;

export const delay11 = css`
  animation-delay: 1.1s !important;
`;

export const delay12 = css`
  animation-delay: 1.2s !important;
`;

export const delay13 = css`
  animation-delay: 1.3s !important;
`;

export const delay14 = css`
  animation-delay: 1.4s !important;
`;

export const delay15 = css`
  animation-delay: 1.5s !important;
`;

export const delay16 = css`
  animation-delay: 1.6s !important;
`;
