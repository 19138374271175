import { css } from "linaria";
import { animation, colors, mediaQuery } from "@/utils/styles";

export const container = css`
  background: ${colors.secondary};
  border: 1px solid ${colors.secondary};
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mediaQuery.min} {
    width: calc(50% - 5px);
    margin-left: 5px;
  }
  ${mediaQuery.max} {
    width: auto;
    margin-left: 0;
    margin-bottom: 0;
  }
`;

export const header = css`
  margin-bottom: 30px;
`;

export const desc = css`
  padding: 10px 0;
  line-height: 2;
`;

export const list = css`
  list-style-type: disclosure-closed;
  padding: 15px 0;
  margin-left: 15px;
`;

export const item = css`
  margin-bottom: 10px;
  &::marker {
    color: ${colors.link.normal};
  }
`;

export const link = css`
  transition: ${animation.hover.basic};

  &:hover {
    opacity: ${animation.opacity};
  }
`;
