import React, { useMemo, VFC } from "react";
import * as styles from "./styles";
import { VerticalTxt } from "@/components/atoms/Txt";
import { useWindowSize } from "@/hooks/useWindowSize";

type Appendix2Props = {
  txtColor: string;
  children: [JSX.Element, JSX.Element[]];
  className?: string;
  style?: { flex: number };
};

const Appendix2: VFC<Appendix2Props> = ({ txtColor, children, ...props }) => {
  const { windowWidth } = useWindowSize();
  const responsive = useMemo(() => {
    return {
      dotsSize: windowWidth <= 767 ? "s" : "xxl",
    };
  }, [windowWidth]);
  return (
    <div className={styles.right} {...props}>
      <div className={styles.num} style={{ color: txtColor }}>
        {children[0]}
      </div>
      <div className={styles.dots} style={{ color: txtColor }}>
        <VerticalTxt size={responsive.dotsSize} isBold>
          ・・・
        </VerticalTxt>
      </div>
      <div className={styles.titles}>
        {children[1].map((c, idx) => (
          <div key={idx} style={{ color: txtColor, display: "contents" }}>
            {c}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Appendix2;
