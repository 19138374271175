import React, { VFC } from "react";
import * as styles from "./styles";

type CoverLayoutProps = {
  children: JSX.Element;
  className?: string;
};

const CoverLayout: VFC<CoverLayoutProps> = ({ children, ...props }) => {
  return (
    <section className={styles.root} {...props}>
      {children}
    </section>
  );
};

export default CoverLayout;
