import React, { VFC } from "react";
import { Link } from "gatsby";

type AnchorProps = {
  to: string;
  isExternal?: boolean;
  children: string | JSX.Element | JSX.Element[]; // fixme
  className?: string;
  target?: string;
  rel?: string;
  style?: { color?: string; flex?: number; display?: string };
};

const Anchor: VFC<AnchorProps> = ({ to, isExternal = false, ...props }) => {
  return isExternal ? (
    <a href={to} target="_blank" rel="noreferrer noopenner" {...props} />
  ) : (
    <Link to={to} {...props} />
  );
};

export default Anchor;
