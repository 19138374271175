import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202107 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202107Query>(graphql`
    query Items_202107 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "savon-the-fairy-blouse",
      title: {
        main: "savon, the fairy - blouse",
        sub: "せっけんのようせい",
      },
      desc: `洗うことに使ってくれたのなら
      溶けてなくなることも本望、
      
      でもね、私の見た目ばかりみて
      タンスの中で香りだけを使われて、
      最後はぽいっと、捨てられる・・
      
      そんなのちょっと、あんまりじゃない？
      ねえ、わたしの真の姿、知ってる？
      （ひゅー、どろどろ）
      `,
      color: {
        en: "milk soap",
        ja: "ミルキーホワイト",
      },
      recommend: ["182 skirt", "umbrella, the fairy", "tea bag, the fairy"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211bl05_nor1",
    },
    {
      id: "lip-stick-the-fairy-dress",
      title: {
        main: "Lip stick, the fairy - dress",
        sub: "くちべにのようせい",
      },
      desc: `あんなに私のこと好き、って言ってたのに
      毎日毎日、いろいろなところに
      連れていってくれたじゃない
      「マイ・ベスト」なんてあだ名もつけて
      ずっとずっと一緒だよ、って
      
      嫌な予感はしていたの
      新入りのあの子をはじめて連れてきた時
      あなた、ずっとその子と一緒に鏡の前で
      うっとりしてた
      
      それからはこのザマよ
      ねえ、私が今どこにいるのかも、
      きっとわかっていないんでしょう？
      
      夜道には、気をつけな
      （ひゅー、どろどろ）
      `,
      color: {
        en: "coco red",
        ja: "赤白ストライプ × ブラック",
      },
      recommend: ["Cushion, the fairy", "savon, the fairy"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op10_nor1",
    },
    {
      id: "chouchou-the-fairy-dress",
      title: {
        main: "Chouchou, the fairy - dress",
        sub: "シュシュのようせい",
      },
      desc: `よくもこきつかってくれたわね
      
      髪を結ぶならまだしも
      食べ途中のお菓子の袋を閉めたり
      ちょいと大きいスカートをしばったり
      特に用もないのに
      手首にずっとつけていたりして
      
      そんなにこきつかったら
      すぐにゴムが切れてしまうに決まっているわ
      結べばまだまだ使えるっていうのに
      
      今に見てなさい、化けて出てやる
      （ひゅー、どろどろ）
      `,
      color: {
        en: "dark night navy",
        ja: "ダークネイビー",
      },
      recommend: [
        "Cushion, the fairy",
        "savon, the fairy",
        "tableware, the fairy",
      ],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm211op11_nor1",
    },
  ];
  const story = `ある人はそれを、
  お化けと呼び、妖怪と呼び、
  鬼と呼び、妖精と呼ぶ。
  神と呼ぶ人も、いるかもしれない。
  そもそもそれは、いるのかもしれないし、
  もしかしたらいないのかもしれない。
  私にもわからない。
  
  でもたしかに、
  自分の所有しているモノ（コト）に対して、
  誰しも愛情を持ったことはあると思う。
  そして私たちは、
  それを捨てなくてはいけない時に、
  「チクリ」とココロを刺される感覚を
  持ったこともあると思う。
  あるいは、後ろ髪をひかれる感覚とか。
  
  7月から9月までのテーマは
  「マダマダ百着夜行」。
  百鬼夜行をもじりました。
  夏至（6末）がすぎ、秋分（9末）を迎えるまで、
  太陽のちからが強くなる。
  そのぶんぎゅぎゅっと凝縮された夜に、
  あやしげに、そして楽しそうに
  お散歩している“やつら”（妖精、あるいは妖怪。
  おばけ。鬼。）はいるはずだ、と確信したので
  服にしました。
  
  ひゅー、どろどろ。
  `;
  const title = {
    main: "HYAKKIYAKOU",
    sub: "第一部・かがみの前から、こんにちは",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="hyakkiyakou-1"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-7/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-7/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={5}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-7/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202107;
