import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { V1ItemsPageLayout } from "@/layouts";
import { V1ItemLayout, V1ItemsLayout } from "@/components/atoms/v1";
import {
  V1ItemLeftLayout,
  V1ItemRightLayout,
  V1PurchaseBtn,
  V1Recommend,
} from "@/components/molecules/v1";
import { Anchor, Heading, Txt } from "@/components/atoms";
import { StaticImage } from "gatsby-plugin-image";

const Items_202109 = () => {
  const data = useStaticQuery<GatsbyTypes.Items_202109Query>(graphql`
    query Items_202109 {
      site {
        siteMetadata {
          title
          description
          designer
          language
          instagram
          twitter
          note
          links {
            label
            to
            isExternal
          }
          navs {
            label
            to
            isExternal
          }
          desc {
            brand
            designer
          }
        }
      }
    }
  `);
  const items = [
    {
      id: "tableware-the-fairy-blouse",
      title: {
        main: "tableware, the fairy - blouse",
        sub: "しょっきのようせい",
      },
      desc: `どんなに大切にしてくれていてもね、
      手元が滑ってしまうこと、あるわ。
      覆水盆にかえらず。
      割れた私は棚にかえらず。
      なんてちょっとナンセンスじゃない？
      どうにかくっつけ直してよ
      金継ぎなんて贅沢言わないから、
      この際アロンアルファでもいいわ。
      （ひゅー、どろどろ）
      `,
      color: {
        en: "off white × gold",
        ja: "オフホワイトに金継ぎ",
      },
      recommend: ["umbrella, the fairy", "182 dress", "tea bag, the fairy"],
      link: "https://teshioni.com/products/maddermadder_mm212bl01_nor1",
    },
    {
      id: "wrapping-paper-the-fairy-gown-dress",
      title: {
        main: "wrapping paper, the fairy - gown dress",
        sub: "ほうそうしのようせい",
      },
      desc: `随分と遠くから嫁いで着たけれど
      大切に、大切に箱の中にしまわれて
      今か今か、と第二の人生を楽しみに
      声を潜めていたけれど
      やっと日の目を浴びれたと思ったら
      ぽい、なんて
      2年間も待たせやがって、
      夢見させるようなこと、するなよ！
      （ひゅー、どろどろ）
      `,
      color: {
        en: "geometric brown",
        ja: "ブラウンベース幾何学模様",
      },
      recommend: ["182 dress", "tea bag the fairy", "chouchou the fairy"],
      link: "https://teshioni.com/collections/madder-madder/products/maddermadder_mm212op01_nor1",
    },
    {
      id: "tea-bag-the-fairy-dress",
      title: {
        main: "tea bag , the fairy - dress",
        sub: "ティーバッグのようせい",
      },
      desc: `知ってますよ、あなたの秘密
      普通は1回・・
      まあ、2回の人も、たまにいますよ。
      それでもねえ、あなた、
      4回はないでしょう。
      3じゃなくて、飛んで4回て。
      大切にしてくれた、
      なんてお門違いもいいところ
      存分に味わいたいのなら、いいですか、
      1回まで。
      次の子たちを、
      よろしく頼みますよ。
      （ひゅー、どろどろ）
      `,
      color: {
        en: "bitter tea",
        ja: "バーガンディー",
      },
      recommend: ["savon, the fairy", "tableware, the fairy"],
      link: "https://teshioni.com/products/maddermadder_mm212op02_nor1",
    },
  ];
  const story = `ある人はそれを、
  お化けと呼び、妖怪と呼び、
  鬼と呼び、妖精と呼ぶ。
  神と呼ぶ人も、いるかもしれない。
  そもそもそれは、いるのかもしれないし、
  もしかしたらいないのかもしれない。
  私にもわからない。
  
  でもたしかに、
  自分の所有しているモノ（コト）に対して、
  誰しも愛情を持ったことはあると思う。
  そして私たちは、
  それを捨てなくてはいけない時に、
  「チクリ」とココロを刺される感覚を
  持ったこともあると思う。
  あるいは、後ろ髪をひかれる感覚とか。
  
  7月から9月までのテーマは
  「マダマダ百着夜行」。
  百鬼夜行をもじりました。
  夏至（6末）がすぎ、秋分（9末）を迎えるまで、
  太陽のちからが強くなる。
  そのぶんぎゅぎゅっと凝縮された夜に、
  あやしげに、そして楽しそうに
  お散歩している“やつら”（妖精、あるいは妖怪。
  おばけ。鬼。）はいるはずだ、と確信したので
  服にしました。
  
  ひゅー、どろどろ。
  `;
  const title = {
    main: "HYAKKIYAKOU",
    sub: "第三部・ワクワク★まよなかのティーパーティー！",
  };
  return (
    <V1ItemsPageLayout
      siteMetadata={data.site.siteMetadata}
      bookTitle={title.main}
      subTitle={title.sub}
      desc={story}
      id="hyakkiyakou-3"
    >
      <V1ItemsLayout>
        <V1ItemLayout id={items[0].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[0].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[0].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[0].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[0].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-9/items/01.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[0].color.en}</Txt>
              <Txt size="l">（{items[0].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[0].recommend} />
            <V1PurchaseBtn link={items[0].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[1].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[1].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[1].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[1].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[1].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-9/items/02.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[1].color.en}</Txt>
              <Txt size="l">（{items[1].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[1].recommend} />
            <V1PurchaseBtn link={items[1].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
        <V1ItemLayout id={items[2].id}>
          <V1ItemLeftLayout>
            <>
              <Heading level={3} visualLevel={5}>
                {items[2].title.main}
              </Heading>
              <Heading level={4} visualLevel={6}>
                （{items[2].title.sub}）
              </Heading>
            </>
            <Txt size="l" isWrapping>
              {items[2].desc}
            </Txt>
          </V1ItemLeftLayout>
          <V1ItemRightLayout>
            <Anchor to={items[2].link} isExternal>
              <StaticImage
                src="../../assets/images/2021-9/items/03.jpg"
                alt="Item image"
              />
            </Anchor>
            <>
              <Txt size="l">{items[2].color.en}</Txt>
              <Txt size="l">（{items[2].color.ja}）</Txt>
            </>
            <Txt isWrapping>{story}</Txt>
            <V1Recommend recommend={items[2].recommend} />
            <V1PurchaseBtn link={items[2].link} />
          </V1ItemRightLayout>
        </V1ItemLayout>
      </V1ItemsLayout>
    </V1ItemsPageLayout>
  );
};

export default Items_202109;
